import './App.css';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
//import Layout from './components/Layout/Layout';
// import 'primereact/resources/themes/lara-light-indigo/theme.css';
import './components/UI/primereactTheme.css';
import 'primereact/resources/primereact.min.css';
import { globals } from './data/global-vars';

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Suspense } from 'react';
import PageLoader from './components/UI/PageLoader';

const Layout = React.lazy(() => import('./components/Layout/Layout'));
const Login = React.lazy(() => import('./components/Views/Login.js'));

function App() {
  const { pathname, hash, key } = useLocation();
  const history = useHistory();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      if (history.location.state && history.location.state.scroll !== false)
        window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }, 0);
    }
  }, [pathname, hash, key, history.location.state]); // do this on route change

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.location.reload();
  //   });
  //   return () => {
  //     unlisten();
  //   };
  // }, [history]);

  return (
    <div className="App">
      <Suspense
        fallback={
          <div>
            <PageLoader show={true} />
          </div>
        }
      >
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/impressum" exact>
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.IMPRESSUM.url}/`}
            />
          </Route>
          <Route path="/kontakt" exact>
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.KONTAKT.url}/`}
            />
          </Route>
          <Route path="/datenschutz" exact>
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.DATENSCHUTZ.url}/`}
            />
          </Route>
          <Route path="/online-bewerbung" exact>
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.BEWERBUNG.url}/`}
            />
          </Route>
          <Route path="/anfrage">
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.ANFRAGE.url}/`}
            />
          </Route>
          <Route path="/:brand/:page/*" exact>
            <Layout></Layout>
          </Route>
          <Route path="/:brand/:page" exact>
            <Redirect to={`${pathname}/`}></Redirect>
          </Route>
          <Route path="/:brand" exact>
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
            />
          </Route>
          <Route path="/" exact>
            <Redirect
              to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
            />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
}

export default App;

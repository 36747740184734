import { useParams } from 'react-router-dom';

// IMPORT MUI COMPONENTS
import ColorButton from '@mui/material/Button';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';

// IMPORT FONTAWESOME ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Button(props) {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  let color = null;
  if (props.disabled) color = 'var(--text-color)';
  else if (props.color && !props.disabled) color = props.color;
  else {
    let primeColor = null;
    if (!brandObject) {
      primeColor = 'var(--vw-color)';
    } else {
      primeColor = brandObject.primeColor;
    }
    color = primeColor;
  }
  const style = {
    backgroundColor: 'transparent',
    border: '1px solid ' + color,
    boxShadow: 'none',
    color: color,
    width: '100%',
    maxWidth: props.maxWidth ? props.maxWidth : '100%',
    borderRadius: '200px',
    marginTop: '5px',
    marginBottom: '5px',
  };
  return (
    <ColorButton
      variant="contained"
      style={style}
      onClick={props.onClick}
      type={props.type}
      target={props.target}
      disabled={props.disabled}
    >
      {props.icon && (!props.iconPos || props.iconPos === 'pre') && (
        <FontAwesomeIcon icon={props.icon} style={{ marginRight: '5px' }} />
      )}
      <span>{props.children}</span>
      {props.icon && props.iconPos === 'post' && (
        <FontAwesomeIcon icon={props.icon} style={{ marginLeft: '5px' }} />
      )}
    </ColorButton>
  );
}

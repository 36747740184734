import { useCallback, useContext, useEffect } from 'react';
// import classes from './Gebrauchtwagen.module.css';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { useHistory, useParams } from 'react-router-dom';
import SuggestedCars from '../Cars/SuggestedCars';
// import { useBrandFind } from '../../hooks/useFinders';
import {
  Box,
  Fab,
  // Tab,
  Zoom,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import CompareContext from '../../store/compare-context';
import { globals } from '../../data/global-vars';
// import { StyledTabs, TabPanel } from '../UI/TabController';
// import SwipeableViews from 'react-swipeable-views';
// import { useTheme } from '@mui/material/styles';
import Gebrauchtwagenbestand from './Gebrauchtwagenbestand';
// import Button from '../UI/Button';

function Gebrauchtwagen() {
  const params = useParams();
  // const theme = useTheme();
  const brand = params.brand;
  const history = useHistory();
  // const brandObject = useBrandFind(brand);
  const compareCtx = useContext(CompareContext);
  const isMobile = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    if (brand === globals.BRANDS.VW_NFZ.url)
      history.push(
        `/${globals.BRANDS.VW.url}/${globals.PAGES.GEBRAUCHTWAGEN.url}`
      );
  });

  // const tabKey = params[0].split('/');
  // const [tabPanel, setTabPanel] = useState(tabKey[0] === 'ankauf' ? 1 : 0);

  // useEffect(() => {
  // if (tabKey[0] === 'ankauf') {
  //   setTabPanel(1);
  // window.AuEmbed = window.AuEmbed || {
  //   config: {
  //     customData: {
  //       leadSource: 'AutoUncle Inzahlungnahme Lead',
  //     },
  //   },
  // };
  // } else {
  // setTabPanel(0);
  // window.AuEmbed = window.AuEmbed || {
  //   config: {
  //     customData: {
  //       leadSource: '',
  //     },
  //   },
  // };
  // }
  // }, [tabPanel, tabKey]);

  // trigger for scroll up button
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 100,
  });
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    localStorage.removeItem('detailId');
    history.push(`/${brand}/${globals.PAGES.GEBRAUCHTWAGEN.url}/p=${1}`);
  }, []);

  // change auto uncle button if scroll up button appears
  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     '--au-bottom-offset',
  //     24 +
  //       (trigger && 50) +
  //       (compareCtx.cars.length >= 1 && !isMobile && 50) +
  //       'px'
  //   );
  // }, [trigger, compareCtx.cars, isMobile]);

  // function getCorrespondingUrl(index) {
  //   switch (index) {
  //     case 0:
  //       return 'p=1';

  //     case 1:
  //       return 'ankauf';
  //     default:
  //       return '';
  //   }
  // }

  // function handleTabChange(event, index) {
  //   setTabPanel(index);
  //   const url = getCorrespondingUrl(index);
  //   history.push(`/${brand}/${globals.PAGES.GEBRAUCHTWAGEN.url}/` + url);
  // }

  // function handleChangeTabIndex(index) {
  //   setTabPanel(index);
  //   const url = getCorrespondingUrl(index);
  //   history.push(`/${brand}/${globals.PAGES.GEBRAUCHTWAGEN.url}/` + url);
  // }

  return (
    <>
      <div className="container">
        <PageHeading title="Gebrauchtwagen">Gebrauchtwagen</PageHeading>
        {/* <Grid xs={12} className={classes.tabBar}> */}
        {/* <StyledTabs
            value={tabPanel}
            indicatorColor={brandObject.primeColor}
            variant="fullWidth"
            onChange={handleTabChange}
            sx={{ marginBottom: '20px' }}
          > */}
        {/* <Tab label="Unser Bestand" /> */}
        {/* <Tab label="Fahrzeugankauf" /> */}
        {/* </StyledTabs> */}
        {/* </Grid> */}
        {/* <SwipeableViews
          axis="x"
          index={tabPanel}
          onChangeIndex={handleChangeTabIndex}
          // enableMouseEvents
          disabled
          style={{
            marginBottom: '40px',
            paddingLeft: 'calc(env(safe-area-inset-left))',
            paddingRight: 'calc( env(safe-area-inset-right))',
          }}
          className="noSelect"
        >
          <TabPanel value={tabPanel} index={0} dir={theme.direction}> */}
        <Gebrauchtwagenbestand />
        {/* </TabPanel> */}
        {/* <TabPanel value={tabPanel} index={1} dir={theme.direction}>
            <Grid xs={12}>
              {!isMobile ? (
                <iframe
                  src="https://www.autouncle.de/widgets/trade_in_valuations/new?c_id=0mCUDQSuFb&amp;car_url=https%3A%2F%2Fautohaus-seyfarth.de%2Fvw%2Fgebrauchtwagen%2Fankauf&amp;depId=null&amp;d=%7B%22device%22%3A%22desktop%22%2C%22url%22%3A%22https%3A%2F%2Fautohaus-seyfarth.de%2Fvw%2Fdetails%2Fabc%22%2C%22ip%22%3A%222003%3Ac3%3Af15%3A8300%3Acd11%3A867f%3A9a38%3A66d3%22%2C%22depId%22%3Anull%7D&amp;data_from_source=%7B%22customData%22%3A%7B%22leadSource%22%3A%22AutoUncle%20Inzahlungnahme%20Lead%22%7D%2C%22integrationType%22%3A%22inline-form%22%7D&amp;bm_text=&amp;hide_au_logo=1&amp;parent_id=au-trade-in-calculator-53b84"
                  title="iframe"
                  height="1110px"
                  width="100%"
                  style={{ border: 'none', borderRadius: '8px' }}
                ></iframe>
              ) : (
                <Button
                  className="au-widget-trade-in"
                  onClick={() =>
                    window.auws.openModal(window.auws.ModuleTypes.TRADE_IN)
                  }
                  maxWidth="400px"
                >
                  Jetzt Fahrzeug in Zahlung geben!
                </Button>
              )}
            </Grid>
          </TabPanel> */}
        {/* </SwipeableViews> */}
        <Grid xs={12} sx={{ paddingBottom: '40px' }}>
          <SuggestedCars />
        </Grid>
      </div>
      <Zoom in={trigger}>
        <Box
          role="presentation"
          sx={{
            position: 'fixed',
            bottom: compareCtx.cars.length > 0 && !isMobile ? 84 : 32,
            right: 32,
            zIndex: 1,
          }}
        >
          <Fab
            onClick={scrollToTop}
            color="primary"
            size="small"
            aria-label="scroll back to top"
            sx={{ borderRadius: '8px' }}
          >
            <KeyboardArrowUp />
          </Fab>
        </Box>
      </Zoom>
    </>
  );
}

export default Gebrauchtwagen;

import classes from './Users.module.css';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import ModalContext from '../../store/modal-context';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import {
  faCancel,
  faCircleMinus,
  faCirclePlus,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';
import usePostContent from '../../hooks/usePostContent';
import usePatchContent from '../../hooks/usePatchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import styled from '@mui/material/styles/styled';
import { TextField, Box } from '@mui/material';
import PopupContext from '../../store/popup-context';
import useDeleteContent from '../../hooks/useDeleteContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// STYLE MUI COMPONENT
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
});

// error message for adding a new employee
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

export function UserForm(props) {
  const { isLoading, error, postUser } = usePostContent();
  const { error: updateError, patchUser } = usePatchContent();
  const s = props.selected;

  const [add, setAdd] = useState(s.edit ? false : true);
  const [username, setUsername] = useState(s.username ? s.username : '');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setUsername(s.username ? s.username : '');
    setPassword('');
    setAdd(s.edit ? false : true);
  }, [s]);

  function clear() {
    setAdd(true);
    setUsername('');
    setPassword('');
  }

  function handleSubmit(e) {
    e.preventDefault();
    const user = {
      username,
      password,
      role: 'employee',
    };

    if (add) {
      postUser(user, (d) => {
        if (!error) {
          props.patchUsers(d, 'add');
        }
      });
    } else {
      patchUser(s._id, user, (d) => {
        if (!updateError) {
          props.patchUsers(d, 'edit');
        }
      });
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  } else
    return (
      <form
        style={{
          width: '100%',
          border: '1px solid var(--text-color)',
          borderRadius: '20px',
          marginBottom: '40px',
        }}
        onSubmit={handleSubmit}
      >
        <StyledBox>
          {error || (updateError && errorMessage)}
          <Grid xs={12} container spacing={2}>
            <Grid xs={12} sm={6}>
              <TextField
                label="Accountname"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="Passwort"
                variant="outlined"
                type="password"
                fullWidth
                className={classes.addInput}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
              }}
              container
            >
              <Grid xs="auto">
                <Button
                  color="red"
                  icon={faCircleMinus}
                  disabled={username === '' && password === '' ? true : false}
                  maxWidth="200px"
                  onClick={clear}
                >
                  Auswahl leeren
                </Button>
              </Grid>
              <Grid xs="auto">
                <Button
                  color="green"
                  icon={add ? faCirclePlus : faPencil}
                  type="submit"
                  disabled={username === '' && password === '' ? true : false}
                  maxWidth="200px"
                >
                  {add ? 'Hinzufügen' : 'Aktualisieren'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </StyledBox>
      </form>
    );
}

function UserLine(props) {
  const e = props.data;
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteUser } = useDeleteContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColor = brandObject.primeColor;

  function deleteUserHandler() {
    showModal(
      e.username,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            deleteUser(e._id, (d) => {
              if (!deleteError) {
                props.patchUsers(d, 'delete');
              } else {
                setPopupMessage(
                  'Fehler beim Löschen des Mitarbeiters!',
                  'error'
                );
              }
              closeModal();
            });
          }}
          color="red"
        >
          ACCOUNT ENTFERNEN
        </Button>
      </div>
    );
  }

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left',
        height: '100%',
        padding: '10px',
        position: 'relative',
        backgroundColor: 'var(--light-contrast)',
        borderRadius: '20px',
      }}
    >
      <span className={classes.lineName} style={{ color: primeColor }}>
        {e.username}
      </span>
      <div className={classes.editListIcons}>
        <FontAwesomeIcon
          icon={faPencil}
          className={classes.editListEditIcon}
          onClick={() => {
            props.setSelected({
              ...e,
              edit: true,
            });
            window.scrollTo(0, 0);
            setPopupMessage('Zur Bearbeitung ausgewählt!', 'info');
          }}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className={classes.editListCancelIcon}
          onClick={deleteUserHandler}
        />
      </div>
    </Grid>
  );
}

export function UserList(props) {
  return (
    <Grid xs={12} container>
      {props.users.map((e, i) => {
        return (
          <Grid
            xs={12}
            sm={6}
            key={i}
            sx={{
              margin: '10px 0',
              padding: '0 10px',
            }}
            container
          >
            <UserLine
              data={e}
              setSelected={props.setSelected}
              selected={props.selected}
              patchUsers={props.patchUsers}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default UserList;

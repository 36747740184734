import waage from '../assets/icons/waage.webp';
import lock from '../assets/icons/lock.webp';
import caravan from '../assets/icons/caravan_measure.webp';
import trash from '../assets/icons/trash.webp';

export const checklisten = [
  {
    title: 'Vor Fahrtantritt',
    image: waage,
    link: 'https://www.youtube.com/watch?v=noSkV6x86C8&feature=emb_logo',
  },
  {
    title: 'Während der Fahrt',
    image: caravan,
    link: 'https://www.youtube.com/watch?v=cbhoZ80oC-s&feature=emb_logo',
  },
  {
    title: 'Fahrzeug verlassen',
    image: lock,
    link: 'https://www.youtube.com/watch?v=lZDWt1j4tkI&feature=emb_logo',
  },
  {
    title: 'Fahrzeugrückgabe',
    image: trash,
    link: 'https://www.youtube.com/watch?v=m6cHpEpnfnY&feature=emb_logo',
  },
];

import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useContext, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import classes from './DragAndDropFiles.module.css';
import PopupContext from '../../store/popup-context';

function DragAndDropFiles(props) {
  const { setPopupMessage } = useContext(PopupContext);
  const [label, setLabel] = useState(
    !props.label ? 'Datei hinzufügen' : props.label
  );

  useEffect(() => {
    if (props.label) setLabel(props.label);
  }, [props.label]);
  return (
    <Fragment>
      <FileUploader
        handleChange={props.handleChange}
        name="file"
        types={props.types}
        label={props.label}
        onDrop={(d) => setLabel(d.name)}
        onTypeError={() => setLabel('Dieses Format wird nicht unterstützt.')}
        // maxSize={props.maxSize}
        // onSizeError={props.onSizeError}
        maxSize={1}
        onSizeError={() => {
          props.handleChange(null);
          setPopupMessage('Datei zu groß. Max 1 Megabyte.', 'error');
        }}
      >
        <div
          className={`${classes.uploader} noSelect`}
          style={{ cursor: 'pointer' }}
        >
          <FontAwesomeIcon icon={faFile} className={classes.icon} />
          <span className={classes.label}>{label}</span>
          <span className={classes.types}>{props.types.join(', ')}</span>
        </div>
      </FileUploader>
    </Fragment>
  );
}

export default DragAndDropFiles;

import { useCallback, useContext } from 'react';
import AuthContext from '../store/auth-context';
import useHttp from './use-http';

const usePatchContent = () => {
  const { isLoading, error, sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);

  const patchSingleText = useCallback(
    async (textId, newData, applyData) => {
      const { field, value } = newData;
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/pagetext/' + textId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              [field]: value,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchMultiText = useCallback(
    async (textId, newData, applyData) => {
      const { field, value } = newData;
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/multipagetext/' + textId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              [field]: value,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchMultiTextEntry = useCallback(
    async (textId, textEntryId, newData, applyData) => {
      const { field, value } = newData;
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/multipagetext/' + textId + '/text/' + textEntryId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              [field]: value,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const sortMultiTextEntries = useCallback(
    async (multiTextId, textEntryId, previousId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url:
              '/api/v1/multipagetext/' +
              multiTextId +
              '/text/' +
              textEntryId +
              '/sort/behind/' +
              previousId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchNews = useCallback(
    async (newsId, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/news/' + newsId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchInternalNews = useCallback(
    async (newsId, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/internalnews/' + newsId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchShortcut = useCallback(
    async (shortcutId, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/shortcut/' + shortcutId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchNewCarModel = useCallback(
    async (carModelId, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/newcar/' + carModelId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const sortShortcuts = useCallback(
    async (shortcutId, previousId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url:
              '/api/v1/shortcut/' + shortcutId + '/sort/behind/' + previousId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const sortNewCarModels = useCallback(
    async (newCarId, previousId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/newcar/' + newCarId + '/sort/behind/' + previousId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchEvent = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/event/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchEmployee = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/person/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchJob = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/job/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchProduct = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/product/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchCaravanClass = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/caravan/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchOpeningHour = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/openinghour/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchOpeningHourSingleDay = useCallback(
    async (_id, _dayId, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/openinghour/' + _id + '/day/' + _dayId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchUser = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/user/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const sortMembersInDepartments = useCallback(
    async (departmentId, employeeId, previousId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url:
              '/api/v1/department/' +
              departmentId +
              '/member/' +
              employeeId +
              '/sort/behind/' +
              previousId,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const patchDepartment = useCallback(
    async (_id, newData, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/department/' + _id,
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
            body: {
              ...newData,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  return {
    isLoading,
    error,
    patchSingleText,
    patchMultiText,
    patchMultiTextEntry,
    sortMultiTextEntries,
    patchNews,
    patchInternalNews,
    patchNewCarModel,
    patchShortcut,
    sortShortcuts,
    sortNewCarModels,
    patchEvent,
    patchEmployee,
    patchJob,
    patchProduct,
    patchCaravanClass,
    patchOpeningHour,
    patchOpeningHourSingleDay,
    patchUser,
    sortMembersInDepartments,
    patchDepartment,
  };
};

export default usePatchContent;

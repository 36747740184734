import classes from './PageLoader.module.css';
import PageLoaderIcon from './PageLoaderIcon';

function PageLoader(props) {
  return (
    <div className={!props.show ? classes.display_none : classes.loader}>
      <PageLoaderIcon isLoading={props.show} />
    </div>
  );
}

export default PageLoader;

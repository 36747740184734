import { useEffect, useState } from 'react';

// IMPORT MUI AND OTHER COMPONENTS
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material/';
import Grid from '@mui/material/Unstable_Grid2';
import PriceInfo from '../UI/PriceInfo';

function DisplayOptions(props) {
  const [priceOptions, setPriceOptions] = useState([]);

  useEffect(() => {
    props.setPriceOptions(priceOptions);
  }, [priceOptions]);

  const handleChange = (event) => {
    props.setSortHandler(event.target.value);
    localStorage.setItem('sort', event.target.value);
    localStorage.removeItem('carsLoadedArray');
  };

  const options = props.options;
  const order = props.order;
  function toggleOption(key, value) {
    props.setOptions((obj) => {
      return { ...obj, [key]: [value, obj[key][1]] };
    });
  }

  return (
    <Grid
      xs={12}
      container
      sx={{
        color: 'var(--text-color)',
        wdith: '100%',
      }}
    >
      <Grid xs={12} sm={6} container>
        <Grid xs={12} sx={{ padding: '0 10px' }}>
          <FormControl fullWidth sx={{ textAlign: 'left' }}>
            <InputLabel id="demo-simple-select-label">Sortierung</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.sort}
              label="Sortierung"
              onChange={handleChange}
              sx={{ padding: '0 10px' }}
            >
              <MenuItem value="_sortby=model&_sortdir=asc">
                Alphabetisch
              </MenuItem>
              <MenuItem value="_sortby=importantDetails.price&_sortdir=asc">
                Preis aufsteigend
              </MenuItem>
              <MenuItem value="_sortby=importantDetails.price&_sortdir=desc">
                Preis absteigend
              </MenuItem>
              {!props.newCars && (
                <MenuItem value="_sortby=importantDetails.kilometers&_sortdir=asc">
                  Kilometerstand aufsteigend
                </MenuItem>
              )}
              {!props.newCars && (
                <MenuItem value="_sortby=importantDetails.kilometers&_sortdir=desc">
                  Kilometerstand absteigend
                </MenuItem>
              )}
              <MenuItem value="_sortby=importantDetails.powerKw&_sortdir=asc">
                Leistung aufsteigend
              </MenuItem>
              <MenuItem value="_sortby=importantDetails.powerKw&_sortdir=desc">
                Leistung absteigend
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sx={{ margin: '15px 0' }}>
          <PriceInfo select setSelected={setPriceOptions} />
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        spacing={0}
        sx={{ textAlign: 'left', padding: '10px' }}
      >
        {order.map((o, i) => {
          return (
            <Grid xs={12} md={6} key={i}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={options[o][0]}
                    color="primary"
                    onChange={(e) => {
                      toggleOption(o, e.target.checked);
                    }}
                  />
                }
                label={options[o][1]}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default DisplayOptions;

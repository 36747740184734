import * as React from 'react';
import classes from './DraggableList.module.css';
import { Draggable } from 'react-beautiful-dnd';

import { Paper, ListItem } from '@mui/material';

// import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function DraggableListItem({ item, index, children, id }) {
  return (
    <Draggable draggableId={id} index={index} style={{ margin: 0, padding: 0 }}>
      {(provided, snapshot) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={snapshot.isDragging ? classes.draggingListItem : ''}
        >
          {children}
        </ListItem>
      )}
    </Draggable>
  );
}

const List = React.memo((props) => {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
});

function DraggableList(props) {
  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(props.items, source.index, destination.index);
    props.setItems(newItems, destination.index, source.index);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.flexPaper}>
        <List items={props.items} onDragEnd={onDragEnd}>
          {props.children}
        </List>
      </Paper>
    </div>
  );
}

export default DraggableList;

//IMPORT OWN COMPONENTS
import classes from './Vergleichsseite.module.css';
import { Fragment, useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PageHeading from '../UI/PageHeading';
import Button from '../UI/Button';
import PriceInfo from '../UI/PriceInfo';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
import '../UI/swiperStyle.css';
import { Mousewheel, Scrollbar, Navigation, Pagination } from 'swiper';

// IMPORT FONTAWESOME ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import CompareContext from '../../store/compare-context';
import { useBrandFind } from '../../hooks/useFinders';

//Import MUI components
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useFetchContent from '../../hooks/useFetchContent';
import { useGetWidth } from '../../hooks/useWindowDimensions';
import dateformat from 'dateformat';
import { globals } from '../../data/global-vars';

const mousewheelOptions = {
  releaseOnEdges: true,
  forceToAxis: true,
  thresholdTime: 200,
  thresholdDelta: 20,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  margin: 0,
  padding: 0,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '12px' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  // paddingLeft: '20px',
  border: 'none',
  color: 'var(--text-color)',
  borderRadius: '8px',
  // marginTop: '5px',
  marginLeft: '55px',
}));

function CompareCar(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);

  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const compareCtx = useContext(CompareContext);
  const [brandUrl, setBrandUrl] = useState(brandObject.url);
  const history = useHistory();
  const _id = props._id;
  const carzillaId = props.carzillaId;
  const expanded = props.expanded;

  const { error, getSingleCar } = useFetchContent();
  const [c, setC] = useState(null);
  const [d, setD] = useState(null);
  const [f, setF] = useState(null);
  const [cons, setCons] = useState(null);
  const [cLoaded, setCLoaded] = useState(false);

  useEffect(() => {
    getSingleCar(_id, (data) => {
      if (!error) {
        setC(data);
        setD(data.importantDetails);
        setF(data.features);
        setCons(data.consumptionAndEnvironment);
        setCLoaded(true);

        // set brandUrl
        if (data.branch === 'moenchhof') setBrandUrl(globals.BRANDS.SKODA.url);
        else if (data.brand === 'seyfarth_nw' || data.branch === 'seyfarth_gw')
          setBrandUrl(globals.BRANDS.VW.url);
      }
    });
  }, [_id, error, getSingleCar]);

  // function parseDescription(text) {
  //   const sections = text.split(/\*{2}(.+?)\*{2}/).filter(Boolean);
  //   return text
  //     .split(/\*{2}(.+?)\*{2}/)
  //     .filter(Boolean)
  //     .reduce((acc, curr, index) => {
  //       if (index % 2 === 0) {
  //         acc.push({
  //           title: curr,
  //           items: sections[index + 1]
  //             .split('\\\\*')
  //             .map((k) => k.replace('* ', '').trim())
  //             .map((k) => k.replace(/\\/g, ''))
  //             .map((k) => k.replace('---- quality by dotzilla', ''))
  //             .filter((item) => item !== ''),
  //         });
  //       }
  //       return acc;
  //     }, []);
  // }

  const [descriptions, setDescriptions] = useState([]);

  useEffect(() => {
    if (cLoaded) {
      setDescriptions([
        {
          title: 'Überblick',
          items: [
            d.transmissionType,
            d.fuelType,
            d.powerKw + ' kw (' + Math.round(d.powerPS, 0) + ' PS)',
            'Baujahr: ' + d.yearOfConstruction,
            'Erstzulassung: ' +
              dateformat(new Date(d.firstRegistration), 'dd.mm.yyyy'),
            d.seatCount + ' Sitze',
            f.roadworthy && 'Verkehrstüchtig',
            f.fullServiceHistory && 'Scheckheftgepflegt',
            f.isDamaged && 'Beschädigt',
            f.isTaxi && 'Taxi',
            f.disabledAccessible && 'Behindertengerecht',
            f.hasWarranty && 'Mit Garantie',
            f.plugInHybrid && 'Plug-in-Hybrid',
            f.isAccidentDamaged && 'durch Unfall beschädigt',
            f.isNew && 'Neuwagen',
            f.nonSmokerVehicle && 'Nichtraucher-Fahrzeug',
            f.newHUonBuy && 'Neue HU bei Kauf',
            f.fourWheelDrive && 'Allradantrieb',
            f.isExportCar && 'Exportfahrzeug',
          ],
        },
        {
          title: 'Umwelt & Verbrauch',
          items: [
            d.fuelType,
            d.powerKw + ' kw (' + Math.round(d.powerPS, 0) + ' PS)',
            f.plugInHybrid && 'Plug-in-Hybrid',
            f.eCarWithoutBattery && 'Elektroauto ohne Batterie',
            f.eCarBatteryBought && 'Elektroauto mit gekaufter Batterie',
            f.eCarBatteryRented && 'Elektroauto mit gemieteter Batterie',
            f.bioDiesel && 'Biodiesel geeignet',
            f.e10Suitable && 'E10 geeignet',
            f.sportsSuspension && 'Sportfahrwerk',
            f.sportExhaustSystem && 'Sportauspuffanlage',

            cons.consumptionUrban && (
              <span>
                NEFZ: Kraftstoffverbrauch innerorts:{' '}
                {cons.consumptionUrban + ' '}L/100km*,
              </span>
            ),
            cons.consumptionExtraUrban && (
              <span>
                NEFZ: Kraftstoffverbrauch außerorts:{' '}
                {cons.consumptionExtraUrban + ' '}L/100km*,
              </span>
            ),
            cons.consumptionCombined && (
              <span>
                NEFZ: Kraftstoffverbrauch kombiniert:{' '}
                {cons.consumptionCombined + ' '}L/100km*,
              </span>
            ),
            cons.electricConsumptionCombined && (
              <span>
                NEFZ: Stromverbrauch (kombiniert):{' '}
                {cons.electricConsumptionCombined + ' '}kWh/100km,
              </span>
            ),
            cons.co2Emission && (
              <span>
                NEFZ: CO2-Emissionen kombiniert: {cons.co2Emission + ' '}g/km*,
              </span>
            ),
            cons.consumptionWLTPCombined && (
              <span>
                WLTP: Kraftstoffverbrauch (kombiniert):{' '}
                {cons.consumptionWLTPCombined + ' '}L/100km,
              </span>
            ),
            cons.WLTPSlow && (
              <span>
                WLTP: Kraftstoffverbrauch (langsam): {cons.WLTPSlow + ' '}
                L/100km,
              </span>
            ),
            cons.WLTPMedium && (
              <span>
                WLTP: Kraftstoffverbrauch (mittel): {cons.WLTPMedium + ' '}
                L/100km,
              </span>
            ),
            cons.WLTPFast && (
              <span>
                WLTP: Kraftstoffverbrauch (schnell): {cons.WLTPFast + ' '}
                L/100km,
              </span>
            ),
            cons.WLTPVeryFast && (
              <span>
                WLTP: Kraftstoffverbrauch (sehr schnell):{' '}
                {cons.WLTPVeryFast + ' '}L/100km,
              </span>
            ),
            cons.electricConsumptionCombinedWLTP && (
              <span>
                WLTP: Stromverbrauch (kombiniert):{' '}
                {cons.electricConsumptionCombinedWLTP + ' '}kWh/100km,
              </span>
            ),
            cons.emissionWLTP && (
              <span>WLTP: CO2-Emissionen: {cons.emissionWLTP + ' '}g/km,</span>
            ),
            cons.co2Efficiency && (
              <span>CO2-Effizienzklasse: {cons.co2Efficiency + '*'},</span>
            ),
            cons.emissionClass && (
              <span>Emissionsklasse: {cons.emissionClass},</span>
            ),
          ],
        },
        {
          title: 'Technik & Sicherheit',
          items: [
            f.alarmSystem && 'Alarmanlage',
            f.particleFilter && 'Partikelfilter',
            f.startStopSystem && 'Start/Stop-Automatik',
            f.immobilizer && 'Wegfahrsperre',
            f.centralLocking && 'Zentralverriegelung',
            f.keylessCentralLocking && 'Keyless-Zentralverriegelung',
            f.powerAssistedSteering && 'Servolenkung',
            f.emergencyCallSystem && 'Notrufsystem',
            f.emergencyBrakeAssist && 'Notbremsassistent',
            f.esp && 'ESP',
            f.abs && 'ABS',
            f.rainSensor && 'Regensensor',
            f.fullServiceHistory && 'Scheckheftgepflegt',
            f.hasWarranty && 'Mit Garantie',
          ],
        },
        {
          title: 'Assistenzsysteme',
          items: [
            f.cruiseControl && 'Tempomat',
            f.adaptiveCruiseControl && 'Adaptiver Tempomat',
            f.laneKeepingAssist && 'Spurhalteassistent',
            f.laneChangeAssist && 'Spurwechselassistent',
            f.emergencyBrakeAssist && 'Notbremsassistent',
            f.hillStartAssist && 'Berganfahrassistent',
            f.fatigueWarningSystem && 'Müdigkeitserkennung',
            f.emergencyCallSystem && 'Notrufsystem',
            f.tyrePressureMonitoring && 'Reifendrucküberwachung',
            f.distanceWarningSystem && 'Abstandswarnsystem',
            f.parkingSensorsFront && 'Einparkhilfe vorne',
            f.parkingSensorsRear && 'Einparkhilfe hinten',
            f.parkingSensorsSelfSteeringSystem &&
              'Einparkhilfe mit Lenkunterstützung',
            f.trafficSignRecognition && 'Verkehrszeichenerkennung',
            f.nightVisionAssist && 'Nachtsichtassistent',
            f.highBeamAssist && 'Fernlichtassistent',
            f.tractionControl && 'Traktionskontrolle',
            f.rangeExtender && 'Reichweitenverlängerer',
            f.speedLimitControlSystem && 'Geschwindigkeitsregelanlage',
          ],
        },
        {
          title: 'Komfort',
          items: [
            f.multifunctionSteeringWheel && 'Multifunktionslenkrad',
            f.parkingSensorsFront && 'Einparkhilfe vorne',
            f.parkingSensorsRear && 'Einparkhilfe hinten',
            f.parkingSensorsCamera && 'Rückfahrkamera',
            f.parkingSensorsCamera360 && '360°-Kamera',
            f.electricWindows && 'Elektrische Fensterheber',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
            f.heatedOutsideMirror && 'Beheizbare Außenspiegel',
            f.parkingSensorsSelfSteeringSystem &&
              'Einparkhilfe mit Lenkunterstützung',
            f.rainSensor && 'Regensensor',
            f.heatedSeats && 'Sitzheizung',
            f.seatVentilation && 'Sitzbelüftung',
            f.parkingHeater && 'Standheizung',
            f.clima2Zones && '2-Zonen-Klimaautomatik',
            f.automaticClimatisation && 'Automatische Klimatisierung',
            f.manualClimatisation && 'Manuelle Klimatisierung',
            f.lumbarSupport && 'Lordosenstütze',
            f.airSuspension && 'Luftfederung',
            f.massageSeats && 'Massagesitze',
            f.heatedSteeringWheel && 'Beheizbares Lenkrad',
            f.electricTailgate && 'Elektrische Heckklappe',
          ],
        },
        {
          title: 'Sicht',
          items: [
            f.lightSensor && 'Lichtsensor',
            f.LEDHeadlights && 'LED-Scheinwerfer',
            f.daytimeRunningLights && 'Tagfahrlicht',
            f.LEDRunningLights && 'LED-Tagfahrlicht',
            f.xenonHeadlights && 'Xenon-Scheinwerfer',
            f.biXenonHeadlights && 'Bi-Xenon-Scheinwerfer',
            f.laserHeadlights && 'Laser-Scheinwerfer',
            f.fogLamp && 'Nebelscheinwerfer',
            f.glareFreeHighBeamHeadlights && 'Blendfreies Fernlicht',
            f.automaticallyDimmingInteriorMirror &&
              'Automatisch abblendender Innenrückspiegel',
            f.adaptiveCorneringLights && 'Adaptive Kurvenlichter',
            f.headlightWasherSystem && 'Scheinwerferreinigungsanlage',
            f.heatedWindshield && 'Beheizbare Windschutzscheibe',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
          ],
        },
        {
          title: 'Innen',
          items: [
            f.leatherSteeringWheel && 'Lederlenkrad',
            f.armRest && 'Mittelarmlehne',
            f.armRest && 'Armauflage',
            f.heatedSeats && 'Sitzheizung',
            f.heatedRearSeats && 'Beheizbare Rücksitze',
            f.seatVentilation && 'Sitzbelüftung',
            f.isofix && 'Isofix',
            f.isofixPassengerSeat && 'Isofix auf Beifahrersitz',
            f.electricBackseatAdjustment &&
              'Elektrische Einstellung der Rücksitze',
            f.lumbarSupport && 'Lordosenstütze',
            f.airSuspension && 'Luftfederung',
            f.massageSeats && 'Massagesitze',
            f.sunroof && 'Schiebedach',
            f.panoramicRoof && 'Panoramadach',
            f.automaticClimatisation && 'Automatische Klimatisierung',
            f.manualClimatisation && 'Manuelle Klimatisierung',
            f.clima2Zones && '2-Zonen-Klimaanlage',
            f.clima3Zones && '3-Zonen-Klimaanlage',
            f.clima4Zones && '4-Zonen-Klimaanlage',
            f.heatedSteeringWheel && 'Beheizbares Lenkrad',
            f.ambientLighting && 'Ambientebeleuchtung',
            f.skiBag && 'Skisack',
          ],
        },
        {
          title: 'Außen',
          items: [
            f.metallic && 'Metallic-Lackierung',
            f.alloyWheels && 'Leichtmetallfelgen',
            f.steelWheels && 'Stahlfelgen',
            f.cargoBarrier && 'Laderaumtrennwand',
            (f.roofRails || f.roofRack) && 'Dachreling',
            f.trailerCoupling && 'Anhängerkupplung',
            f.trailerCouplingSwiveling &&
              'Abnehmbare schwenkbare Anhängerkupplung',
            f.trailerCouplingDetachable && 'Abnehmbare Anhängerkupplung',
            f.trailerCouplingFix && 'Feste Anhängerkupplung',
            f.slidingDoor && 'Schiebetür',
            f.slidingDoorRight && 'Schiebetür rechts',
            f.slidingDoorLeft && 'Schiebetür links',
            f.slidingDoorBothSides && 'Schiebetür beidseitig',
            f.privacyGlas && 'Getönte Scheiben',
            f.heatInsulatingGlass && 'Wärmeschutzverglasung',
            f.heatedWindshield && 'Beheizbare Windschutzscheibe',
            f.electricSideMirror && 'Elektrische Seitenspiegel',
            f.electricTailgate && 'Elektrische Heckklappe',
          ],
        },
        {
          title: 'Infotainment',
          items: [
            f.bluetooth && 'Bluetooth',
            f.MP3Interface && 'MP3-Schnittstelle',
            f.multifunctionSteeringWheel && 'Multifunktionslenkrad',
            f.tunerOrRadio && 'Radio/Tuner',
            f.navigationSystem && 'Navigationssystem',
            f.DABRadio && 'DAB-Radio',
            f.digitalCockpit && 'Digitales Cockpit',
            f.integratedMusicStreaming && 'Integriertes Musik-Streaming',
            f.soundSystem && 'Soundsystem',
            f.voiceControl && 'Sprachsteuerung',
            f.touchscreen && 'Touchscreen',
            f.usbPort && 'USB-Anschluss',
            f.androidAuto && 'Android Auto',
            f.appleCarPlay && 'Apple CarPlay',
            f.WiFi && 'WiFi',
            f.bluetooth && 'Bluetooth',
            f.CDPlayer && 'CD-Player',
            f.boardComputer && 'Bordcomputer',
            f.headUpDisplay && 'Head-Up Display',
            f.MP3Interface && 'MP3-Schnittstelle',
            f.handsFreeKit && 'Freisprecheinrichtung',
            f.inductionSmartphoneCharging &&
              'Induktive Smartphone-Ladefunktion',
            f.integratedMusicStreaming && 'Integriertes Musik-Streaming',
            f.paddleShifters && 'Schaltwippen',
          ],
        },
        {
          title: 'Sitze + Polster',
          items: [
            f.heatedSeats && 'Sitzheizung',
            f.heatedRearSeats && 'Beheizbare Rücksitze',
            f.seatVentilation && 'Sitzbelüftung',
            f.isofix && 'Isofix',
            f.isofixPassengerSeat && 'Isofix auf Beifahrersitz',
            f.lumbarSupport && 'Lordosenstütze',
            f.airSuspension && 'Luftfederung',
            f.massageSeats && 'Massagesitze',
            f.sportSeats && 'Sportsitze',
            f.electricSeatAdjustment && 'Elektrische Sitzverstellung',
            f.vegetableOilSuitable && 'Pflanzenöl geeignet',
            f.foldFlatPassengerSeat && 'Umklappbarer Beifahrersitz',
            f.electricBackseatAdjustment &&
              'Elektrische Einstellung der Rücksitze',
          ],
        },
        {
          title: 'Räder & Reifen',
          items: [
            f.emergencyTyreRepairKit && 'Reifendichtmittel',
            f.tyresAllSeason && 'Ganzjahresreifen',
            f.tyresSummer && 'Sommerreifen',
            f.tyresWinter && 'Winterreifen',
            f.emergencyTyre && 'Notfallreifen',
            f.emergencyTyreRepairKit && 'Notfallreifen-Reparaturset',
            f.spareTyre && 'Ersatzreifen',
            f.steelWheels && 'Stahlfelgen',
            f.alloyWheels && 'Leichtmetallfelgen',
          ],
        },
        {
          title: 'Pakete',
          items: [
            f.winterPackage && 'Winterpaket',
            f.smokersPackage && 'Raucherpaket',
            f.sportsPackage && 'Sportpaket',
          ],
        },
      ]);
    }
  }, [cLoaded, f, cons, d]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    props.setExpanded(isExpanded ? panel : false);
  };
  let compare = compareCtx.cars.includes(carzillaId);
  if (!cLoaded || error) return;
  else
    return (
      <div className={classes.wrapper}>
        <FontAwesomeIcon
          icon={faHeart}
          onClick={() => compareCtx.toggleCars(c.carzillaId)}
          className={`${classes.compareIcon} ${compare && classes.active}`}
          style={{
            color: compare ? brandObject.primeColor : 'white',
            cursor: 'pointer',
          }}
        />

        <Swiper
          slidesPerView={1}
          className={classes.imageSwiper}
          grabCursor={true}
          mousewheel={mousewheelOptions}
          modules={[Mousewheel, Navigation, Scrollbar]}
          scrollbar={{ draggable: true }}
          centerInsufficientSlides={true}
          navigation
        >
          {Array.from({ length: c.pictureCount }).map((v, i) => {
            return (
              <SwiperSlide key={i} className={classes.imageSwiperSlide}>
                <img
                  className={classes.image}
                  alt={'Ansichtsfoto ' + c.brand + ' ' + c.model}
                  src={`https://autohaus-seyfarth.de/images/cars/${
                    c.carzillaId
                  }_${String(i + 1).padStart(2, '0')}.jpg`}
                  loading="lazy"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div style={{ marginBottom: '20px', marginLeft: '20px' }}>
          <h1 style={{ color: brandObject.primeColor }}>
            {c.model}
            {d.modelYear && ' (' + d.modelYear + ')'}
          </h1>
          <span>{c.brand}</span>
        </div>
        <span>
          <PriceInfo
            total={d.price}
            financing={c.financingProposal.monthlyRate}
            // leasing={c.financingProposal.monthlyRate}
            priceFeature="financing"
          />
        </span>

        <div className={classes.expansionpanel}>
          {descriptions.map((s, i) => {
            return (
              <Accordion
                key={i}
                expanded={expanded === 'panel' + i}
                onChange={
                  s.items.filter(Boolean).length >= 1
                    ? handleChangeAccordion('panel' + i)
                    : null
                }
                className={classes.accordion}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowForwardIosSharpIcon
                      sx={{ opacity: s.items.filter(Boolean).length < 1 && 0 }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={classes.accordionSummary}
                >
                  <Typography
                    variant={'h6'}
                    sx={{ cursor: s.items.length < 1 && 'auto' }}
                  >
                    {/* <FontAwesomeIcon
                      className={classes.iconspace}
                      icon={faSwatchbook}
                    /> */}
                    {s.title}
                  </Typography>
                </AccordionSummary>
                {s.items.filter(Boolean).length >= 1 && (
                  <AccordionDetails>
                    <ul style={{ margin: 0, padding: 0 }}>
                      {s.items.filter(Boolean).map((item, ii) => {
                        return (
                          <li style={{ margin: '5px 0' }} key={ii}>
                            {item}
                          </li>
                        );
                      })}
                    </ul>
                  </AccordionDetails>
                )}
              </Accordion>
            );
          })}
        </div>

        <div className={classes.btn}>
          <Button
            onClick={() =>
              history.push(`/${brandUrl}/details/${c.carzillaId}`, {
                scroll: true,
              })
            }
          >
            Fahrzeug ansehen
          </Button>
        </div>
      </div>
    );
}

function Vergleichsseite() {
  const { pathname } = useLocation();
  const tabKey = pathname.split('/');
  const carIds = tabKey[3].split('&');
  const uCarIds = Array.from(new Set(carIds));
  const [expanded, setExpanded] = useState('');
  const width = useGetWidth();
  return (
    <Fragment>
      <PageHeading title="Vergleich">Vergleichen</PageHeading>
      <div className={classes.swiperWrapper}>
        <Swiper
          slidesPerView={width <= 500 ? 1 : 'auto'}
          grabCursor={true}
          mousewheel={mousewheelOptions}
          className={`${classes.swiper} noSelect`}
          modules={[Mousewheel, Navigation, Pagination]}
          centerInsufficientSlides={true}
          pagination={{ clickable: true }}
        >
          {uCarIds.map((i) => {
            return (
              <SwiperSlide className={classes.swiperSlide} key={i}>
                <CompareCar
                  _id={i}
                  carzillaId={i}
                  setExpanded={setExpanded}
                  expanded={expanded}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Fragment>
  );
}

export default Vergleichsseite;

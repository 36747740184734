import { createContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { CModal } from '../components/UI/Modal';

const ModalContext = createContext({
  heading: '',
  subheading: '',
  body: '',
  noScroll: false,
  showModal: (heading, subheading, body, noScroll) => {},
  closeModal: () => {},
  updateHeight: () => {},
});

export const ModalProvider = ({ children }) => {
  const modalRef = useRef();
  const history = useHistory();
  const [heading, setHeading] = useState('');
  const [subheading, setSubheading] = useState('');
  const [body, setBody] = useState('');
  const [noScroll, setNoScroll] = useState(false);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === 'PUSH') {
        closeModalHandler();
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const showModalHandler = (heading, subheading, body, noScroll) => {
    setHeading(heading);
    setSubheading(subheading);
    setBody(body);
    setNoScroll(noScroll);
  };
  const closeModalHandler = () => {
    setHeading('');
    setSubheading('');
    setBody('');
    setNoScroll(false);
  };

  // trigger Modal to check if height of ckeditor has changed
  const updateHeight = () => {
    modalRef.current.updateHeight();
  };

  const contextValue = {
    heading,
    subheading,
    body,
    noScroll,
    showModal: showModalHandler,
    closeModal: closeModalHandler,
    updateHeight,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      <CModal ref={modalRef} />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;

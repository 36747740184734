import classes from './ServiceFeatures.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';

// IMPORT MUI AND OTHER COMPONENTS
import { Box } from '@mui/material/';
import PropTypes from 'prop-types';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import {
  MultiTextHeader,
  MultiTextTitle,
  MultiTextEntry,
  MultiTextAdminInfo,
  MultiTextWrapper,
  MultiTextSubmitIcon,
  MultiTextDeleteIcon,
} from '../UI/MultiText';
import AuthContext from '../../store/auth-context';
import usePostContent from '../../hooks/usePostContent';
import PopupContext from '../../store/popup-context';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  flexDirection: 'row-reverse',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '8px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '&:hover': {
    // backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  border: 'none',
  color: '#474747',
  textAlign: 'left',
  borderRadius: '8px',
  marginTop: '5px',
  padding: 0,
  hyphens: 'auto',
  paddingLeft: '25px',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function ServiceFeatures(props) {
  const authCtx = useContext(AuthContext);
  const [richText, setRichText] = useState(true);
  const { setPopupMessage } = useContext(PopupContext);
  const { error, postMultiTextEntry } = usePostContent();

  const [expandedPanel, setExpandedPanel] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const emptyMultiText = {
    title: '',
    texts: [],
    editedBy: '',
    editedAt: '',
    createdBy: '',
    createdAt: '',
  };
  const [editServiceleistungen, setEditServiceleistungen] = useState(false);
  const [serviceleistungen, setServiceleistungen] = useState(emptyMultiText);
  // const [serviceleistungenIsLoading, setServiceleistungenIsLoading] =
  //   useState(true);
  const [serviceleistungenLoaded, setServiceleistungenLoaded] = useState(false);
  const [newTitleServiceleistungen, setNewTitleServiceleistungen] =
    useState('');
  const [newEntryServiceleistung, setNewEntryServiceleistung] = useState('');

  useEffect(() => {
    props.setEditServiceleistungen(editServiceleistungen);
  }, [editServiceleistungen]);

  function submitNewServiceleistungHandler() {
    postMultiTextEntry(
      serviceleistungen._id,
      {
        title: newTitleServiceleistungen,
        text: newEntryServiceleistung,
      },
      (d) => {
        if (!error) {
          setNewTitleServiceleistungen('');
          setNewEntryServiceleistung('');
          setServiceleistungen(d);
          setPopupMessage(
            '"' +
              newTitleServiceleistungen +
              '" wurde erfolgreich hinzugefügt!',
            'success'
          );
        } else {
          setPopupMessage('Es gab einen Fehler beim Hochladen!', 'error');
        }
      }
    );
  }

  return (
    <MultiTextWrapper
      id="63ebcc5275e44ba4aef2572d"
      applyData={setServiceleistungen}
      setEdit={setEditServiceleistungen}
      value={serviceleistungen}
      setLoaded={setServiceleistungenLoaded}
    >
      {serviceleistungenLoaded && (
        <Fragment>
          <h1 className="subheading" id="serviceleistungen">
            <MultiTextHeader
              value={serviceleistungen}
              setValue={setServiceleistungen}
              edit={editServiceleistungen}
              setEdit={setEditServiceleistungen}
              richText={richText}
              setRichText={setRichText}
            />
          </h1>
          {serviceleistungen.texts.map((f, i) => {
            return (
              <Accordion
                key={i}
                expanded={expandedPanel === 'panel' + i}
                onChange={handleAccordionChange('panel' + i)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      textAlign: 'left',
                    }}
                    spacing={2}
                  >
                    <Grid xs={11}>
                      <MultiTextTitle
                        id={serviceleistungen._id}
                        value={f}
                        setValue={setServiceleistungen}
                        edit={editServiceleistungen}
                        setEdit={setEditServiceleistungen}
                      />
                    </Grid>
                    {editServiceleistungen && (
                      <Grid
                        xs={1}
                        sx={{
                          textAlign: 'right',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <MultiTextDeleteIcon
                          id={serviceleistungen._id}
                          value={f}
                          setValue={setServiceleistungen}
                          edit={editServiceleistungen}
                        />
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.detailEntry}>
                  <MultiTextEntry
                    id={serviceleistungen._id}
                    value={f}
                    setValue={setServiceleistungen}
                    edit={editServiceleistungen}
                    setEdit={setEditServiceleistungen}
                    richText={richText}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
          {authCtx.isAdmin && editServiceleistungen && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid
                  container
                  xs={12}
                  sx={{
                    textAlign: 'left',
                  }}
                  spacing={2}
                >
                  <Grid xs={11}>
                    <MultiTextTitle
                      id={serviceleistungen._id}
                      value={newTitleServiceleistungen}
                      setValue={setNewTitleServiceleistungen}
                      edit={editServiceleistungen}
                      setEdit={setEditServiceleistungen}
                      new
                    />
                  </Grid>
                  <Grid
                    xs={1}
                    sx={{
                      textAlign: 'right',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <MultiTextSubmitIcon
                      submit={submitNewServiceleistungHandler}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <MultiTextEntry
                  id={serviceleistungen._id}
                  value={newEntryServiceleistung}
                  setValue={setNewEntryServiceleistung}
                  edit={editServiceleistungen}
                  setEdit={setEditServiceleistungen}
                  richText={richText}
                  new
                />
              </AccordionDetails>
            </Accordion>
          )}
          <MultiTextAdminInfo value={serviceleistungen} />
        </Fragment>
      )}
    </MultiTextWrapper>
  );
}

export default ServiceFeatures;

import { useCallback, useContext } from 'react';
import AuthContext from '../store/auth-context';
import useHttp from './use-http';

const useFetchContent = () => {
  const { isLoading, error, sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);

  const getSingleText = useCallback(
    async (textId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/pagetext/' + textId + '/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/pagetext/' + textId,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getMultiText = useCallback(
    async (textId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/multipagetext/' + textId + '/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/multipagetext/' + textId,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getAllNews = useCallback(
    async (applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/news/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/news',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getSingleNews = useCallback(
    async (newsId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/news/' + newsId + '/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/news/' + newsId,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getAllInternalNews = useCallback(
    async (applyData) => {
      sendRequest(
        {
          url: '/api/v1/internalnews/',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getSingleInternalNews = useCallback(
    async (newsId, applyData) => {
      sendRequest(
        {
          url: '/api/v1/internalnews/' + newsId,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getAllShortcuts = useCallback(
    async (applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/shortcut/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/shortcut',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getSingleUserById = useCallback(
    async (uId, applyData) => {
      sendRequest(
        {
          url: '/api/v1/user/' + uId,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getAllEvents = useCallback(
    async (applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/event/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/event',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getSingleCar = useCallback(
    async (_id, applyData) => {
      sendRequest(
        {
          url: '/api/v1/car/' + _id,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllCarsPaginated = useCallback(
    async ({ page, limit, offset }, applyData) => {
      let query = '';
      if (page) query = query + '_page=' + page;
      if (limit) query = query + '&_limit=' + limit;
      if (offset) query = query + '&_offset=' + offset;
      sendRequest(
        {
          url: '/api/v1/car?' + query,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  // exception
  const getCarsFiltered = useCallback(
    async ({ page, limit, offset, sort }, filter, applyData) => {
      let query = '';
      if (page) query = query + '_page=' + page;
      if (limit) query = query + '&_limit=' + limit;
      if (offset) query = query + '&_offset=' + offset;
      if (sort) query = query + '&' + sort;
      sendRequest(
        {
          url: '/api/v1/car/filter?' + query,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            ...filter,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  // exception
  const getCarsFilteredOptions = useCallback(
    async (filter, applyData) => {
      sendRequest(
        {
          url: '/api/v1/car/filter/preflight',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            ...filter,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllCarFeatures = useCallback(
    async (filter, applyData) => {
      sendRequest(
        {
          url: '/api/v1/car/feature',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllSuggestedCars = useCallback(
    async (applyData) => {
      sendRequest(
        {
          url: '/api/v1/suggestedcar',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllSuggestedCarsByBrand = useCallback(
    async (brand, applyData) => {
      sendRequest(
        {
          url: '/api/v1/suggestedcar?brand=' + brand,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getNewCars = useCallback(
    async (brand, applyData) => {
      sendRequest(
        {
          url: '/api/v1/newCar/by/brand/' + brand,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllEmployees = useCallback(
    async (applyData) => {
      sendRequest(
        {
          url: '/api/v1/person/full',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getSingleEmployee = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/person/' + _id + '/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/person/' + _id,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getAllDepartmentsOfEmployee = useCallback(
    async (_id, applyData) => {
      sendRequest(
        {
          url: '/api/v1/person/' + _id + '/departments',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getAllDepartments = useCallback(
    async (applyData) => {
      sendRequest(
        {
          url: '/api/v1/department/full',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );
  const getAllDepartmentsOfBrand = useCallback(
    async (brand, applyData) => {
      sendRequest(
        {
          url: '/api/v1/department/by/brand/' + brand,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getSingleDepartmentByBrand = useCallback(
    async (brand, key, applyData) => {
      sendRequest(
        {
          url: '/api/v1/department/by/brand/' + brand + '/key/' + key,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllJobs = useCallback(
    async (applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/job/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/job',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getAllProducts = useCallback(
    async (applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/product/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/product',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getAllCaravanClasses = useCallback(
    async (applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/caravan/full',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      } else {
        sendRequest(
          {
            url: '/api/v1/caravan',
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const getAllOpeningHours = useCallback(
    async (applyData) => {
      sendRequest(
        {
          url: '/api/v1/openinghour/full',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getOpeningHoursByBrand = useCallback(
    async (_id, applyData) => {
      sendRequest(
        {
          url: '/api/v1/openinghour/' + _id,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getTodayOpeningHours = useCallback(
    async (brand, applyData) => {
      sendRequest(
        {
          url: '/api/v1/openinghour/today/by/brand/' + brand,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [sendRequest]
  );

  const getAllUsers = useCallback(
    async (applyData) => {
      sendRequest(
        {
          url: '/api/v1/user/full',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authCtx.token,
          },
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  const getSecureFile = useCallback(
    async (path, applyData) => {
      sendRequest(
        {
          url: '/api/v1/getfilesecure/' + path,
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + authCtx.token,
          },
          returnBlob: true,
        },
        (data) => {
          applyData(data);
        }
      );
    },
    [authCtx.token, sendRequest]
  );

  return {
    isLoading,
    error,
    getSingleText,
    getMultiText,
    getAllNews,
    getSingleNews,
    getAllInternalNews,
    getSingleInternalNews,
    getAllShortcuts,
    getSingleUserById,
    getAllEvents,
    getSingleCar,
    getAllCarsPaginated,
    getAllSuggestedCars,
    getAllSuggestedCarsByBrand,
    getNewCars,
    getAllEmployees,
    getSingleEmployee,
    getAllDepartmentsOfEmployee,
    getAllDepartments,
    getAllDepartmentsOfBrand,
    getSingleDepartmentByBrand,
    getAllJobs,
    getAllProducts,
    getAllCaravanClasses,
    getAllOpeningHours,
    getOpeningHoursByBrand,
    getTodayOpeningHours,
    getAllUsers,
    getSecureFile,
    getCarsFiltered,
    getCarsFilteredOptions,
    getAllCarFeatures,
  };
};

export default useFetchContent;

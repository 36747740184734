import { useCallback, useContext } from 'react';
import AuthContext from '../store/auth-context';
import useHttp from './use-http';

const useDeleteContent = () => {
  const { isLoading, error, sendRequest } = useHttp();
  const authCtx = useContext(AuthContext);

  const deleteNews = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/news/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteMultiTextEntry = useCallback(
    async (multipagetextId, textId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/multipagetext/' + multipagetextId + '/text/' + textId,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteInternalNews = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/internalnews/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteShortcut = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/shortcut/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteEvent = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/event/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteNewCar = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/newcar/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteEmployee = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/person/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteEmployeeFromDepartment = useCallback(
    async (employeeId, departmentId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/department/' + departmentId + '/member/' + employeeId,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteDepartment = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/department/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteJob = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/job/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteProduct = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/product/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteCaravanClass = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/caravan/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteExceptionFromOpeningHour = useCallback(
    async (_id, _eId, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/openinghour/' + _id + '/exception/' + _eId,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteSuggestedCar = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/suggestedcar/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  const deleteUser = useCallback(
    async (_id, applyData) => {
      if (authCtx.isLoggedIn) {
        sendRequest(
          {
            url: '/api/v1/user/' + _id,
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + authCtx.token,
            },
          },
          (data) => {
            applyData(data);
          }
        );
      }
    },
    [authCtx.isLoggedIn, authCtx.token, sendRequest]
  );

  return {
    isLoading,
    error,
    deleteNews,
    deleteMultiTextEntry,
    deleteInternalNews,
    deleteShortcut,
    deleteEvent,
    deleteNewCar,
    deleteEmployee,
    deleteEmployeeFromDepartment,
    deleteDepartment,
    deleteJob,
    deleteProduct,
    deleteCaravanClass,
    deleteExceptionFromOpeningHour,
    deleteSuggestedCar,
    deleteUser,
  };
};

export default useDeleteContent;

import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';

function PageHeading(props) {
  const defaultTitle = useRef(document.title);
  const title = props.title;

  const params = useParams();
  const brand = params.brand;
  const brandHouse = useBrandFind(brand, 'house');

  useEffect(() => {
    if (title) document.title = brandHouse.PAGE_TITLE + ' - ' + title;
    else document.title = brandHouse.PAGE_TITLE;
  }, [brand, title]);

  useEffect(
    () => () => {
      document.title = defaultTitle.current;
    },
    []
  );
  return (
    <div
      style={{
        maxWidth: 'var(--max-content-width)',
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        paddingLeft: 'calc(env(safe-area-inset-left))',
        // width: 'calc(100% - 20px)',
        paddingRight: 'calc( env(safe-area-inset-right))',
      }}
    >
      <h1
        style={{
          textAlign: 'left',
          color: 'var(--text-color)',
          textTransform: 'uppercase',
          fontSize: '24px',
          margin: 0,
          padding: '30px 20px',
          fontWeight: '400',
          backgroundColor: 'white',
        }}
      >
        {props.children}
      </h1>
    </div>
  );
}

export default PageHeading;

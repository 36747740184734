import classes from './ShortFilter.module.css';
import { useEffect, useState } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import React from 'react';

import './ReactTags.css';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { NumericFormat } from 'react-number-format';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { motion } from 'framer-motion';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';

// IMPORT FONTAWESOME ICONS
import { useHistory, useParams } from 'react-router-dom';
import { globals } from '../../data/global-vars';
import { faArrowRight, faX } from '@fortawesome/free-solid-svg-icons';
import useFetchContent from '../../hooks/useFetchContent';
import { Skeleton, TextField } from '@mui/material';
import Collapsible from '../UI/Collapsible';
import { useBrandFind } from '../../hooks/useFinders';

const featureSuggestions = [
  { key: 'isDamaged', translation: 'Beschädigt' },
  {
    key: 'automaticClimatisation',
    translation: 'Automatische Klimatisierung',
  },
  { key: 'manualClimatisation', translation: 'Manuelle Klimatisierung' },
  { key: 'taxi', translation: 'Taxi' },
  { key: 'disabledAccessible', translation: 'Behindertengerecht' },
  { key: 'metallic', translation: 'Metallic' },
  { key: 'hasWarranty', translation: 'Mit Garantie' },
  { key: 'alloyWheels', translation: 'Leichtmetallfelgen' },
  { key: 'esp', translation: 'ESP' },
  { key: 'abs', translation: 'ABS' },
  { key: 'trailerCoupling', translation: 'Anhängerkupplung' },
  { key: 'immobilizer', translation: 'Wegfahrsperre' },
  { key: 'navigationSystem', translation: 'Navigationssystem' },
  { key: 'sunroof', translation: 'Schiebedach' },
  { key: 'centralLocking', translation: 'Zentralverriegelung' },
  { key: 'electricWindows', translation: 'Elektrische Fensterheber' },
  { key: 'fourWheelDrive', translation: 'Allradantrieb' },
  { key: 'powerAssistedSteering', translation: 'Servolenkung' },
  { key: 'bioDiesel', translation: 'Biodiesel' },
  { key: 'fullServiceHistory', translation: 'Scheckheftgepflegt' },
  { key: 'cruiseControl', translation: 'Tempomat' },
  { key: 'parkingHeater', translation: 'Standheizung' },
  { key: 'xenonHeadlights', translation: 'Xenon-Scheinwerfer' },
  { key: 'heatedSeats', translation: 'Sitzheizung' },
  { key: 'particleFilter', translation: 'Partikelfilter' },
  { key: 'newHUonBuy', translation: 'Neue HU bei Kauf' },
  { key: 'isExportCar', translation: 'Exportfahrzeug' },
  { key: 'e10Suitable', translation: 'E10-geeignet' },
  { key: 'vegetableOilSuitable', translation: 'Pflanzenöl geeignet' },
  { key: 'electricSideMirror', translation: 'Elektrische Seitenspiegel' },
  { key: 'sportsSuspension', translation: 'Sportfahrwerk' },
  { key: 'bluetooth', translation: 'Bluetooth' },
  { key: 'sportsPackage', translation: 'Sportpaket' },
  { key: 'boardComputer', translation: 'Bordcomputer' },
  { key: 'CDPlayer', translation: 'CD-Player' },
  {
    key: 'electricSeatAdjustment',
    translation: 'Elektrische Sitzverstellung',
  },
  { key: 'headUpDisplay', translation: 'Head-Up Display' },
  { key: 'handsFreeKit', translation: 'Freisprecheinrichtung' },
  { key: 'MP3Interface', translation: 'MP3-Schnittstelle' },
  { key: 'multifunctionSteeringWheel', translation: 'Multifunktionslenkrad' },
  { key: 'skiBag', translation: 'Ski-Bag' },
  { key: 'tunerOrRadio', translation: 'Tuner/Radio' },
  { key: 'sportSeats', translation: 'Sportsitze' },
  { key: 'panoramicRoof', translation: 'Panoramadach' },
  { key: 'isofix', translation: 'Isofix' },
  { key: 'adaptiveCorneringLights', translation: 'Adaptive Kurvenlichter' },
  { key: 'lightSensor', translation: 'Lichtsensor' },
  { key: 'fogLamp', translation: 'Nebelscheinwerfer' },
  { key: 'daytimeRunningLights', translation: 'Tagfahrlicht' },
  { key: 'tractionControl', translation: 'Traktionskontrolle' },
  { key: 'startStopSystem', translation: 'Start-Stopp-System' },
  { key: 'rainSensor', translation: 'Regensensor' },
  { key: 'nonSmokerVehicle', translation: 'Nichtraucherfahrzeug' },
  { key: 'roofRails', translation: 'Dachreling' },
  { key: 'isAccidentDamaged', translation: 'Unfallwagen' },
  { key: 'roadworthy', translation: 'Verkehrssicher' },
  { key: 'parkingSensorsFront', translation: 'Einparkhilfe vorne' },
  { key: 'parkingSensorsRear', translation: 'Einparkhilfe hinten' },
  { key: 'parkingSensorsCamera', translation: 'Einparkhilfe mit Kamera' },
  {
    key: 'parkingSensorsCamera360',
    translation: 'Einparkhilfe mit 360-Grad-Kamera',
  },
  {
    key: 'parkingSensorsSelfSteeringSystem',
    translation: 'Einparkhilfe mit Selbstlenksystem',
  },
  { key: 'plugInHybrid', translation: 'Plug-in-Hybrid' },
  { key: 'headlightWasherSystem', translation: 'Scheinwerfer-Waschanlage' },
  { key: 'highBeamAssist', translation: 'Fernlichtassistent' },
  { key: 'ambientLighting', translation: 'Ambientebeleuchtung' },
  { key: 'heatedRearSeats', translation: 'Beheizbare Rücksitze' },
  {
    key: 'electricBackseatAdjustment',
    translation: 'Elektrische Rücksitzverstellung',
  },
  { key: 'smokersPackage', translation: 'Raucherpaket' },
  { key: 'foldFlatPassengerSeat', translation: 'Beifahrersitz umklappbar' },
  { key: 'digitalCockpit', translation: 'Digitales Cockpit' },
  { key: 'winterPackage', translation: 'Winterpaket' },
  { key: 'androidAuto', translation: 'Android Auto' },
  { key: 'appleCarPlay', translation: 'Apple CarPlay' },
  {
    key: 'inductionSmartphoneCharging',
    translation: 'Induktive Smartphone-Ladefunktion',
  },
  {
    key: 'integratedMusicStreaming',
    translation: 'Integriertes Musik-Streaming',
  },
  { key: 'WiFi', translation: 'WLAN' },
  { key: 'tyresAllSeason', translation: 'Ganzjahresreifen' },
  { key: 'tyresSummer', translation: 'Sommerreifen' },
  { key: 'tyresWinter', translation: 'Winterreifen' },
  { key: 'emergencyTyre', translation: 'Notfallreifen' },
  { key: 'emergencyTyreRepairKit', translation: 'Notfallreifenreparaturset' },
  { key: 'spareTyre', translation: 'Reserverad' },
  { key: 'steelWheels', translation: 'Stahlfelgen' },
  { key: 'eCarWithoutBattery', translation: 'Elektroauto ohne Batterie' },
  {
    key: 'eCarBatteryBought',
    translation: 'Elektroauto mit gekaufter Batterie',
  },
  {
    key: 'eCarBatteryRented',
    translation: 'Elektroauto mit gemieteter Batterie',
  },
  { key: 'rangeExtender', translation: 'Reichweitenverlängerer' },
  { key: 'isofixPassengerSeat', translation: 'Isofix auf Beifahrersitz' },
  { key: 'cargoBarrier', translation: 'Laderaumabtrennung' },
  {
    key: 'trailerCouplingSwiveling',
    translation: 'Schwenkbare Anhängerkupplung',
  },
  {
    key: 'trailerCouplingDetachable',
    translation: 'Abnehmbare Anhängerkupplung',
  },
  { key: 'trailerCouplingFix', translation: 'Feste Anhängerkupplung' },
  { key: 'slidingDoorRight', translation: 'Schiebetür rechts' },
  { key: 'slidingDoorLeft', translation: 'Schiebetür links' },
  { key: 'slidingDoorBothSides', translation: 'Schiebetür beidseitig' },
  { key: 'biXenonHeadlights', translation: 'Bi-Xenon-Scheinwerfer' },
  { key: 'laserHeadlights', translation: 'Laser-Scheinwerfer' },
  { key: 'alarmSystem', translation: 'Alarmanlage' },
  { key: 'heatedSteeringWheel', translation: 'Beheizbares Lenkrad' },
  {
    key: 'automaticallyDimmingInteriorMirror',
    translation: 'Automatisch abblendender Innenspiegel',
  },
  { key: 'laneKeepingAssist', translation: 'Spurhalteassistent' },
  { key: 'adaptiveCruiseControl', translation: 'Adaptiver Tempomat' },
  { key: 'LEDHeadlights', translation: 'LED-Scheinwerfer' },
  { key: 'emergencyBrakeAssist', translation: 'Notbremsassistent' },
  { key: 'distanceWarningSystem', translation: 'Abstandswarnsystem' },
  { key: 'laneChangeAssist', translation: 'Spurwechselassistent' },
  { key: 'seatVentilation', translation: 'Sitzbelüftung' },
  { key: 'armRest', translation: 'Armauflage' },
  { key: 'heatedWindshield', translation: 'Beheizbare Windschutzscheibe' },
  { key: 'hillStartAssist', translation: 'Berganfahrassistent' },
  { key: 'DABRadio', translation: 'DAB-Radio' },
  { key: 'electricTailgate', translation: 'Elektrische Heckklappe' },
  { key: 'LEDRunningLights', translation: 'LED-Tagfahrlicht' },
  { key: 'leatherSteeringWheel', translation: 'Lederlenkrad' },
  { key: 'lumbarSupport', translation: 'Lordosenstütze' },
  { key: 'airSuspension', translation: 'Luftfederung' },
  { key: 'massageSeats', translation: 'Massagefunktion für Sitze' },
  { key: 'fatigueWarningSystem', translation: 'Müdigkeitserkennungssystem' },
  { key: 'nightVisionAssist', translation: 'Nachtsichtassistent' },
  { key: 'emergencyCallSystem', translation: 'Notrufsystem' },
  { key: 'tyrePressureMonitoring', translation: 'Reifendrucküberwachung' },
  { key: 'paddleShifters', translation: 'Schaltwippen' },
  { key: 'slidingDoor', translation: 'Schiebetür' },
  { key: 'soundSystem', translation: 'Soundsystem' },
  { key: 'voiceControl', translation: 'Sprachsteuerung' },
  { key: 'touchscreen', translation: 'Touchscreen' },
  { key: 'usbPort', translation: 'USB-Anschluss' },
  { key: 'trafficSignRecognition', translation: 'Verkehrszeichenerkennung' },
  {
    key: 'keylessCentralLocking',
    translation: 'Keyless-Zentralverriegelung',
  },
  { key: 'clima2Zones', translation: 'Zweizonen-Klimaautomatik' },
  { key: 'clima3Zones', translation: 'Dreizonen-Klimaautomatik' },
  { key: 'clima4Zones', translation: 'Vierzonen-Klimaautomatik' },
  {
    key: 'glareFreeHighBeamHeadlights',
    translation: 'Blendfreies Fernlicht',
  },
  {
    key: 'speedLimitControlSystem',
    translation: 'Geschwindigkeitsregelanlage',
  },
  { key: 'roofRack', translation: 'Dachreling' },
  { key: 'heatedOutsideMirror', translation: 'Beheizbare Außenspiegel' },
  { key: 'privacyGlas', translation: 'Privacy-Verglasung' },
  { key: 'sportExhaustSystem', translation: 'Sportauspuffanlage' },
  { key: 'heatInsulatingGlass', translation: 'Wärmeschutzverglasung' },
];

Array.prototype.sortArray = function () {
  return this.slice().sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
};

function compareArrays(arr1, arr2) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

function reduceArrayAccordingToOptions(optionArray = [], setValueArray) {
  setValueArray((valueArray) =>
    valueArray.filter((value) => optionArray.includes(value))
  );
}

function ShortFilter(props) {
  const unfiltered = props.unfiltered;
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);

  const { getCarsFilteredOptions } = useFetchContent();
  const [count, setCount] = useState(0);
  const [d, setD] = useState(null);
  const [countLoaded, setCountLoaded] = useState(false);
  // BRANDS
  const [brands, setBrands] = useState(
    unfiltered?.brands || [brandObject.carzillaBrand]
  );
  const [includeOtherBrands, setIncludeOtherBrands] = useState(
    unfiltered?.includeOtherBrands
  );
  const [showOtherBrands, setShowOtherBrands] = useState(false);

  // MODELS
  const [models, setModels] = useState(unfiltered?.models || []);
  const [modelOptions, setModelOptions] = useState([]);

  // COLORS
  const [baseColors, setBaseColors] = useState(unfiltered?.baseColors || []);
  const [colorOptions, setColorOptions] = useState([]);

  // FUELTYPES
  const [fuelTypes, setFuelTypes] = useState(unfiltered?.fuelTypes || []);
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);

  // TRANSMISSION
  const [transmissions, setTransmissions] = useState(
    unfiltered?.transmissions || []
  );
  const [transmissionOptions, setTransmissionOptions] = useState([]);

  // TAGS
  const [features, setFeatures] = useState(unfiltered?.features || []);

  const [priceMin, setPriceMin] = useState(unfiltered?.priceFrom || 0);
  const [priceMax, setPriceMax] = useState(unfiltered?.priceTo || 0);
  const [mileageMin, setMileageMin] = useState(unfiltered?.kmFrom || 0);
  const [mileageMax, setMileageMax] = useState(unfiltered?.kmTo || 0);
  const [yearMin, setYearMin] = useState(unfiltered?.firstRegFrom || 0);
  const [yearMax, setYearMax] = useState(unfiltered?.firstRegTo || 0);

  // const [yearMinOption, setYearMinOption] = useState(unfiltered?.firstRegFrom || 0)
  // const [yearMaxOption, setYearMax]

  const history = useHistory();

  const handlePriceChange = (event, value) => {
    if (event) {
      if (event.target.name === 'priceMin') {
        setPriceMin(value);
      } else if (event.target.name === 'priceMax') {
        setPriceMax(value);
      }
    }
  };

  const handleMileageChange = (event, value) => {
    if (event) {
      if (event.target.name === 'mileageMin') {
        setMileageMin(value);
      } else if (event.target.name === 'mileageMax') {
        setMileageMax(value);
      }
    }
  };

  const handleYearChange = (event, value) => {
    if (event) {
      if (event.target.name === 'yearMin') {
        setYearMin(value);
      } else if (event.target.name === 'yearMax') {
        setYearMax(value);
      }
    }
  };

  // FIND ON SUBMIT
  function submitHandler() {
    if (props.short && !props.newCars) {
      history.push(
        `/${brandObject.url}/${globals.PAGES.GEBRAUCHTWAGEN.url}/p=1`,
        {
          filter: {
            isNew: props.newCars ? true : false,
            brand: brands,
            model: models,
            baseColor: baseColors,
            fuelType: fuelTypes,
            transmissionType: transmissions,
            tags: features.map(
              (k) =>
                featureSuggestions.find((obj, id) => obj.translation === k).key
            ),
            priceFrom: Number(priceMin),
            priceTo: Number(priceMax),
            firstRegFrom: props.newCars ? null : yearMin + '-01',
            firstRegTo: props.newCars ? null : yearMax + '-12',
            kmFrom: props.newCars ? null : Number(mileageMin),
            kmTo: props.newCars ? null : Number(mileageMax),
            class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
          },
          unfilteredFilter: {
            isNew: props.newCars ? true : false,
            brands,
            models,
            baseColors,
            fuelTypes,
            transmissions,
            features,
            priceFrom: Number(priceMin),
            priceTo: Number(priceMax),
            firstRegFrom: props.newCars ? null : Number(yearMin),
            firstRegTo: props.newCars ? null : Number(yearMax),
            kmFrom: props.newCars ? null : Number(mileageMin),
            kmTo: props.newCars ? null : Number(mileageMax),
            class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
            includeOtherBrands: includeOtherBrands,
          },
        }
      );
    } else {
      props.setFilter({
        isNew: props.newCars ? true : false,
        brand: brands,
        model: models,
        baseColor: baseColors,
        fuelType: fuelTypes,
        transmissionType: transmissions,
        tags: features.map(
          (k) => featureSuggestions.find((obj, id) => obj.translation === k).key
        ),
        priceFrom: Number(priceMin),
        priceTo: Number(priceMax),
        firstRegFrom: props.newCars ? null : yearMin + '-01',
        firstRegTo: props.newCars ? null : yearMax + '-12',
        kmFrom: props.newCars ? null : Number(mileageMin),
        kmTo: props.newCars ? null : Number(mileageMax),
        class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      });
    }
    let filterKey = 'filter';
    if (props.newCars) filterKey = 'newCarFilter';
    else if (props.mobileHome) filterKey = 'motorhomeFilter';
    localStorage.setItem(
      filterKey,
      JSON.stringify({
        filter: {
          isNew: props.newCars ? true : false,
          brand: brands,
          model: models,
          baseColor: baseColors,
          fuelType: fuelTypes,
          transmissionType: transmissions,
          tags: features.map(
            (k) =>
              featureSuggestions.find((obj, id) => obj.translation === k).key
          ),
          priceFrom: Number(priceMin),
          priceTo: Number(priceMax),
          firstRegFrom: props.newCars ? null : yearMin + '-01',
          firstRegTo: props.newCars ? null : yearMax + '-12',
          kmFrom: props.newCars ? null : Number(mileageMin),
          kmTo: props.newCars ? null : Number(mileageMax),
          class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
        },
        unfilteredFilter: {
          isNew: props.newCars ? true : false,
          brands,
          models,
          baseColors,
          fuelTypes,
          transmissions,
          features,
          priceFrom: Number(priceMin),
          priceTo: Number(priceMax),
          firstRegFrom: props.newCars ? null : Number(yearMin),
          firstRegTo: props.newCars ? null : Number(yearMax),
          kmFrom: props.newCars ? null : Number(mileageMin),
          kmTo: props.newCars ? null : Number(mileageMax),
          class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
          includeOtherBrands: includeOtherBrands,
        },
      })
    );
  }

  useEffect(() => {
    getCarsFilteredOptions(
      { class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'] },
      (d) => {
        // SHOW OTHER BRANDS OPTION IF MORE
        setShowOtherBrands(
          d.brands.filter(
            (v) =>
              ![
                globals.BRANDS.VW.carzillaBrand,
                globals.BRANDS.AUDI.carzillaBrand,
                globals.BRANDS.SKODA.carzillaBrand,
              ].includes(v) && v
          ).length >= 1
        );
      }
    );
  }, []);

  // TIER 1: BRANDS CHANGE --> RESET EVERYTHING
  useEffect(() => {
    getCarsFilteredOptions(
      {
        brand: brands,
        isNew: props.newCars ? true : false,
        class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      },
      (data) => {
        const d = data;
        setD(data);
        // setCount(data.count);
        // setCountLoaded(true);

        // RESET SELECTED DATA
        reduceArrayAccordingToOptions(d.models, setModels);
        reduceArrayAccordingToOptions(d.baseColors, setBaseColors);
        reduceArrayAccordingToOptions(d.fuelTypes, setFuelTypes);
        reduceArrayAccordingToOptions(d.transmissionTypes, setTransmissions);
        if (!compareArrays(brands, unfiltered?.brands)) {
          // tier 3
          setPriceMin(d.priceMin || 0);
          setPriceMax(d.priceMax || 0);
          setYearMax(new Date(d.firstRegistrationMax).getFullYear() || 0);
          setYearMin(new Date(d.firstRegistrationMin).getFullYear() || 0);
          setMileageMin(d.kmMin || 0);
          setMileageMax(d.kmMax || 0);
        }

        // SET OPTIONS OF TIER 2
        setModelOptions(
          d.models
            .map((k) => {
              return { label: k, value: k };
            })
            .sortArray()
        );
        setColorOptions(
          d.baseColors
            .map((k) => {
              return { label: k, value: k };
            })
            .sortArray()
        );
        setFuelTypeOptions(
          d.fuelTypes
            .map((k) => {
              return { label: k, value: k };
            })
            .sortArray()
        );
        setTransmissionOptions(
          d.transmissionTypes
            .map((k) => {
              return { label: k, value: k };
            })
            .sortArray()
        );
      }
    );
  }, [brands]);

  // TIER 2: MODEL, COLOR, FUEL TYPE, TRANSMISSION, FEATURES CHANGE --> RESET TIER 3
  useEffect(() => {
    const genFilter = {
      isNew: props.newCars ? true : false,
      class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      brand: brands,
      model: models.length >= 1 ? models : null,
      baseColor: baseColors,
      fuelType: fuelTypes,
      transmissionType: transmissions,
      tags: features.map(
        (k) => featureSuggestions.find((obj, id) => obj.translation === k).key
      ),
    };
    const filteredFilter = Object.entries(genFilter).reduce(
      (acc, [key, value]) => {
        if (!Array.isArray(value) || value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    getCarsFilteredOptions(filteredFilter, (data) => {
      const d = data;
      setD(data);
      // setCount(data.count);
      // setCountLoaded(true);
      if (
        !compareArrays(models, unfiltered?.models) ||
        !compareArrays(baseColors, unfiltered?.baseColors) ||
        !compareArrays(fuelTypes, unfiltered?.fuelTypes) ||
        !compareArrays(transmissions, unfiltered?.transmissions) ||
        !compareArrays(features, unfiltered?.features)
      ) {
        // RESET SELECTED DATA
        // tier 3
        setPriceMin(d.priceMin || 0);
        setPriceMax(d.priceMax || 0);
        setYearMax(new Date(d.firstRegistrationMax).getFullYear() || 0);
        setYearMin(new Date(d.firstRegistrationMin).getFullYear() || 0);
        setMileageMin(d.kmMin || 0);
        setMileageMax(d.kmMax || 0);
      }
    });
  }, [brands, models, baseColors, fuelTypes, transmissions, features]);

  // TIER 3: PRICE, KM ODER FIRST REG CHANGE  --> update Counter
  useEffect(() => {
    const genFilter = {
      isNew: props.newCars ? true : false,
      class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      brand: brands,
      model: models.length >= 1 ? models : null,
      baseColor: baseColors,
      fuelType: fuelTypes,
      transmissionType: transmissions,
      tags: features.map(
        (k) => featureSuggestions.find((obj, id) => obj.translation === k).key
      ),
      priceFrom: Number(priceMin),
      priceTo: Number(priceMax),
      firstRegFrom: props.newCars ? null : yearMin + '-01',
      firstRegTo: props.newCars ? null : yearMax + '-12',
      kmFrom: props.newCars ? null : Number(mileageMin),
      kmTo: props.newCars ? null : Number(mileageMax),
      class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
    };
    const filteredFilter = Object.entries(genFilter).reduce(
      (acc, [key, value]) => {
        if (!Array.isArray(value) || value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    getCarsFilteredOptions(filteredFilter, (data) => {
      const d = data;
      setD(d);
      setCount(d.count);
      setCountLoaded(true);
    });
  }, [
    brands,
    models,
    baseColors,
    fuelTypes,
    transmissions,
    features,
    priceMin,
    priceMax,
    mileageMin,
    mileageMax,
    yearMax,
    yearMin,
    features,
  ]);

  useEffect(() => {
    if (!history.location.state?.filter && countLoaded) {
      setBrands([brandObject.carzillaBrand]);
      setIncludeOtherBrands(false);
      setModels([]);
      setBaseColors([]);
      setFuelTypes([]);
      setFeatures([]);
      setTransmissions([]);
      if (props.newCars) localStorage.removeItem('newCarFilter');
      else if (props.mobileHome) localStorage.removeItem('motorhomeFilter');
      else localStorage.removeItem('filter');
      if (!props.short) {
        props.setFilter({
          brand: [brandObject.carzillaBrand],
          isNew: props.newCars ? true : false,
        });
      }
    }
  }, [brandObject]);

  function clearHandler() {
    setBrands([brandObject.carzillaBrand]);
    setModels([]);
    setBaseColors([]);
    setFuelTypes([]);
    setFeatures([]);
    setTransmissions([]);
    setIncludeOtherBrands(false);

    localStorage.removeItem('detailId');
    if (props.newCars) localStorage.removeItem('newCarFilter');
    else if (props.mobileHome) localStorage.removeItem('motorhomeFilter');
    else localStorage.removeItem('filter');

    let url;
    if (props.newCars) url = globals.PAGES.NEUWAGEN.url + '/bestand/p=1';
    else if (props.mobileHome) url = globals.PAGES.WOHNMOBILE + '/p=1';
    else url = globals.PAGES.GEBRAUCHTWAGEN.url + '/p=1';

    if (!props.short) {
      history.push(`/${brandObject.url}/${url}/`, {
        filter: {
          brand: [brandObject.carzillaBrand],
          // class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
          // isNew: props.newCars ? true : false,
        },
        unfilteredFilter: {
          brand: [brandObject.carzillaBrand],
          // class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
          // isNew: props.newCars ? true : false,
        },
      });
      props.setFilter({
        brand: [brandObject.carzillaBrand],
        isNew: props.newCars ? true : false,
        class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'],
      });
    }
  }

  function toggleBrand(brand) {
    const index = brands.indexOf(brand);
    if (index > -1) {
      const updatedBrands = [...brands];
      updatedBrands.splice(index, 1);
      setBrands(updatedBrands);
    } else {
      setBrands((prevBrands) => [...prevBrands, brand]);
    }
  }

  useEffect(() => {
    if (includeOtherBrands) {
      getCarsFilteredOptions(
        { class: props.mobileHome ? ['Motorhome'] : ['Car', 'VanUpTo7500'] },
        (d) => {
          setBrands((b) => [
            ...b,
            ...d.brands.filter(
              (v) =>
                ![
                  globals.BRANDS.VW.carzillaBrand,
                  globals.BRANDS.AUDI.carzillaBrand,
                  globals.BRANDS.SKODA.carzillaBrand,
                ].includes(v) && v
            ),
          ]);
        }
      );
    } else {
      setBrands((b) =>
        b.filter((bb) =>
          [
            globals.BRANDS.VW.carzillaBrand,
            globals.BRANDS.AUDI.carzillaBrand,
            globals.BRANDS.SKODA.carzillaBrand,
          ].includes(bb)
            ? bb
            : null
        )
      );
    }
  }, [includeOtherBrands]);

  return (
    <Grid
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
      container
    >
      <Grid
        xs={12}
        sx={{
          maxWidth: props.short ? '700px' : '1200px',
          // display: 'flex',
          // justifyContent: 'center',
          // alignItems: 'flex-end',
        }}
        container
      >
        <Grid
          xs={12}
          sm={props.short ? 12 : 6}
          className={classes.multiSelect}
          // spacing={2}
          id="suche"
          container
        >
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <motion.div
              style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDiretion: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: '100%',
                textAlign: 'center',
                maxWidth: '400px',
              }}
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    delayChildren: 0.5,
                    staggerChildren: 0.1,
                  },
                },
              }}
              animate="visible"
              initial="hidden"
              className={classes.images}
            >
              {!countLoaded ? (
                <Skeleton width="100%" height="100px" />
              ) : (
                Object.values(globals.BRANDS).map((item, i) => {
                  if (
                    !item.hideInBrandswitch &&
                    (!props.mobileHome ||
                      (props.mobileHome && !item.hideInMotorhome)) &&
                    (!props.newCars || (props.newCars && !item.onlyService))
                  ) {
                    return (
                      <motion.div
                        style={{ width: '100%' }}
                        whileTap={{ scale: 0.94 }}
                        variants={{
                          hidden: { opacity: 0 },
                          visible: { opacity: 1 },
                        }}
                        onClick={() => toggleBrand(item.carzillaBrand)}
                        key={i}
                      >
                        <img
                          className={`${classes.brandImage} ${
                            item.logoStyle
                          } noSelect ${
                            brands.indexOf(item.carzillaBrand) < 0 &&
                            classes.grayedOut
                          }`}
                          src={item.sale_logo}
                          alt={item.name + ' Logo'}
                          style={{
                            borderColor:
                              brands.indexOf(item.carzillaBrand) >= 0 &&
                              item.primeColor,
                          }}
                        />
                      </motion.div>
                    );
                  }
                })
              )}
              {!props.newCars && showOtherBrands && (
                <motion.div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  className="noSelect"
                  whileTap={{ scale: 0.94 }}
                  variants={{
                    hidden: { opacity: 0 },
                    visible: { opacity: 1 },
                  }}
                  onClick={() => setIncludeOtherBrands((v) => !v)}
                >
                  <span
                    style={{
                      marginBottom: '10px',
                      marginLeft: '20px',
                      fontSize: '15px',
                      borderBottom: '2px solid',
                      borderColor: includeOtherBrands
                        ? 'var(--text-color)'
                        : 'transparent',
                    }}
                  >
                    Andere Marken
                  </span>
                </motion.div>
              )}
            </motion.div>
          </Grid>
          <Grid xs={12} container>
            <Grid xs={12} sm={6}>
              <span
                className="p-float-label"
                style={{ margin: '15px 0', padding: '0 10px' }}
              >
                <MultiSelect
                  value={models}
                  onChange={(e) => {
                    setModels(e.value);
                  }}
                  filterInputAutoFocus={false}
                  options={modelOptions}
                  filter
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label style={{ padding: '0 10px' }}>Modelle</label>
              </span>
            </Grid>
            <Grid xs={12} sm={6}>
              <span
                className="p-float-label"
                style={{ margin: '15px 0', padding: '0 10px' }}
              >
                <MultiSelect
                  value={baseColors}
                  onChange={(e) => {
                    setBaseColors(e.value);
                  }}
                  filterInputAutoFocus={false}
                  options={colorOptions}
                  filter
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label style={{ padding: '0 10px' }}>Farben</label>
              </span>
            </Grid>
            <Grid xs={12} sm={6}>
              <span
                className="p-float-label"
                style={{ margin: '15px 0', padding: '0 10px' }}
              >
                <MultiSelect
                  value={fuelTypes}
                  onChange={(e) => {
                    setFuelTypes(e.value);
                  }}
                  filterInputAutoFocus={false}
                  options={fuelTypeOptions}
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label style={{ padding: '0 10px' }}>Kraftstoffart</label>
              </span>
            </Grid>
            <Grid xs={12} sm={6}>
              <span
                className="p-float-label"
                style={{ margin: '15px 0', padding: '0 10px' }}
              >
                <MultiSelect
                  value={transmissions}
                  onChange={(e) => {
                    setTransmissions(e.value);
                  }}
                  filterInputAutoFocus={false}
                  options={transmissionOptions}
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                  style={{
                    width: '100%',
                  }}
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label style={{ padding: '0 10px' }}>Getriebe</label>
              </span>
            </Grid>
            <Grid xs={12}>
              <span
                className="p-float-label"
                style={{ margin: '15px 0', padding: '0 10px' }}
              >
                <MultiSelect
                  value={features}
                  onChange={(e) => {
                    setFeatures(e.value);
                  }}
                  filterInputAutoFocus={false}
                  options={featureSuggestions.map((s) => s.translation).sort()}
                  maxSelectedLabels={3}
                  className="w-full md:w-20rem"
                  style={{
                    width: '100%',
                  }}
                  filter
                  display="chip"
                  emptyFilterMessage="Keine Option gefunden."
                />
                <label style={{ padding: '0 10px' }}>
                  Kriterien hinzufügen
                </label>
              </span>
            </Grid>
          </Grid>
        </Grid>
        {props.short ? (
          <Collapsible
            label={
              <span
                style={{
                  color: 'var(--text-color)',
                  fontSize: '12px',
                  fontStyle: 'italic',
                }}
              >
                mehr...
              </span>
            }
          >
            <Grid
              xs={12}
              container
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px 0',
              }}
            >
              <Grid xs={12} container>
                <Grid
                  xs={6}
                  className={classes.numberSelectWrapper}
                  sx={{ padding: '0 10px' }}
                >
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowedDecimalSeparators={[',']}
                    label="Preis (min)"
                    name="priceMin"
                    value={priceMin}
                    className={classes.numberSelect}
                    size="small"
                    onValueChange={(v, e) => {
                      handlePriceChange(e.event, v.floatValue);
                    }}
                    decimalScale={2}
                    suffix=" €"
                    inputProps={{
                      min: d?.priceMin || 0,
                      max: d?.priceMax || 0,
                      lang: 'de-DE',
                    }}
                  />
                </Grid>
                <Grid
                  xs={6}
                  className={classes.numberSelectWrapper}
                  sx={{ padding: '0 10px' }}
                >
                  <NumericFormat
                    customInput={TextField}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowedDecimalSeparators={[',']}
                    label="Preis (max)"
                    name="priceMax"
                    value={priceMax}
                    className={classes.numberSelect}
                    size="small"
                    onValueChange={(v, e) => {
                      handlePriceChange(e.event, v.floatValue);
                    }}
                    decimalScale={2}
                    suffix=" €"
                    inputProps={{
                      min: d?.priceMin || 0,
                      max: d?.priceMax || 0,
                      lang: 'de-DE',
                    }}
                  />
                </Grid>
                {!props.newCars && (
                  <>
                    <Grid
                      xs={6}
                      className={classes.numberSelectWrapper}
                      sx={{ padding: '0 10px' }}
                    >
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator="."
                        decimalSeparator=","
                        allowedDecimalSeparators={[',']}
                        label="Kilometerzahl (min)"
                        name="mileageMin"
                        value={mileageMin}
                        className={classes.numberSelect}
                        size="small"
                        onValueChange={(v, e) => {
                          handleMileageChange(e.event, v.floatValue);
                        }}
                        decimalScale={2}
                        suffix=" km"
                        inputProps={{
                          min: d?.mileageMin || 0,
                          max: d?.mileageMax || 0,
                          lang: 'de-DE',
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      className={classes.numberSelectWrapper}
                      sx={{ padding: '0 10px' }}
                    >
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator="."
                        decimalSeparator=","
                        allowedDecimalSeparators={[',']}
                        label="Kilometerzahl (max)"
                        name="mileageMin"
                        value={mileageMax}
                        className={classes.numberSelect}
                        size="small"
                        onValueChange={(v, e) => {
                          handleMileageChange(e.event, v.floatValue);
                        }}
                        decimalScale={2}
                        suffix=" km"
                        inputProps={{
                          min: d?.mileageMin || 0,
                          max: d?.mileageMax || 0,
                          lang: 'de-DE',
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      className={classes.numberSelectWrapper}
                      sx={{ padding: '0 10px' }}
                    >
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator=""
                        allowedDecimalSeparators={['']}
                        label="Erstzulassung (min)"
                        name="yearMin"
                        value={yearMin}
                        className={classes.numberSelect}
                        size="small"
                        onValueChange={(v, e) => {
                          handleYearChange(e.event, v.floatValue);
                        }}
                        decimalScale={0}
                        inputProps={{
                          min:
                            new Date(d?.firstRegistrationMin).getFullYear() ||
                            new Date().getFullYear(),
                          max:
                            new Date(d?.firstRegistrationMax).getFullYear() ||
                            new Date().getFullYear(),
                          lang: 'de-DE',
                        }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      className={classes.numberSelectWrapper}
                      sx={{ padding: '0 10px' }}
                    >
                      <NumericFormat
                        customInput={TextField}
                        thousandSeparator=""
                        allowedDecimalSeparators={['']}
                        label="Erstzulassung (max)"
                        name="yearMax"
                        value={yearMax}
                        className={classes.numberSelect}
                        size="small"
                        onValueChange={(v, e) => {
                          handleYearChange(e.event, v.floatValue);
                        }}
                        decimalScale={0}
                        inputProps={{
                          min:
                            new Date(d?.firstRegistrationMin).getFullYear() ||
                            new Date().getFullYear(),
                          max:
                            new Date(d?.firstRegistrationMax).getFullYear() ||
                            new Date().getFullYear(),
                          lang: 'de-DE',
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Collapsible>
        ) : (
          <Grid
            xs={12}
            sm={6}
            container
            className={`${classes.multiSelect} ${classes.priceSelect}`}
          >
            <Grid xs={12} container>
              <Grid
                xs={6}
                className={classes.numberSelectWrapper}
                sx={{ padding: '0 10px' }}
              >
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator="."
                  decimalSeparator=","
                  allowedDecimalSeparators={[',']}
                  label="Preis (min)"
                  name="priceMin"
                  value={priceMin}
                  className={classes.numberSelect}
                  size="small"
                  onValueChange={(v, e) => {
                    handlePriceChange(e.event, v.floatValue);
                  }}
                  decimalScale={2}
                  suffix=" €"
                  inputProps={{
                    min: d?.priceMin || 0,
                    max: d?.priceMax || 0,
                    lang: 'de-DE',
                  }}
                />
              </Grid>
              <Grid
                xs={6}
                className={classes.numberSelectWrapper}
                sx={{ padding: '0 10px' }}
              >
                <NumericFormat
                  customInput={TextField}
                  thousandSeparator="."
                  decimalSeparator=","
                  allowedDecimalSeparators={[',']}
                  label="Preis (max)"
                  name="priceMax"
                  value={priceMax}
                  className={classes.numberSelect}
                  size="small"
                  onValueChange={(v, e) => {
                    handlePriceChange(e.event, v.floatValue);
                  }}
                  decimalScale={2}
                  suffix=" €"
                  inputProps={{
                    min: d?.priceMin || 0,
                    max: d?.priceMax || 0,
                    lang: 'de-DE',
                  }}
                />
              </Grid>
              {!props.newCars && (
                <>
                  <Grid
                    xs={6}
                    className={classes.numberSelectWrapper}
                    sx={{ padding: '0 10px' }}
                  >
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowedDecimalSeparators={[',']}
                      label="Kilometerzahl (min)"
                      name="mileageMin"
                      value={mileageMin}
                      className={classes.numberSelect}
                      size="small"
                      onValueChange={(v, e) => {
                        handleMileageChange(e.event, v.floatValue);
                      }}
                      decimalScale={2}
                      suffix=" km"
                      inputProps={{
                        min: d?.mileageMin || 0,
                        max: d?.mileageMax || 0,
                        lang: 'de-DE',
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    className={classes.numberSelectWrapper}
                    sx={{ padding: '0 10px' }}
                  >
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowedDecimalSeparators={[',']}
                      label="Kilometerzahl (max)"
                      name="mileageMin"
                      value={mileageMax}
                      className={classes.numberSelect}
                      size="small"
                      onValueChange={(v, e) => {
                        handleMileageChange(e.event, v.floatValue);
                      }}
                      decimalScale={2}
                      suffix=" km"
                      inputProps={{
                        min: d?.mileageMin || 0,
                        max: d?.mileageMax || 0,
                        lang: 'de-DE',
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    className={classes.numberSelectWrapper}
                    sx={{ padding: '0 10px' }}
                  >
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator=""
                      allowedDecimalSeparators={['']}
                      label="Erstzulassung (min)"
                      name="yearMin"
                      value={yearMin}
                      className={classes.numberSelect}
                      size="small"
                      onValueChange={(v, e) => {
                        handleYearChange(e.event, v.floatValue);
                      }}
                      decimalScale={0}
                      inputProps={{
                        min:
                          new Date(d?.firstRegistrationMin).getFullYear() ||
                          new Date().getFullYear(),
                        max:
                          new Date(d?.firstRegistrationMax).getFullYear() ||
                          new Date().getFullYear(),
                        lang: 'de-DE',
                      }}
                    />
                  </Grid>
                  <Grid
                    xs={6}
                    className={classes.numberSelectWrapper}
                    sx={{ padding: '0 10px', marginBottom: '7.5px' }}
                  >
                    <NumericFormat
                      customInput={TextField}
                      thousandSeparator=""
                      allowedDecimalSeparators={['']}
                      label="Erstzulassung (max)"
                      name="yearMax"
                      value={yearMax}
                      className={classes.numberSelect}
                      size="small"
                      onValueChange={(v, e) => {
                        handleYearChange(e.event, v.floatValue);
                      }}
                      decimalScale={0}
                      inputProps={{
                        min:
                          new Date(d?.firstRegistrationMin).getFullYear() ||
                          new Date().getFullYear(),
                        max:
                          new Date(d?.firstRegistrationMax).getFullYear() ||
                          new Date().getFullYear(),
                        lang: 'de-DE',
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          xs={12}
          sx={{ display: 'flex', alignItem: 'center', marginTop: '10px' }}
          container
        >
          <Grid xs={12} sm={6}>
            <Button
              icon={faX}
              color="red"
              maxWidth="200px"
              onClick={clearHandler}
              iconPos="pre"
            >
              Auswahl löschen
            </Button>
          </Grid>
          <Grid xs={12} sm={6}>
            <Button
              icon={faArrowRight}
              maxWidth="200px"
              onClick={submitHandler}
              iconPos="post"
            >
              Suche {countLoaded && '(' + count + ')'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ShortFilter;

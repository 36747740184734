import classes from './Product.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import Animation from '../UI/Animation';

// IMPORT MUI AND OTHER COMPONENTS
import { ButtonGroup, Paper, Stack, TextField, Skeleton } from '@mui/material/';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { pdfjs } from 'react-pdf';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import Button from '../UI/Button';
import { faCancel, faPencil } from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../store/auth-context';
import ModalContext from '../../store/modal-context';
import usePostContent from '../../hooks/usePostContent';
import PopupContext from '../../store/popup-context';
import useFetchContent from '../../hooks/useFetchContent';
import DragAndDropFiles from '../UI/DragAndDropFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePatchContent from '../../hooks/usePatchContent';
import useDeleteContent from '../../hooks/useDeleteContent';

const ColorButton = styled(MuiButton)(({ theme }) => ({
  color: '#474747',
  borderColor: '#474747',
  '&:hover': {
    borderColor: 'black',
    backgroundColor: 'lightgray',
  },
  '&.videoChecked': {
    color: 'white',
    backgroundColor: '#474747',
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '8px',
  color: theme.palette.text.secondary,
}));

function ProductForm({ patchProducts, product = {} }) {
  const { error, postProduct, postProductThumbnail, postProductPdf } =
    usePostContent();
  const { error: updateError, patchProduct } = usePatchContent();
  const { setPopupMessage } = useContext(PopupContext);
  const { closeModal } = useContext(ModalContext);
  const [formData, setFormData] = useState({
    title: product.title || '',
    rentPrice: product.rentPrice || '',
    buyPrice: product.buyPrice || '',
    description: product.description || '',
    isRentable: true,
    isBuyable: true,
  });
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [pdf, setPDF] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const fieldValue = type === 'checkbox' ? checked : files ? files[0] : value;
    setFormData((prevData) => ({ ...prevData, [name]: fieldValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!product.title) {
      postProduct(formData, (d) => {
        if (!error) {
          if (thumbnailImage) {
            const formData = new FormData();
            formData.append('upload', thumbnailImage);
            postProductThumbnail(formData, d._id, (i) => {
              if (pdf) {
                const formDataPdf = new FormData();
                formDataPdf.append('upload', pdf);
                postProductPdf(formDataPdf, d._id, (p) => {
                  patchProducts(
                    {
                      ...d,
                      pdfPath: p.url,
                      thumbnailImage: { src: i.url },
                    },
                    'add'
                  );
                });
              } else {
                patchProducts({ ...d, thumbnailImage: { src: i.url } }, 'add');
                setPopupMessage(
                  'Produkt: "' + d.title + '" hinzugefügt.',
                  'success'
                );
              }
            });
          } else if (pdf) {
            const formData = new FormData();
            formData.append('upload', pdf);
            postProductPdf(formData, d._id, (p) => {
              patchProducts({ ...d, pdf: p.url }, 'edit');
            });
          } else {
            patchProducts(d, 'add');
            setPopupMessage(
              'Produkt: "' + d.title + '" hinzugefügt.',
              'success'
            );
          }
        }
        closeModal();
      });
    } else {
      patchProduct(product._id, formData, (d) => {
        if (!updateError) {
          // if (thumbnailImage) {
          //   const formDataFile = new FormData();
          //   formDataFile.append('upload', thumbnailImage);
          //   postProductThumbnail(formDataFile, d._id, (i) => {
          //     patchProducts({ ...d, thumbnailImage: { src: i.url } }, 'edit');
          //     setPopupMessage('Produkt erfolgreich bearbeitet!', 'success');
          //   });
          // } else {
          //   patchProducts(d, 'edit');
          // }
          if (thumbnailImage) {
            const formData = new FormData();
            formData.append('upload', thumbnailImage);
            postProductThumbnail(formData, d._id, (i) => {
              if (pdf) {
                const formDataPdf = new FormData();
                formDataPdf.append('upload', pdf);
                postProductPdf(formDataPdf, d._id, (p) => {
                  patchProducts(
                    {
                      ...d,
                      pdfPath: p.url,
                      thumbnailImage: { src: i.url },
                    },
                    'edit'
                  );
                });
              } else {
                patchProducts({ ...d, thumbnailImage: { src: i.url } }, 'add');
                setPopupMessage(
                  'Produkt: "' + d.title + '" hinzugefügt.',
                  'success'
                );
              }
            });
          } else if (pdf) {
            const formData = new FormData();
            formData.append('upload', pdf);
            postProductPdf(formData, d._id, (p) => {
              patchProducts({ ...d, pdfPath: p.url }, 'edit');
            });
          } else {
            patchProducts(d, 'edit');
            setPopupMessage(
              'Produkt: "' + d.title + '" hinzugefügt.',
              'success'
            );
          }
        }
        closeModal();
      });
    }
  };

  // const [numPages, setNumPages] = useState(null);

  // const onDocumentLoadSuccess = ({ numPages }) => {
  //   setNumPages(numPages);
  // };

  // const renderPages = () => {
  //   const pages = [];

  //   for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
  //     pages.push(
  //       <div key={pageNumber} className="pdf-page">
  //         <Page pageNumber={pageNumber} />
  //       </div>
  //     );
  //   }

  //   return pages;
  // };

  return (
    <form onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
      <Grid
        container
        spacing={2}
        sx={{
          overflow: 'hidden',
          padding: '20px 0',
        }}
      >
        <Grid xs={12} sx={{ marginTop: '10px' }}>
          <TextField
            label="Titel"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            label="Mietpreis"
            name="rentPrice"
            value={formData.rentPrice}
            onChange={handleChange}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            label="Kaufpreis"
            name="buyPrice"
            value={formData.buyPrice}
            onChange={handleChange}
            type="text"
            fullWidth
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label="Beschreibung"
            name="description"
            value={formData.description}
            onChange={handleChange}
            multiline
            fullWidth
          />
        </Grid>
        <Grid xs={12} md={6}>
          <DragAndDropFiles
            types={['JPG', 'JPEG', 'PNG']}
            handleChange={setThumbnailImage}
            label={
              !thumbnailImage
                ? 'Kleines Bild hinzufügen'
                : 'Kleines Bild ausgewählt'
            }
          />
        </Grid>
        <Grid xs={12} md={6}>
          <DragAndDropFiles
            types={['PDF']}
            handleChange={setPDF}
            label={!pdf ? 'PDF hinzufügen (max. 1mb)' : 'PDF ausgewählt'}
            maxSize={1}
            onSizeError={() => {
              setPDF(null);
              setPopupMessage('Datei zu groß. Max 1 Megabyte.', 'error');
            }}
          />
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" color="green" maxWidth="300px">
            {product.title ? 'Produkt aktualisieren' : 'Produkt hinzufügen'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

function Products() {
  const [items, setItems] = useState();
  const [loaded, setLoaded] = useState(false);
  const { error, getAllProducts } = useFetchContent();
  const { error: deleteError, deleteProduct } = useDeleteContent();
  const { showModal, closeModal } = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const [item, setItem] = useState({
    title: '',
    description: '',
    thumbnailImage: {},
    buyPrice: '',
    pdfPath: '',
    rentPrice: '',
  });

  useEffect(() => {
    getAllProducts((d) => {
      if (!error) {
        setItems(d);
        if (d.length > 0) setItem(d[0]);
        setLoaded(true);
      }
    });
  }, []);

  function patchProducts(data, action) {
    if (action === 'add') {
      setItems((c) => [...c, data]);
      setItem(data);
    } else if (action === 'delete') {
      setItems((items) => items.filter((item) => item._id !== data._id));
      setItem(items.length > 0 && items[0]);
      closeModal();
    } else if (action === 'edit') {
      let found = false;
      setItem(data);
      setItems((arr) => {
        const updatedItems = arr.map((item) => {
          if (item._id === data._id) {
            found = true;
            return data;
          } else {
            return item;
          }
        });
        if (!found) {
          return [data, ...arr];
        }
        return updatedItems;
      });
    }
  }

  function deleteProductHandler(item) {
    showModal(
      item.title + ' löschen',
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          maxWidth="200px"
          color="red"
          onClick={() => {
            deleteProduct(item._id, (d) => {
              if (!deleteError) {
                setPopupMessage('Produkt erfolgreich entfernt.', 'success');
                patchProducts(d, 'delete');
              }
            });
          }}
        >
          WIRKLICH LÖSCHEN
        </Button>
      </div>
    );
  }

  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const history = useHistory();

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  if (error || !loaded) {
    return (
      <Grid container xs={12}>
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          {Array.from({ length: 5 }).map((r, i) => {
            return (
              <Fragment key={i}>
                <Skeleton width="300px" height="30px" key={i} />
                <br />
              </Fragment>
            );
          })}
        </Grid>
        <Grid xs={12} sm={12} md={8} lg={8} xl={8}>
          <Item>
            <Grid xs={12} container spacing={0}>
              <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                <Skeleton width="200px" height="60px" />
                <Skeleton width="300px" height="200px" />
              </Grid>
              <Grid xs={12} sm={6} md={6} lg={6} xl={6}>
                <Skeleton width="300px" height="100px" />
                <Skeleton width="320px" height="250px" />
              </Grid>
            </Grid>
          </Item>
        </Grid>
      </Grid>
    );
  } else
    return (
      <Grid container xs={12}>
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Animation dir="right">
            <Stack>
              <ul>
                {items.map((r, i) => {
                  return (
                    <li
                      className={classes.rentListItem}
                      key={i}
                      onClick={() => {
                        setItem(r);
                      }}
                      style={{
                        color: item === r && brandObject.primeColor,
                      }}
                    >
                      {r.title}
                    </li>
                  );
                })}
                {authCtx.isAdmin && (
                  <Button
                    maxWidth="200px"
                    color="green"
                    onClick={() => {
                      showModal(
                        'Produkt hinzufügen',
                        null,
                        <ProductForm patchProducts={patchProducts} />
                      );
                    }}
                  >
                    Hinzufügen
                  </Button>
                )}
              </ul>
            </Stack>
          </Animation>
        </Grid>

        {items.length > 0 && (
          <Grid xs={12} sm={12} md={8} lg={8} xl={8} container>
            <Item sx={{ overflow: 'hidden' }}>
              <Animation dir="left">
                <Grid xs={12} container sx={{ position: 'relative' }}>
                  <Grid xs={12}>
                    <h1 className="subheading" style={{ marginLeft: '20px' }}>
                      {item.title}
                    </h1>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {item.thumbnailImage && (
                      <Animation>
                        <img
                          src={`https://autohaus-seyfarth.de${item.thumbnailImage.src}`}
                          alt={'Foto ' + item.title}
                          className={classes.rentAndUpgradePanelImage}
                          loading="lazy"
                        />
                      </Animation>
                    )}
                  </Grid>
                  <Grid xs={12} sm={6} container>
                    <Grid xs={12}>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                      >
                        {item.rentPrice && (
                          <ColorButton
                            onClick={() =>
                              history.push(
                                `/${brandObject.url}/anfrage/zubehoer`,
                                {
                                  product: {
                                    title: item.title,
                                    service: 'mieten',
                                  },
                                }
                              )
                            }
                          >
                            mieten
                          </ColorButton>
                        )}
                        {item.buyPrice !== '' && (
                          <ColorButton
                            onClick={() =>
                              history.push(
                                `/${brandObject.url}/anfrage/zubehoer`,
                                {
                                  product: {
                                    title: item.title,
                                    service: 'kaufen',
                                  },
                                }
                              )
                            }
                          >
                            kaufen
                          </ColorButton>
                        )}
                        {item.pdfPath && (
                          <ColorButton
                            className={classes.btnSpace}
                            onClick={() => {
                              const pdfUrl = `https://autohaus-seyfarth.de${item.pdfPath}`;
                              const newTab = window.open(pdfUrl, '_blank');
                              newTab.focus();
                            }}
                          >
                            mehr Infos
                          </ColorButton>
                        )}
                      </ButtonGroup>
                    </Grid>
                    <Grid xs={12}>
                      <div
                        className="standardText"
                        style={{ marginTop: '20px', marginBottom: '20px' }}
                      >
                        {item.description}
                      </div>
                    </Grid>
                    <Grid
                      xs={12}
                      sx={{
                        marginBottom: 0,
                        marginTop: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      container
                    >
                      {item.rentPrice !== '' && (
                        <Grid xs={6}>
                          <span>ab {item.rentPrice}</span>
                          <br />
                          <span>Jetzt mieten!</span>
                        </Grid>
                      )}
                      {item.buyPrice !== '' && (
                        <Grid xs={6}>
                          <span>ab {item.buyPrice}</span>
                          <br />
                          <span>Jetzt kaufen!</span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {authCtx.isAdmin && (
                    <div className={classes.editListIcons}>
                      <FontAwesomeIcon
                        icon={faPencil}
                        className={classes.editListEditIcon}
                        onClick={() => {
                          showModal(
                            item.title + ' bearbeiten',
                            null,
                            <ProductForm
                              patchProducts={patchProducts}
                              product={item}
                            />
                          );
                          setPopupMessage(
                            'Zur Bearbeitung ausgewählt!',
                            'info'
                          );
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faCancel}
                        className={classes.editListCancelIcon}
                        onClick={() => deleteProductHandler(item)}
                      />
                    </div>
                  )}
                </Grid>
              </Animation>
            </Item>
          </Grid>
        )}
      </Grid>
    );
}

export default Products;

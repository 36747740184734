import classes from './Modal.module.css';
import {
  useContext,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ReactDOM from 'react-dom';

// IMPORT ICONS
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

// IMPORT OWN COMPONENTS
import Backdrop from './Backdrop';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import ModalContext from '../../store/modal-context';

const ModalOverlay = forwardRef((props, ref) => {
  const header = props.content.header;
  const heading = props.content.heading;
  const subheading = props.content.subheading;
  const body = props.content.body;
  const show = props.show;
  const headerRef = useRef();
  const bodyRef = useRef();
  const wrapperRef = useRef();
  const innerBodyRef = useRef();
  // const modalRef = useRef();
  const [fullHeight, setFullHeight] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(60);
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  function recheckHeight() {
    if (props.noScroll) {
      setFullHeight(true);
    } else {
      if (bodyRef.current.scrollHeight > bodyRef.current.clientHeight) {
        setFullHeight(true);
      } else if (
        bodyRef.current.scrollHeightHeight < bodyRef.current.clientHeight
      ) {
        setFullHeight(false);
      } else if (
        bodyRef.current.scrollHeight - innerBodyRef.current.scrollHeight >
        headerRef.current.clientHeight + 60
      ) {
        setFullHeight(false);
      }
    }
  }

  const updateHeight = () => {
    recheckHeight();
  };

  useImperativeHandle(ref, () => ({
    updateHeight,
  }));

  useEffect(() => {
    if (show) recheckHeight();
    else setFullHeight(false);
  }, [show, scrollHeight, clientHeight, bodyRef.current]);

  useEffect(() => {
    const currentRef = bodyRef.current;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setScrollHeight(entry.target.scrollHeight);
      });
    });

    observer.observe(currentRef);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const currentRef = bodyRef.current;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setClientHeight(entry.target.clientHeight);
      });
    });

    observer.observe(currentRef);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const currentRef = innerBodyRef.current;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        recheckHeight();
      });
    });

    observer.observe(currentRef);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={`${classes.modalWrapper} ${show && classes.active}`}
      ref={wrapperRef}
    >
      <div
        className={`${classes.modal} ${show && classes.active} ${
          fullHeight && !props.noScroll && classes.scroll
        }`}
        ref={bodyRef}
        style={{ height: props.noScroll && '80vh' }}
      >
        <CloseRoundedIcon
          className={`${classes.closeIcon} noSelect`}
          onClick={props.onHide}
          sx={{ height: '30px', width: '30px' }}
        />
        <div
          className={classes.inner}
          style={{ height: props.noScroll && '80vh' }}
        >
          <div className={classes.header} ref={headerRef}>
            <div>
              <span>{header}</span>
              {heading && (
                <span
                  className={classes.heading}
                  style={{ color: brandObject.primeColor }}
                >
                  {heading}
                </span>
              )}
              {subheading && (
                <>
                  <br />
                  <span className={classes.subheading}>{subheading}</span>
                </>
              )}
            </div>
          </div>
          <div
            className={classes.body}
            onLoad={() => {
              setHeaderHeight(headerRef.current.scrollHeight);
            }}
            style={{
              height: 'calc(100% - 20px - ' + headerHeight + 'px)',
            }}
          >
            <div
              ref={innerBodyRef}
              style={{
                height:
                  props.noScroll && 'calc(100%  - ' + headerHeight + 'px)',
              }}
            >
              {body}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export const CModal = forwardRef((props, ref) => {
  const { heading, subheading, body, noScroll, closeModal } =
    useContext(ModalContext);
  const content = { heading, subheading, body };
  const modalRef = useRef();

  const updateHeight = () => {
    modalRef.current.updateHeight();
  };

  useImperativeHandle(ref, () => ({
    updateHeight,
  }));

  // useEffect(() => {
  //   if (!!content.heading) {
  //     document.body.style.overflow = 'hidden';
  //   } else document.body.style.overflow = 'auto';
  // }, [content.heading]);

  useEffect(() => {
    if (!!content.heading) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [content.heading]);

  // useEffect(() => {
  //   if (!!content.heading) disableBodyScroll(modalRef);
  //   else enableBodyScroll(modalRef);
  // }, [content.heading]);

  if (content == null) return null;
  return (
    <div style={{ height: '100%' }}>
      <Backdrop show={!!content.heading} onClick={closeModal} />
      {ReactDOM.createPortal(
        <ModalOverlay
          content={content}
          show={!!content.heading}
          onHide={closeModal}
          ref={modalRef}
          noScroll={noScroll}
        />,
        document.getElementById('overlay-root')
      )}
    </div>
  );
});

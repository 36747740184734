import { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import Button from '../UI/Button';
import PageHeading from '../UI/PageHeading';
import { EmployeeList, StaffForm } from '../About/StaffWidgets';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import SwipeableViews from 'react-swipeable-views';
import { Tab } from '@mui/material/';

// IMPORT DATA
import { globals } from '../../data/global-vars';

// IMPORT HOOKS AND CONTEXTS
import AuthContext from '../../store/auth-context';
import { useBrandFind } from '../../hooks/useFinders';
import useFetchContent from '../../hooks/useFetchContent';
import { OpeningHoursForm } from '../About/OpeningHours';
import { useTheme } from '@emotion/react';
import UserList, { UserForm } from '../About/Users';
import PopupContext from '../../store/popup-context';
import { StyledTabs, TabPanel } from '../UI/TabController';
import DepartmentsList, { DepartmentForm } from '../About/Departments';

function Admin() {
  const authCtx = useContext(AuthContext);
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { setPopupMessage } = useContext(PopupContext);
  const history = useHistory();
  const { error, getAllEmployees, getAllUsers, getAllDepartmentsOfBrand } =
    useFetchContent();
  const [employees, setEmployees] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const emptyEmployee = {
    firstName: '',
    lastName: '',
    description: '',
    birthDate: new Date(),
    edit: false,
    departments: [],
  };
  const emptyUser = {
    username: '',
    password: '',
    role: 'employee',
    edit: false,
  };
  const emptyDepartment = {
    title: '',
    key: '',
    brand: globals.BRANDS.VW.make,
  };
  const [selectedEmployee, setSelectedEmployee] = useState(emptyEmployee);
  const [selectedUser, setSelectedUser] = useState(emptyUser);
  const [selectedDepartment, setSelectedDepartment] = useState(emptyDepartment);

  useEffect(() => {
    getAllEmployees((d) => {
      if (!error) setEmployees(d);
    });
  }, []);

  useEffect(() => {
    getAllUsers((d) => {
      if (!error) setUsers(d);
    });
  }, []);

  useEffect(() => {
    setDepartments([]);
    Object.entries(globals.BRANDS).map(([key, value]) => {
      getAllDepartmentsOfBrand(value.make, (d) => {
        setDepartments((arr) => [
          ...arr,
          {
            brandKey: value.make,
            brandTitle: value.name,
            primeColor: value.primeColor,
            departments: d,
          },
        ]);
      });
    });
  }, []);

  const theme = useTheme();
  const [value, setValue] = useState(0);

  function getCorrespondingUrl(index) {
    switch (index) {
      case 0:
        return 'oeffnungszeiten';

      case 1:
        return 'mitarbeiter';

      case 2:
        return 'accounts';

      case 3:
        return 'abteilungen';

      default:
        return 'oeffnungszeiten';
    }
  }

  const tabKey = params[0].split('/');
  useEffect(() => {
    if (tabKey[0] === getCorrespondingUrl(1)) {
      if (value !== 1) {
        window.scrollTo(0, 0);
        setValue(1);
      }
    } else if (tabKey[0] === getCorrespondingUrl(2)) {
      if (value !== 2) {
        window.scrollTo(0, 0);
        setValue(2);
      }
    } else if (tabKey[0] === getCorrespondingUrl(3)) {
      if (value !== 3) {
        window.scrollTo(0, 0);
        setValue(3);
      }
    } else {
      if (value !== 0) window.scrollTo(0, 0);
      setValue(0);
    }
  }, [value, tabKey]);

  function handleTabChange(event, index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/admin/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/admin/` + url);
  }

  function patchEmployees(e, action) {
    if (action === 'add') {
      setSelectedEmployee(emptyEmployee);
      setEmployees((arr) => {
        const newItemDate = new Date(e.date);
        const index = arr.findIndex(
          (item) => new Date(item.date) > newItemDate
        );
        if (index === -1) {
          return [...arr, e];
        }
        return [...arr.slice(0, index), e, ...arr.slice(index)];
      });
    } else if (action === 'edit') {
      setSelectedEmployee(emptyEmployee);
      setEmployees((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        return index === -1
          ? arr
          : [...arr.slice(0, index), e, ...arr.slice(index + 1)];
      });
    } else if (action === 'delete') {
      setSelectedEmployee(emptyEmployee);
      setEmployees((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        return arr;
      });
    }
  }

  function patchUsers(e, action) {
    if (action === 'add') {
      setSelectedUser(emptyUser);
      setUsers((arr) => {
        const newItemDate = new Date(e.date);
        const index = arr.findIndex(
          (item) => new Date(item.date) > newItemDate
        );
        if (index === -1) {
          return [...arr, e];
        }
        return [...arr.slice(0, index), e, ...arr.slice(index)];
      });
      setPopupMessage('Account: "' + e.username + '" hinzugefügt.', 'success');
    } else if (action === 'edit') {
      setSelectedUser(emptyUser);
      setUsers((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        return index === -1
          ? arr
          : [...arr.slice(0, index), e, ...arr.slice(index + 1)];
      });
    } else if (action === 'delete') {
      setSelectedUser(emptyUser);
      setUsers((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        return arr;
      });
      setPopupMessage('Account gelöscht.', 'success');
    }
  }

  function patchDepartments(e, action) {
    if (action === 'add') {
      setSelectedDepartment(emptyDepartment);
      setDepartments((arr) => {
        const updatedBrands = arr.map((brand) => {
          if (brand.brandKey === e.brand) {
            return {
              ...brand,
              departments: [...brand.departments, e],
            };
          }
          return brand;
        });

        return updatedBrands;
      });
      setPopupMessage('Abteilung: "' + e.title + '" hinzugefügt.', 'success');
    } else if (action === 'edit') {
      setSelectedDepartment(emptyDepartment);
      setDepartments((arr) => {
        const updatedBrands = arr.map((brand) => {
          const index = brand.departments.findIndex(
            (item) => item._id === e._id
          );
          return {
            ...brand,
            departments:
              index === -1
                ? brand.departments
                : [
                    ...brand.departments.slice(0, index),
                    e,
                    ...brand.departments.slice(index + 1),
                  ],
          };
        });

        return updatedBrands;
      });
    } else if (action === 'delete') {
      setSelectedDepartment(emptyDepartment);
      setDepartments((arr) => {
        const updatedBrands = arr.map((brand) => {
          return {
            ...brand,
            departments: brand.departments.filter((obj) => obj._id !== e._id),
          };
        });

        return updatedBrands;
      });
      setPopupMessage('Abteilung gelöscht.', 'success');
    }
  }

  if (authCtx.isAdmin) {
    return (
      <Grid xs={12} container className="container">
        <Grid xs={12}>
          <PageHeading title="Admin">Administration</PageHeading>
        </Grid>
        <StyledTabs
          value={value}
          indicatorColor={brandObject.primeColor}
          onChange={handleTabChange}
          sx={{ marginBottom: '20px' }}
        >
          <Tab label="Öffnungszeiten" />
          <Tab label="Mitarbeiter" />
          <Tab label="Accounts" />
          <Tab label="Abteilungen" />
        </StyledTabs>
        <SwipeableViews
          axis="x"
          index={value}
          onChangeIndex={handleChangeTabIndex}
          enableMouseEvents
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            style={{ paddingTop: '20px' }}
          >
            <OpeningHoursForm />
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            id="mitarbeiter"
          >
            <StaffForm
              selected={selectedEmployee}
              patchEmployees={patchEmployees}
            />
            <EmployeeList
              employees={employees}
              selected={selectedEmployee}
              setSelected={setSelectedEmployee}
              patchEmployees={patchEmployees}
            />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction} id="accounts">
            <UserForm selected={selectedUser} patchUsers={patchUsers} />
            <UserList
              users={users}
              selected={selectedUser}
              setSelected={setSelectedUser}
              patchUsers={patchUsers}
            />
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            id="abteilungen"
          >
            <DepartmentForm
              selected={selectedDepartment}
              patchDepartments={patchDepartments}
            />
            <DepartmentsList
              departments={departments}
              selected={selectedDepartment}
              setSelected={setSelectedDepartment}
              patchDepartments={patchDepartments}
            />
          </TabPanel>
        </SwipeableViews>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button onClick={() => authCtx.logout()} color="red" maxWidth="200px">
            Ausloggen
          </Button>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Redirect
        to={`/${globals.BRANDS.VW.url}/${globals.PAGES.STARTSEITE.url}/`}
      />
    );
  }
}

export default Admin;

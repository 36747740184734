import classes from './JobApplication.module.css';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import dateformat from 'dateformat';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

// IMPORT MUI COMPONENTS
import { TextField, FormControlLabel, Checkbox } from '@mui/material/';
import { DateField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Grid from '@mui/material/Unstable_Grid2';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';
import Button from '../UI/Button';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import PopupContext from '../../store/popup-context';
import Popup from '../UI/Popup';
import usePostContent from '../../hooks/usePostContent';
import { globals } from '../../data/global-vars';

function JobApplication() {
  const history = useHistory();
  const { setPopupMessage } = useContext(PopupContext);
  const { error, postRequest } = usePostContent();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);

  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [formData, setFormData] = useState({
    job: '',
    salutation: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    startDate: tomorrow,
    message: '',
    reCaptcha: '',
    acceptPrivacy: false,
    // consent: false,
  });

  const [vita, setVita] = useState(null);
  function handleVitaFileChange(e) {
    if (e.target.files) {
      setVita(e.target.files[0]);
    }
  }
  const [motivationFile, setMotivationFile] = useState(null);
  function handleMotivationFileChange(e) {
    if (e.target.files) {
      setMotivationFile(e.target.files[0]);
    }
  }
  const [recommendationFile, setRecommendationFile] = useState(null);
  function handleRecommendationFileChange(e) {
    if (e.target.files) {
      setRecommendationFile(e.target.files[0]);
    }
  }
  const [otherFiles, setOtherFiles] = useState([]);
  function handleOthersFilesChange(e) {
    if (e.target.files) {
      setOtherFiles(e.target.files);
    }
  }

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  function submitApplicationHandler(e) {
    e.preventDefault();

    const formFields = [
      { mailLabel: 'Angestrebte Tätigkeit', value: formData.job },
      { mailLabel: 'Anrede', value: formData.salutation },
      { mailLabel: 'Vorname', value: formData.firstName },
      { mailLabel: 'Nachname', value: formData.lastName },
      { mailLabel: 'Telefon', value: formData.phone },
      { mailLabel: 'E-Mail', value: formData.email },
      { mailLabel: 'Straße', value: formData.street },
      { mailLabel: 'Hausnummer', value: formData.houseNumber },
      { mailLabel: 'Postleitzahl', value: formData.postalCode },
      { mailLabel: 'Stadt', value: formData.city },
      {
        mailLabel: 'Startdatum',
        value: dateformat(new Date(formData.startDate), 'dd.mm.yyyy'),
      },
      { mailLabel: 'Nachricht', value: formData.message },
      { mailLabel: 'Datenschutz akzeptiert.', value: formData.acceptPrivacy },
      // { mailLabel: 'Einwilligung', value: formData.consent },
    ];

    const formRequest = {
      fromMail: formData.email,
      phone: formData.phone,
      firstName: formData.firstName,
      lastName: formData.lastName,
      reCaptcha: formData.reCaptcha,
      type: 'assessment',
      data: formFields,
    };
    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));
    [vita, motivationFile, recommendationFile, ...otherFiles]
      .filter((obj) => obj !== undefined && obj !== null)
      .forEach((file) => {
        reqFormData.append('files', file);
      });

    postRequest(reqFormData, (d) => {
      if (!error) {
        setFormData({
          job: '',
          salutation: '',
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          street: '',
          houseNumber: '',
          postalCode: '',
          city: '',
          startDate: tomorrow,
          message: '',
          reCaptcha: '',
          acceptPrivacy: false,
          // consent: false,
        });
        setVita(null);
        setRecommendationFile(null);
        setMotivationFile(null);
        setOtherFiles([]);
        setPopupMessage('Bewerbung gesendet', 'success');
      } else setPopupMessage('Fehler beim Senden der Bewerbung', 'error');
    });
  }

  const formIsValid = () => {
    const requiredFields = [
      'firstName',
      'lastName',
      'phone',
      'email',
      'acceptPrivacy',
      'job',
      'street',
      'houseNumber',
      'postalCode',
      'city',
      'startDate',
      'message',
      'acceptPrivacy',
      // 'consent',
      'reCaptcha',
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }

    if (formData.reCaptcha !== '57A1') return false;

    return true;
  };

  useEffect(() => {
    if (history.location.state) {
      if (history.location.state.data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          job: history.location.state.data.job,
        }));
      }
    }
  }, []);

  return (
    <Fragment>
      <Popup />
      <PageHeading title={globals.PAGES.BEWERBUNG.name}>
        {globals.PAGES.BEWERBUNG.name}
      </PageHeading>
      <form onSubmit={submitApplicationHandler}>
        <Grid xs={12} container className="container">
          <Grid xs={12}>
            <h2 className={classes.teaserText}>
              - Ihre Bewerbung in nur 5 Minuten -
            </h2>
          </Grid>
          <Grid xs={12} container spacing={2}>
            <Grid xs={12} sm={6}>
              <TextField
                name="salutation"
                label="Anrede"
                value={formData.salutation}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="firstName"
                label="Vorname"
                value={formData.firstName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="lastName"
                label="Nachname"
                value={formData.lastName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="email"
                label="E-Mail"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="phone"
                label="Telefon"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12}>
              <hr className={classes.stripline}></hr>
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="street"
                label="Straße"
                value={formData.street}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="houseNumber"
                label="Hausnummer"
                value={formData.houseNumber}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="postalCode"
                label="Postleitzahl"
                value={formData.postalCode}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="city"
                label="Stadt"
                value={formData.city}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12}>
              <hr className={classes.stripline}></hr>
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                name="job"
                label="Angestrebte Tätigkeit"
                value={formData.job}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={
                  deDE.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
                adapterLocale="de"
              >
                <DateField
                  disablePast
                  label="Gewünschtes Startdatum"
                  value={dayjs(formData.startDate)}
                  onChange={handleChange}
                  // renderInput={(params) => <TextField {...params} />}
                  className={classes.appointmentDatepicker}
                  // inputFormat="DD.MM.YYYY"
                  fullWidth
                />
              </LocalizationProvider>
            </Grid>
            <Grid xs={12}>
              <TextField
                name="message"
                label="Nachricht"
                value={formData.message}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                required
              />
            </Grid>
            <Grid xs={12}>
              <hr className={classes.stripline}></hr>
            </Grid>
          </Grid>
          <Grid xs={12} container sx={{ textAlign: 'left' }} spacing={2}>
            <Grid xs={12} sm={6}>
              <p>Lebenslauf: *</p>
              <input
                type="file"
                onChange={handleVitaFileChange}
                accept="application/pdf"
                required
              />{' '}
            </Grid>
            <Grid xs={12} sm={6}>
              <p>Motivationsschreiben: *</p>
              <input
                type="file"
                onChange={handleMotivationFileChange}
                accept="application/pdf"
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <p>Empfehlungsschreiben:</p>
              <input
                type="file"
                onChange={handleRecommendationFileChange}
                accept="application/pdf"
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <p>Sonstige Dokumente:</p>
              <input
                type="file"
                onChange={handleOthersFilesChange}
                multiple
                accept="application/pdf"
              />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <hr className={classes.stripline}></hr>
          </Grid>
          <Grid xs={12} container sx={{ textAlign: 'left' }}>
            <Grid xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="acceptPrivacy"
                    checked={formData.acceptPrivacy}
                    onChange={handleChange}
                  />
                }
                label={
                  <span>
                    Ich habe die <a href="/datenschutz">Datenschutzerklärung</a>{' '}
                    gelesen und akzeptiere diese.
                  </span>
                }
                required
              />
            </Grid>
            {/* <Grid xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                  />
                }
                label="Einwilligung"
                required
              />
            </Grid> */}
            <Grid
              xs={12}
              container
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Grid xs="auto">
                <img
                  src={captcha}
                  alt="captcha"
                  loading="lazy"
                  style={{
                    maxWidth: '140px',
                    marginRight: '10px',
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid xs={4}>
                <TextField
                  fullWidth
                  label="Sicherheitscode"
                  variant="outlined"
                  name="reCaptcha"
                  sx={{ maxWidth: '150px' }}
                  value={formData.reCaptcha}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '30px',
            }}
          >
            <Button
              type="submit"
              maxWidth="200px"
              color="green"
              disabled={!formIsValid()}
            >
              Abschicken
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default JobApplication;

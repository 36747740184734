import React, { useState, useEffect } from 'react';

const CompareContext = React.createContext({
  // cars: [],
});

// const getCars = () => {
//   let cars = localStorage.getItem('cars');
//   console.log('cars:', cars);
//   if (cars) cars = cars.split(',');
//   else cars = [];
//   return cars.map(Number);
// };

export const CompareContextProvider = (props) => {
  // const cars = getCars();

  // useEffect(() => {
  //   localStorage.setItem('cars', cars);
  // }, []);

  // let initialCars;
  // if (cars) {
  //   initialCars = cars;
  // } else initialCars = [];

  // const [carIds, setCarIds] = useState(initialCars);

  // function toggleCars(i) {
  //   if (!carIds.includes(i)) setCarIds((s) => [...s, i]);
  //   else setCarIds((s) => s.filter((v) => v !== i));
  // }

  // useEffect(() => {
  //   if (carIds) localStorage.setItem('cars', carIds);
  //   console.log(carIds, cars);
  // }, [carIds]);

  const [ids, setIds] = useState([]);

  useEffect(() => {
    // Speichere die IDs im LocalStorage beim Update
    localStorage.setItem('cars', JSON.stringify(ids));
  }, [ids]);

  useEffect(() => {
    // Lade die IDs aus dem LocalStorage beim Initialisieren
    const storedIds = localStorage.getItem('cars');
    if (storedIds) {
      setIds(JSON.parse(storedIds));
    }
  }, []);

  useEffect(() => {
    // Füge einen Event-Listener hinzu, um den LocalStorage bei einem Unload-Event zu aktualisieren
    const handleUnload = () => {
      localStorage.setItem('cars', JSON.stringify(ids));
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      // Entferne den Event-Listener, wenn die Komponente unmountet wird
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [ids]);

  // Funktion zum Hinzufügen/Entfernen von IDs
  const toggleId = (id) => {
    setIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      } else {
        return [...prevIds, id];
      }
    });
  };

  const contextValue = {
    cars: ids,
    toggleCars: toggleId,
  };

  return (
    <CompareContext.Provider value={contextValue}>
      {props.children}
    </CompareContext.Provider>
  );
};

export default CompareContext;

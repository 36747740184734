import { useState, useEffect } from 'react';
import { globals } from '../data/global-vars';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export function useGetWidth() {
  let { width } = useWindowDimensions();
  return width;
}

export default function useGetMobile() {
  let { width } = useWindowDimensions();
  if (width < globals.TABLETWIDTH) {
    return true;
  } else {
    return false;
  }
}
export function useGetTablet() {
  let { width } = useWindowDimensions();
  if (width < 1200) {
    return true;
  } else {
    return false;
  }
}

import { useParams } from 'react-router-dom';
import classes from './PageLoaderIcon.module.css';
import { useBrandFind } from '../../hooks/useFinders';

function PageLoaderIcon(props) {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColorHex = brandObject.primeColorHex;
  return (
    <div className={!props.isLoading ? classes.display_none : classes.loader}>
      <svg
        className={classes.car}
        width="102"
        height="40"
        display={!props.isLoading ? 'none' : undefined}
      >
        <g
          transform="translate(2 1)"
          stroke={primeColorHex}
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            className={classes.car__body}
            d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
            fill="#FFF"
            strokeWidth="3"
          />
          <ellipse
            className={classes.car__wheel__left}
            strokeWidth="3.2"
            fill="#FFF"
            cx="83.493"
            cy="30.25"
            rx="6.922"
            ry="6.808"
          />
          <ellipse
            className={classes.car__wheel__right}
            strokeWidth="3.2"
            fill="#FFF"
            cx="46.511"
            cy="30.25"
            rx="6.922"
            ry="6.808"
          />
          <path
            className={`${classes.car__line} ${classes.car__line__top}`}
            d="M22.5 16.5H2.475"
            strokeWidth="3"
          />
          <path
            className={`${classes.car__line} ${classes.car__line__middle}`}
            d="M20.5 23.5H.4755"
            strokeWidth="3"
          />
          <path
            className={`${classes.car__line} ${classes.car__line__bottom}`}
            d="M25.5 9.5h-19"
            strokeWidth="3"
          />
        </g>
      </svg>
    </div>
  );
}

export default PageLoaderIcon;

import classes from './ImageCarousel.module.css';
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useEffect } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function ImageCarousel(props) {
  const images = Array.from({ length: props.pictureCount }).map((v, i) => {
    return {
      originalClass: classes.imageClass,
      thumbnailClass: classes.thumbnailClass,
      original: `https://autohaus-seyfarth.de/images/cars/${
        props.carzillaId
      }_${String(i + 1).padStart(2, '0')}.jpg`,
      thumbnail: `https://autohaus-seyfarth.de/images/cars/${
        props.carzillaId
      }_${String(i + 1).padStart(2, '0')}.jpg`,
    };
  });

  useEffect(() => {
    if (props.show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [props]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        props.onHide();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 120,
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        padding: '0 10px',
        backgroundColor: 'white',
      }}
    >
      <CloseRoundedIcon
        className={`${classes.closeIcon} noSelect`}
        onClick={props.onHide}
        sx={{ height: '30px', width: '30px' }}
      />
      <div
        style={{
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      >
        <ReactImageGallery items={images} autoPlay={false} />
      </div>
    </div>
  );
}

export default ImageCarousel;

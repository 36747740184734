import { useContext, useEffect, useState } from 'react';
import classes from './OpeningHours.module.css';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import useFetchContent from '../../hooks/useFetchContent';
import { Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import Collapsible from '../UI/Collapsible';
import Button from '../UI/Button';
import ModalContext from '../../store/modal-context';
import usePatchContent from '../../hooks/usePatchContent';
import dateformat from 'dateformat';
import usePostContent from '../../hooks/usePostContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel } from '@fortawesome/free-solid-svg-icons';
import useDeleteContent from '../../hooks/useDeleteContent';
import { DateField } from '@mui/x-date-pickers';
import PopupContext from '../../store/popup-context';

function ExceptionList(props) {
  const _id = props._id;
  const { deleteExceptionFromOpeningHour } = useDeleteContent();
  const { closeModal } = useContext(ModalContext);
  const { setPopupMessage } = useContext(PopupContext);

  const exceptions =
    props.exceptions.filter((obj) => new Date(obj.date) > new Date()) || [];

  function deleteExceptionHandler(_eId) {
    deleteExceptionFromOpeningHour(_id, _eId, (d) => {
      props.updateOpeningHour(d);
      setPopupMessage('Ausnahme gelöscht.', 'success');
    });
    closeModal();
  }

  return (
    <Grid item xs={12} container>
      {exceptions.length >= 1 ? (
        exceptions.map((e, i) => {
          return (
            <Grid
              item
              xs={12}
              key={i}
              sx={{
                margin: '10px 0',
              }}
              container
            >
              <Grid
                xs={'auto'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ListItemAvatar>
                  <FontAwesomeIcon
                    icon={faCancel}
                    className={classes.cancelIcon}
                    onClick={() => {
                      deleteExceptionHandler(e._id);
                    }}
                  />
                </ListItemAvatar>
              </Grid>
              <Grid xs={'auto'}>
                <ListItemText
                  primary={e.description}
                  secondary={
                    <>
                      <span>{dateformat(e.date, 'dd.mm.yyyy')}, </span>
                      <span>
                        {e.isOpen ? e.start + ' - ' + e.end : 'geschlossen'}{' '}
                      </span>
                    </>
                  }
                  sx={{ width: 'fit-content' }}
                />
              </Grid>
            </Grid>
          );
        })
      ) : (
        <p>Es gibt keine aktuellen Ausnahmen.</p>
      )}
    </Grid>
  );
}

function ExceptionForm(props) {
  const _id = props._id;
  const brand = props.brand;
  const { showModal } = useContext(ModalContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { error: uploadError, postException } = usePostContent();
  const [formData, setFormData] = useState({
    brand: brand,
    description: '',
    date: dayjs(new Date()),
    isOpen: false,
    start: dayjs('2022-04-17T8:00'),
    end: dayjs('2022-04-17T12:00'),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleTimeChange = (e, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: e,
    }));
  };

  function showExceptionsHandler() {
    showModal(
      'Alle Ausnahmen',
      null,
      <ExceptionList
        updateOpeningHour={props.updateOpeningHour}
        exceptions={props.exceptions}
        _id={_id}
      />
    );
  }

  function submitExceptionHandler() {
    const exception = {
      description: formData.description,
      isOpen: formData.isOpen,
      date: dateformat(new Date(formData.date), 'yyyy-mm-dd'),
      start: dateformat(new Date(formData.start), 'HH:MM'),
      end: dateformat(new Date(formData.end), 'HH:MM'),
    };
    postException(_id, exception, (d) => {
      if (!uploadError) {
        setPopupMessage('Ausnahme hinzugefügt.', 'success');
        setFormData({
          brand: brand,
          description: '',
          date: dayjs(new Date()),
          isOpen: false,
          start: dayjs('2022-04-17T8:00'),
          end: dayjs('2022-04-17T12:00'),
        });
        props.updateOpeningHour(d);
      }
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} md={8} sx={{ marginTop: '10px' }}>
          <TextField
            label="Begründung"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ marginTop: '10px' }}>
          <DateField
            value={formData.date}
            disablePast
            name="date"
            onChange={(e) => handleTimeChange(e, 'date')}
            format="DD.MM.YYYY"
            fullWidth
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            marginTop: '10px',
          }}
        >
          <Grid item xs="auto">
            <FormControlLabel
              control={
                <Checkbox
                  name="isOpen"
                  checked={formData.isOpen}
                  onChange={handleChangeCheckbox}
                />
              }
              label="geöffnet"
            />
          </Grid>
          <Grid item xs="auto" sx={{ display: 'flex', alignItems: 'center' }}>
            <TimeField
              // label={`${day.weekdayDesc} Start Time`}
              value={formData.start}
              onChange={(e) => handleTimeChange(e, 'start')}
              format="HH:mm"
              disabled={!formData.isOpen}
              sx={{ width: '80px' }}
            />
            <span style={{ margin: '0 20px' }}> - </span>
            <TimeField
              // label={`${day.weekdayDesc} End Time`}
              value={formData.end}
              onChange={(e) => handleTimeChange(e, 'end')}
              format="HH:mm"
              disabled={!formData.isOpen}
              sx={{ width: '80px' }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
          container
        >
          <Grid sx={{ marginRight: '10px' }}>
            <Button
              maxWidth="200px"
              color="var(--vw-color)"
              onClick={showExceptionsHandler}
            >
              alle Ausnahmen
            </Button>
          </Grid>
          <Grid>
            <Button
              maxWidth="200px"
              color="green"
              onClick={submitExceptionHandler}
            >
              Hinzufügen
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}

export function OpeningHoursForm() {
  const [item, setItem] = useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { error, getAllOpeningHours } = useFetchContent();
  const { patchOpeningHour, patchOpeningHourSingleDay } = usePatchContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { setPopupMessage } = useContext(PopupContext);

  const [formData, setFormData] = useState({
    selectedBrand: brandObject.make || 'VW',
    weekdaysAsString: '',
    saturdayAsString: '',
    sundayAsString: '',
    1: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T18:00') },
    2: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T18:00') },
    3: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T18:00') },
    4: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T18:00') },
    5: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T18:00') },
    6: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T18:00') },
    7: { start: dayjs('2022-04-17T08:00'), end: dayjs('2022-04-17T10:00') },
  });

  useEffect(() => {
    getAllOpeningHours((d) => {
      if (!error) {
        setItem(d.find((item) => item.brand === formData.selectedBrand));
        setDataLoaded(true);
      }
    });
  }, [formData.selectedBrand]);

  useEffect(() => {
    if (item) {
      setFormData({
        selectedBrand: item.brand,
        weekdaysAsString: item.weekdaysAsString,
        saturdayAsString: item.saturdayAsString,
        sundayAsString: item.sundayAsString,
        1: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 1).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 1).end
          ),
        },
        2: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 2).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 2).end
          ),
        },
        3: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 3).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 3).end
          ),
        },
        4: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 4).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 4).end
          ),
        },
        5: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 5).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 5).end
          ),
        },
        6: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 6).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 6).end
          ),
        },
        7: {
          start: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 7).start
          ),
          end: dayjs(
            '2022-04-17T' + item.days.find((d) => d.weekday === 7).end
          ),
        },
      });
    }
  }, [item]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleTimeChange = (e, day, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [day]: { ...prevData[day], [field]: e },
    }));
  };

  function submitHandler(e) {
    e.preventDefault();
    const openingHour = {
      weekdaysAsString: formData.weekdaysAsString,
      saturdayAsString: formData.saturdayAsString,
      sundayAsString: formData.sundayAsString,
    };
    patchOpeningHour(item._id, openingHour, (d) => {
      if (!error) {
        item.days.map((day) => {
          const newDay = {
            weekday: day.weekday,
            weekdayDesc: day.weekdayDesc,
            start: dateformat(new Date(formData[day.weekday].start), 'HH:MM'),
            end: dateformat(new Date(formData[day.weekday].end), 'HH:MM'),
          };
          patchOpeningHourSingleDay(item._id, day._id, newDay, (dd) => {});
        });
      }
      setPopupMessage('Öffnungszeiten aktualisiert!', 'success');
    });
  }

  if (!dataLoaded || error) {
    return (
      <Grid
        item
        xs={12}
        container
        spacing={2}
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Skeleton variant="rounded" width="250px" height="60px" />
          <br />
          <Skeleton variant="rounded" width="250px" height="60px" />
          <br />
          <Skeleton variant="rounded" width="250px" height="60px" />
          <br />
          <Skeleton variant="rounded" width="250px" height="60px" />
          <br />
          <Skeleton variant="rounded" width="250px" height="40px" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Skeleton variant="rounded" width="200px" height="40px" />
          <br />
          <Skeleton variant="rounded" width="200px" height="40px" />
        </Grid>
      </Grid>
    );
  } else
    return (
      <form onSubmit={submitHandler}>
        <Grid
          container
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'flex-start' }}
        >
          <Grid
            item
            xs={12}
            sm={4}
            container
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              padding: '0 10px',
            }}
            spacing={3}
          >
            <Grid item xs={12} className={classes.concInput}>
              <FormControl fullWidth>
                <InputLabel>Haus</InputLabel>
                <Select
                  name="selectedBrand"
                  label="Haus"
                  value={formData.selectedBrand}
                  onChange={handleChange}
                >
                  <MenuItem value="VW">Autohaus Rainer Seyfarth</MenuItem>
                  <MenuItem value="SKODA">Automobile Am Mönchhof</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.concInput}>
              <TextField
                label="Beschreibung wochentags"
                name="weekdaysAsString"
                value={formData.weekdaysAsString}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} className={classes.concInput}>
              <TextField
                label="Beschreibung samstags"
                name="saturdayAsString"
                value={formData.saturdayAsString}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} className={classes.concInput}>
              <TextField
                label="Beschreibung sonntags"
                name="sundayAsString"
                value={formData.sundayAsString}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} className={classes.concInput}>
              <Button color="green" type="submit">
                Aktualisieren
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Collapsible label={<h1 className="sectionHeading">Standard</h1>}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {item.days.map((day, i) => (
                  <Grid
                    container
                    key={i}
                    sx={{
                      margin: '10px 0',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      textAlign: 'left',
                    }}
                  >
                    <Grid item xs={12} className={classes.weekdayHeading}>
                      {day.weekdayDesc}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <TimeField
                        // label={`${day.weekdayDesc} Start Time`}
                        value={formData[day.weekday].start}
                        onChange={(e) =>
                          handleTimeChange(e, day.weekday, 'start')
                        }
                        format="HH:mm"
                      />
                      <span style={{ margin: '0 20px' }}> - </span>
                      <TimeField
                        // label={`${day.weekdayDesc} End Time`}
                        value={formData[day.weekday].end}
                        onChange={(e) =>
                          handleTimeChange(e, day.weekday, 'end')
                        }
                        format="HH:mm"
                      />
                    </Grid>
                  </Grid>
                ))}
              </LocalizationProvider>
            </Collapsible>
            <Collapsible label={<h1 className="sectionHeading">Ausnahmen</h1>}>
              <ExceptionForm
                exceptions={item.exceptions}
                updateOpeningHour={setItem}
                brand={formData.selectedBrand}
                _id={item._id}
              />
            </Collapsible>
          </Grid>
        </Grid>
      </form>
    );
}

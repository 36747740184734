import { useState, useCallback } from 'react';

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);
    let body;
    if (requestConfig.body) {
      if (requestConfig.noJSON) body = requestConfig.body;
      else body = JSON.stringify(requestConfig.body);
    } else {
      body = null;
    }
    try {
      const response = await fetch(
        'https://autohaus-seyfarth.de' + requestConfig.url,
        // 'http://localhost:3000' + requestConfig.url,
        {
          method: requestConfig.method ? requestConfig.method : 'GET',
          headers: requestConfig.headers ? requestConfig.headers : {},
          body,
        }
      );

      let data;

      if (requestConfig.returnBlob) {
        data = await response.blob();
      } else {
        data = await response.json();
        if (!response.ok) {
          if (data.status === 'error') throw new Error(data.message);
        }
      }
      applyData(data);
    } catch (err) {
      setError(err.message || 'Etwas ist schiefgelaufen!');
    }
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;

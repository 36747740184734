import classes from './Request.module.css';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import dateformat from 'dateformat';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

// IMPORT MUI AND OTHER COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import {
  FormControl,
  InputLabel,
  Tab,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material/';
import { useTheme } from '@emotion/react';
import { requests } from '../../data/service';
import SwipeableViews from 'react-swipeable-views';
import Button from '../UI/Button';
import { DateTimeField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PopupContext from '../../store/popup-context';
import Popup from '../UI/Popup';
import usePostContent from '../../hooks/usePostContent';
import { StyledTabs, TabPanel } from '../UI/TabController';
import { useBrandFind } from '../../hooks/useFinders';

function Request() {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const history = useHistory();
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const { pathname } = useLocation();
  const tabKey = pathname.split('/');
  const { setPopupMessage } = useContext(PopupContext);
  const { error, postRequest } = usePostContent();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);

  const [formValues, setFormValues] = useState({}); // State für Formularwerte

  // Funktion zum Aktualisieren der Formularwerte
  const handleFormChange = (stepIndex, requestIndex, inputIndex, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [`${requestIndex}-${stepIndex}-${inputIndex}`]: value,
    }));
  };

  function pageIsValid(page) {
    const step = page - 1;
    let feedback = true;
    if (page > 0)
      requests[value].steps[step].map((ip, id) => {
        if (ip.obligatory) {
          if (
            ip.type === 'reCaptcha' &&
            formValues[`${value}-${step}-${id}`] !== '57A1'
          ) {
            feedback = false;
          } else if (
            !formValues[`${value}-${step}-${id}`] ||
            formValues[`${value}-${step}-${id}`] === ''
          ) {
            feedback = false;
          }
        }
      });
    return feedback;
  }
  function formIsValid() {
    let feedback = true;
    requests[value].steps.map((s, i) => {
      feedback = pageIsValid(i + 1);
    });
    return feedback;
  }

  function submitHandler(e) {
    e.preventDefault();
    let formData = [];
    let genInfo = {};

    requests.map((r, i) => {
      r.steps.map((s, ii) => {
        s.map((ip, iii) => {
          const val = formValues[`${value}-${ii}-${iii}`] || '';
          if (i === value) {
            if (ip.type === 'date_future' || ip.type === 'date_past')
              formData = [
                ...formData,
                {
                  mailLabel: ip.mailLabel,
                  value: dateformat(new Date(val), 'dd.mm.yyyy, H:MM') + ' Uhr',
                },
              ];
            else
              formData = [...formData, { mailLabel: ip.mailLabel, value: val }];

            if (
              ip.key === 'firstName' ||
              ip.key === 'lastName' ||
              ip.key === 'fromMail' ||
              ip.key === 'phone' ||
              ip.key === 'reCaptcha'
            ) {
              genInfo = { ...genInfo, [ip.key]: val };
            }
            if (ip.key === 'type')
              genInfo = {
                ...genInfo,
                type: requests[i].steps[ii].find((obj) => obj.key === 'type')
                  .value,
              };
          }
        });
      });
    });

    const formRequest = {
      ...genInfo,
      data: formData,
      type: 'service',
      reCaptcha: '57A1',
    };

    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));

    postRequest(reqFormData, (d) => {
      if (!error) {
        setPopupMessage('Anfrage gesendet', 'success');
        setFormValues({});
      } else {
        setPopupMessage('Fehler beim Senden der Anfrage', 'error');
      }
    });
  }

  useEffect(() => {
    requests.map((r, i) => {
      if (tabKey[3] === r.path) {
        if (value !== i) {
          window.scrollTo(0, 0);
          setValue(i);
        }
      }
    });
  }, [value, tabKey]);

  function handleTabChange(event, index) {
    setValue(index);
    const url = requests[index] && requests[index].path;
    history.push(`/${brandObject.url}/anfrage/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = requests[index] && requests[index].path;
    history.push(`/${brandObject.url}/anfrage/` + url);
  }

  function toggleStringInArray(array, string) {
    const index = array.indexOf(string);
    if (index !== -1) {
      // Zeichenkette im Array gefunden, entfernen
      array.splice(index, 1);
    } else {
      // Zeichenkette nicht im Array gefunden, hinzufügen
      array.push(string);
    }
    return array;
  }

  function renderInput(ip, stepIndex, inputIndex, requestIndex) {
    const inputKey = `${requestIndex}-${stepIndex}-${inputIndex}`;
    const value = formValues[inputKey] || ''; // Aktueller Wert des Inputs

    switch (ip.type) {
      case 'select':
      case 'dependant_select':
        return (
          <FormControl fullWidth>
            <InputLabel id="select-label-test">{ip.title}</InputLabel>
            <Select
              labelId="select-label-test"
              label={ip.title}
              className={classes.appointmentSelect}
              value={value}
              onChange={(e) => {
                handleFormChange(
                  stepIndex,
                  requestIndex,
                  inputIndex,
                  e.target.value
                );
              }}
            >
              {ip.type !== 'dependant_select' &&
                ip.options.map((o, oi) => {
                  return (
                    <MenuItem value={o} key={oi}>
                      {o}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        );

      case 'text':
        return (
          <TextField
            label={ip.title}
            variant="outlined"
            className={classes.appointmentTextInput}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'email':
        return (
          <TextField
            label={ip.title}
            required={ip.obligatory}
            type="email"
            variant="outlined"
            className={classes.appointmentTextInput}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'telephone':
        return (
          <TextField
            label={ip.title}
            required={ip.obligatory}
            type="text"
            variant="outlined"
            className={classes.appointmentTextInput}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'date_future':
      case 'date_past':
        var tomorrow = new Date();
        if ('date_future') tomorrow.setDate(tomorrow.getDate() + 1);
        else if ('date_past') tomorrow.setDate(tomorrow.getDate() - 1);
        return (
          <DateTimeField
            disableFuture={ip.type === 'date_future' ? false : true}
            disablePast={ip.type === 'date_past' ? false : true}
            label={ip.title}
            value={dayjs(value || tomorrow)}
            onChange={(e) =>
              handleFormChange(stepIndex, requestIndex, inputIndex, new Date(e))
            }
            className={classes.appointmentDatepicker}
          />
        );

      case 'textarea':
        return (
          <TextField
            label={ip.title}
            multiline
            rows={4}
            className={classes.appointmentTextarea}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!value}
                onChange={(e) => {
                  handleFormChange(
                    stepIndex,
                    requestIndex,
                    inputIndex,
                    e.target.checked
                  );
                }}
              />
            }
            label={ip.label}
            className={classes.appointmentCheckbox}
            required={ip.obligatory === true ? true : false}
            style={{ color: 'var(--text-color)' }}
          />
        );

      case 'checkboxes':
        if (!value) handleFormChange(stepIndex, requestIndex, inputIndex, []);
        return ip.options.map((o, oi) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value.includes(o) || false}
                  onChange={() => {
                    handleFormChange(
                      stepIndex,
                      requestIndex,
                      inputIndex,
                      toggleStringInArray(value, o)
                    );
                  }}
                />
              }
              label={o}
              key={oi}
              className={classes.appointmentCheckbox}
              style={{ color: 'var(--text-color)' }}
            />
          );
        });

      case 'reCaptcha':
        return (
          <Grid
            xs={12}
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid xs="auto">
              <img
                src={captcha}
                alt="captcha"
                loading="lazy"
                style={{
                  maxWidth: '140px',
                  marginRight: '10px',
                  borderRadius: '8px',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                fullWidth
                label="Sicherheitscode"
                variant="outlined"
                sx={{ maxWidth: '150px' }}
                value={value}
                onChange={(e) => {
                  handleFormChange(
                    stepIndex,
                    requestIndex,
                    inputIndex,
                    e.target.value
                  );
                }}
                required
              />
            </Grid>
          </Grid>
        );

      default:
        break;
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        deDE.components.MuiLocalizationProvider.defaultProps.localeText
      }
      adapterLocale="de"
    >
      <Popup />
      <PageHeading title="Anfrage">Anfrage</PageHeading>
      <div className={classes.tabBar + ' container'} style={{ paddingTop: 0 }}>
        <StyledTabs
          value={value}
          indicatorColor={brandObject.primeColor}
          className={classes.tabWrapper}
          onChange={handleTabChange}
        >
          {requests.map((r, i) => {
            return (
              <Tab label={r.title} key={i} style={{ cursor: 'pointer' }} />
            );
          })}
        </StyledTabs>
      </div>
      <form onSubmit={submitHandler}>
        <SwipeableViews
          axis="x"
          index={value}
          className="container"
          onChangeIndex={handleChangeTabIndex}
          enableMouseEvents
        >
          {requests.map((r, i) => {
            return (
              <TabPanel
                value={value}
                key={i}
                index={i}
                dir={theme.direction}
                id={r.path}
              >
                <Grid xs={12} container>
                  {r.steps.map((s, ii) => {
                    return (
                      <Grid
                        xs={12}
                        container
                        key={`${i}.${ii}`}
                        className={classes.inputSegment}
                        sx={{ margin: '20px 0', padding: '20px 0' }}
                        spacing={2}
                      >
                        {s.map((ip, id) => {
                          const inputKey = `${ii}-${id}`;
                          const value = formValues[inputKey] || ''; // Aktueller Wert des Inputs
                          return (
                            <Grid
                              xs={12}
                              sm={ip.type === 'textarea' ? 12 : 6}
                              key={inputKey}
                            >
                              {renderInput(ip, ii, id, i)}
                            </Grid>
                          );
                        })}
                      </Grid>
                    );
                  })}
                  <Grid
                    xs={12}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      maxWidth="200px"
                      color="green"
                      type="submit"
                      disabled={!formIsValid()}
                    >
                      Absenden
                    </Button>
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })}
        </SwipeableViews>
      </form>
    </LocalizationProvider>
  );
}

export default Request;

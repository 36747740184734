import Autohaus from '../components/Views/Autohaus';
import Neuwagen from '../components/Views/Neuwagen';
import Gebrauchtwagen from '../components/Views/Gebrauchtwagen';
import Service from '../components/Views/Service';
import Startseite from '../components/Views/Startseite';
import Wohnmobile from '../components/Views/Wohnmobile';
import Admin from '../components/Views/Admin';

import skoda_logo from '../assets/car_logos/Skoda.webp';
import audi_service_logo from '../assets/car_logos/Audi_Service.webp';
import audi_sale_logo from '../assets/car_logos/Audi_Sale.webp';
import vw_logo from '../assets/car_logos/VW.webp';
import vw_nfz_logo from '../assets/car_logos/VW_NFZ.png';
import Imprint from '../components/OnePager/imprint';
import Contact from '../components/OnePager/contact';
import PrivacyPolicy from '../components/OnePager/privacyPolicy';
import JobApplication from '../components/OnePager/JobApplication';
import Request from '../components/OnePager/Request';
import Vergleichsseite from '../components/Views/Vergleichsseite';
import Detailseite from '../components/Views/Detailseite';

export const globals = {
  TABLETWIDTH: 900,
  BRANDS: {
    VW: {
      id: 0,
      url: 'vw',
      name: 'VW',
      mobileKey: 'VW',
      make: 'VW',
      carzillaBrand: 'VW',
      primeColor: 'var(--vw-color)',
      primeColorHex: '#0D4671',
      logoStyle: 'vwLogo',
      logo: vw_logo,
      sale_logo: vw_logo,
      hideInBrandswitch: false,
      hideInMotorhome: false,
    },
    VW_NFZ: {
      id: 1,
      url: 'vw-nutzfahrzeuge',
      name: 'VW Nutzfahrzeuge',
      mobileKey: 'VW',
      make: 'VW-NFZ',
      carzillaBrand: 'VW',
      primeColor: 'var(--vw-nfz-color)',
      primeColorHex: '#0D4671',
      logoStyle: 'vwNfzLogo',
      logo: vw_nfz_logo,
      onlyService: true,
      hideInBrandswitch: true,
      hideInMotorhome: true,
    },
    AUDI: {
      id: 3,
      url: 'audi',
      name: 'AUDI',
      mobileKey: 'AUDI',
      make: 'AUDI',
      carzillaBrand: 'Audi',
      primeColor: 'var(--audi-color)',
      primeColorHex: '#D10A00',
      logoStyle: 'audiServiceLogo',
      logo: audi_service_logo,
      onlyService: true,
      hideInBrandswitch: false,
      hideInMotorhome: true,
      sale_logo: audi_sale_logo,
    },
    SKODA: {
      id: 2,
      url: 'skoda',
      name: 'SKODA',
      mobileKey: 'SKODA',
      make: 'SKODA',
      carzillaBrand: 'Skoda',
      primeColor: 'var(--skoda-color)',
      primeColorHex: '#0E3A2F',
      logoStyle: 'skodaLogo',
      logo: skoda_logo,
      sale_logo: skoda_logo,
      hideInBrandswitch: false,
      hideInMotorhome: true,
    },
  },
  PAGES: {
    STARTSEITE: {
      url: 'startseite',
      content: <Startseite />,
      name: 'Startseite',
    },
    NEUWAGEN: {
      url: 'neuwagen',
      content: <Neuwagen />,
      name: 'Neuwagen',
      showInFooter: true,
      subpages: [
        { title: 'Beratung', url: 'anfrage/beratungstermin', otherPage: true },
        {
          title: 'Probefahrt',
          url: 'anfrage/probefahrttermin',
          otherPage: true,
        },
        { title: 'Bestand', url: 'bestand' },
      ],
    },
    GEBRAUCHTWAGEN: {
      url: 'gebrauchtwagen',
      content: <Gebrauchtwagen />,
      name: 'Gebrauchtwagen',
      showInFooter: true,
      subpages: [
        { title: 'Liste', url: '#liste' },
        { title: 'Ankauf', url: 'ankauf' },
        {
          title: 'Probefahrt',
          url: 'anfrage/probefahrttermin',
          otherPage: true,
        },
        {
          title: 'Anfrage',
          url: 'anfrage/fahrzeuganfrage',
          otherPage: true,
        },
      ],
    },
    WOHNMOBILE: {
      url: 'wohnmobile',
      content: <Wohnmobile />,
      name: 'Wohnmobile',
      showInFooter: true,
      subpages: [
        { title: 'Wohnmobilvermietung', url: '#termin-buchen' },
        { title: 'Mietbedingungen', url: '#mietbedingungen' },
        { title: 'Checklisten', url: '#checklisten' },
        { title: 'Wohnmobilverkauf', url: 'verkauf' },
      ],
    },
    SERVICE: {
      url: 'service',
      content: <Service />,
      name: 'Service',
      showInFooter: true,
      subpages: [
        {
          title: 'Werkstatttermin',
          url: 'anfrage/werkstatttermin',
          otherPage: true,
        },
        { title: 'Serviceleistungen', url: '#serviceleistungen' },
        { title: 'Vermietung', url: '#vermietung-und-nachruestung' },
        { title: 'Waschanlage', url: '#waschanlage' },
        { title: 'Finanzielles', url: 'finanzielles/#mehr-informationen' },
        { title: 'Nachrüstungen', url: '#vermietung-und-nachruestung' },
        {
          title: 'Zubehör und Teile',
          url: 'zubehoer-und-teile/#mehr-informationen',
        },
        {
          title: 'NORA',
          url: 'nora/#mehr-informationen',
        },
        { title: 'Großkunden', url: 'großkunden/#mehr-informationen' },
      ],
    },
    AUTOHAUS: {
      url: 'autohaus',
      content: <Autohaus />,
      name: 'Autohaus',
      showInFooter: true,
      subpages: [
        { title: 'Über uns', url: '#ueber-uns' },
        { title: 'Unser Team', url: '#unser-team' },
        { title: 'Unsere Partner', url: '#unsere-partner' },
        { title: 'Aktuelles', url: '#aktuelles' },
        { title: 'Karriere', url: '#karriere' },
        { title: 'Bonuskarte', url: '#bonuskarte' },
        { title: 'Veranstaltungen', url: '#veranstaltungen' },
      ],
    },
    ADMIN: {
      url: 'admin',
      content: <Admin />,
      name: 'Admin',
      showInFooter: false,
      loginNeeded: true,
      subpages: [],
    },
    IMPRESSUM: {
      url: 'impressum',
      content: <Imprint />,
      name: 'Impressum',
      showInFooter: false,
      hideInNav: true,
    },
    KONTAKT: {
      url: 'kontakt',
      content: <Contact />,
      name: 'Kontakt',
      showInFooter: false,
      hideInNav: true,
    },
    DATENSCHUTZ: {
      url: 'datenschutz',
      content: <PrivacyPolicy />,
      name: 'Datenschutz',
      showInFooter: false,
      hideInNav: true,
    },
    // COOKIES: {
    //   url: 'cookie-richtlinie',
    //   content: <Cookies />,
    //   name: 'Cookie-Richtlinie',
    //   showInFooter: false,
    //   hideInNav: true,
    // },
    // RECHTLICHES: {
    //   url: 'rechtliches',
    //   content: <Rechtliches />,
    //   name: 'Rechtliches',
    //   showInFooter: false,
    //   hideInNav: true,
    // },
    BEWERBUNG: {
      url: 'online-bewerbung',
      content: <JobApplication />,
      name: 'Online-Bewerbung',
      showInFooter: false,
      hideInNav: true,
    },
    ANFRAGE: {
      url: 'anfrage',
      content: <Request />,
      name: 'Anfrage',
      showInFooter: false,
      hideInNav: true,
    },
    VERGLEICH: {
      url: 'vergleich',
      content: <Vergleichsseite />,
      name: 'Vergleichen',
      showInFooter: false,
      hideInNav: true,
    },
    DETAIL: {
      url: 'details',
      content: <Detailseite />,
      name: 'Detail',
      showInFooter: false,
      hideInNav: true,
    },
  },
};

export const compData = {
  AAM: {
    NAME: <span>AUTOMOBILE AM MÖNCHHOF GMBH</span>,
    PAGE_TITLE: 'Automobile Am Mönchhof',
    TELEFONNUMMER: '03621 / 82070',
    TELEFONNUMMER_LINK: '+49362182070',
    FAX: '03621 / 820777',
    NOTFALL: '0173-9 33 99 99',
    NOTFALL_LINK: '+491739339999',
    EMAIL: 'info@skoda-gotha.de',
    GESCHAEFTSFUEHRER: 'Ralf Dammeyer',
    REGISTER_GERICHT_HANDEL: 'Amtsgericht Jena',
    REGISTERNUMMER_HANDEL: '7273',
    VERWALTUNG: 'Verantwortlicher i.S. d. §55 Abs. 2 RfStV: Ralf Dammeyer',
    UID: 'DE158265285',
    DATENSCHUTZERKLAERUNG_ID: '647f6de718df3959450f2542',
    COOKIE_RICHTLINIE_ID: '647f6eea18df3959450f2546',
    RECHTLICHES_ID: '647f6fda18df3959450f254a',
    IMPRINT_NODE: (
      <p>
        Alle Rechte vorbehalten. Text, Bilder, Grafiken, Sound, Animationen und
        Videos unterliegen dem Schutz des Urheberrechts und anderer
        Schutzgesetze. Der Inhalt dieser Websites darf nicht zu kommerziellen
        Zwecken kopiert, verbreitet oder Dritten zugänglich gemacht werden.
        <br />
        <br />
        Wir haben auf unseren Seiten Verknüpfungen zu anderen Seiten im Internet
        angelegt. Auf sämtliche Links haben wir keinerlei Einfluss. Deshalb
        distanzieren wir uns hiermit ausdrücklich von allen Inhalten der
        verknüpften Seiten.
        <br />
        <br />
        Hinweis gemäß §36 Abs.1 Nr.1 des Verbraucherstreitbeilegungsgesetz
        (VSBG): Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor
        einer Verbraucherschlichtungsstelle weder bereit noch dazu verpflichtet.
      </p>
    ),
    SOCIAL_MEDIA: {
      FACEBOOK: 'https://www.facebook.com/skoda.gotha/',
      INSTAGRAM: 'https://www.instagram.com/autohaus_rainer_seyfarth/',
      YOUTUBE: 'https://www.youtube.com/@autohausrainerseyfarth4388',
    },
    ADRESSE: {
      KURZFORM: 'Seeberger Landstraße 3',
      STRASSE: 'Seeberger Landstraße',
      HAUSNUMMER: '3',
      PLZ: '99867',
      ORT: 'Gotha',
      LAND: 'Deutschland',
    },
  },
  SEYFARTH: {
    NAME: (
      <span>
        AUTOHAUS RAINER SEYFARTH GMBH <br />& CO. KG
      </span>
    ),
    PAGE_TITLE: 'Autohaus Seyfarth',
    TELEFONNUMMER: '03621 / 455210',
    TELEFONNUMMER_LINK: '+493621455210',
    FAX: '03621 / 28333',
    NOTFALL: '0173-9 33 99 99',
    NOTFALL_LINK: '+491739339999',
    EMAIL: 'info@autohaus-seyfarth.de',
    GESCHAEFTSFUEHRER: 'Ralf Dammeyer, Dirk Wojczyk',
    REGISTER_GERICHT: 'Jena',
    REGISTERNUMMER: 'HRB 504953',
    REGISTER_GERICHT_HANDEL: 'Amtsgericht Jena',
    REGISTERNUMMER_HANDEL: 'HRA 100249',
    VERWALTUNG: 'Autohaus Rainer Seyfarth Verwaltungs GmbH',
    UID: 'DE269188174',
    DATENSCHUTZERKLAERUNG_ID: '63e4117dfe838d336b0f4690',
    COOKIE_RICHTLINIE_ID: '63e40ea4fe838d336b0f468c',
    RECHTLICHES_ID: '647f6fe518df3959450f254b',
    SOCIAL_MEDIA: {
      FACEBOOK: 'https://www.facebook.com/autohausrainerseyfarth/',
      YOUTUBE: 'https://www.youtube.com/@autohausrainerseyfarth4388',
      INSTAGRAM: 'https://www.instagram.com/autohaus_rainer_seyfarth/',
    },
    ADRESSE: {
      KURZFORM: 'Seeberger Landstraße 1',
      STRASSE: 'Seeberger Landstraße',
      HAUSNUMMER: '1',
      PLZ: '99867',
      ORT: 'Gotha',
      LAND: 'Deutschland',
    },
  },
};

import classes from './Collapsible.module.css';
import { useState, useRef, useEffect } from 'react';

const Collapsible = (props) => {
  const deactivated = props.deactivated;
  const [open, setOpen] = useState(deactivated ? true : false);
  const contentRef = useRef();
  const [contentHeight, setContentHeight] = useState(0);
  // let style = null;
  // if (!deactivated) {
  //   style = open
  //     ? { maxHeight: contentRef.current.offsetHeight + 'px' }
  //     : { maxHeight: '0px' };
  // }
  const toggle = () => {
    setOpen((prev) => (deactivated ? true : !prev));
  };

  useEffect(() => {
    const currentRef = contentRef.current;
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setContentHeight(entry.target.scrollHeight);
      });
    });

    observer.observe(currentRef);
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (deactivated) setOpen(true);
    else setOpen(false);
  }, [deactivated]);

  return (
    <div style={{ maxWidth: '100%' }}>
      <span
        onClick={!deactivated ? toggle : null}
        className={`${!deactivated && classes.collapsible} ${
          open && !deactivated && classes.active
        } noSelect`}
        style={{
          cursor: 'pointer',
          maxWidth: '100%',
          hyphens: 'auto',
        }}
      >
        {props.label}
      </span>
      <div
        className={`${classes.contentParent} ${
          !deactivated && classes.moveRight
        }`}
        ref={contentRef}
        style={{
          maxHeight: open ? `${contentHeight}px` : 0,
          transitionDuration: props.slow ? '1s' : '.2s',
        }}
      >
        {/* <div className={classes.content}> */}
        {props.children}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Collapsible;

import classes from './Wohnmobile.module.css';
import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import StaffWidgets from '../About/StaffWidgets';
import PageHeading from '../UI/PageHeading';
import CaravanWidgets from '../Cars/CaravanWidget';
import Animation from '../UI/Animation';
import PageLoaderIcon from '../UI/PageLoaderIcon';

// IMPORT MUI COMPONENTS
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Paper,
  styled,
  Tab,
  // Tabs,
  ButtonGroup,
  // Stack,
} from '@mui/material/';
import Grid from '@mui/material/Unstable_Grid2';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useMediaQuery from '@mui/material/useMediaQuery';

// IMPORT DATA
import { checklisten } from '../../data/caravan';

// IMPORT HOOKS
import { useBrandFind } from '../../hooks/useFinders';
import { useInView } from 'react-intersection-observer';

// IMPORT IMAGES
import weinsberg_background from '../../assets/caravans/csm_Kopfbild_Weinsberg_Range_2_14163b49dc.webp';
import knaus_background from '../../assets/caravans/csm_Kopfbild_Knaus_BoxLife_600_MQ_9a9a1c783f.webp';
import SingleText from '../UI/SingleText';
import {
  MultiTextDeleteIcon,
  MultiTextEntry,
  MultiTextHeader,
  MultiTextTitle,
  MultiTextWrapper,
  MultiTextAdminInfo,
  MultiTextSubmitIcon,
} from '../UI/MultiText';
import PopupContext from '../../store/popup-context';
import AuthContext from '../../store/auth-context';
import usePostContent from '../../hooks/usePostContent';
import { StyledTabs, TabPanel } from '../UI/TabController';
import { globals } from '../../data/global-vars';
import ModalContext from '../../store/modal-context';
import Wohnmobilbestand from './Wohnmobilbestand';

const ColorButton = styled(MuiButton)(({ theme }) => ({
  color: '#474747',
  borderColor: '#474747',
  '&:hover': {
    borderColor: 'black',
    backgroundColor: 'lightgray',
  },
  '&.videoChecked': {
    color: 'white',
    backgroundColor: '#474747',
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: 'none',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  '&:focus': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: 'rgba(255, 255, 255, 0.9)',
  flexDirection: 'row-reverse',
  border: 'none',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  border: 'none',
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
}));

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
}

function Wohnmobile() {
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const history = useHistory();
  const { setPopupMessage } = useContext(PopupContext);
  const authCtx = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);
  const [videoLink, setVideoLink] = useState('11e4JdNvx2s');
  const { showModal } = useContext(ModalContext);
  const emptyMultiText = {
    _id: '',
    title: '',
    texts: [],
    editedBy: '',
    editedAt: '',
    createdBy: '',
    createdAt: '',
  };
  const [editMietbedingungen, setEditMietbedingungen] = useState(false);
  const [mietbedingungenn, setMietbedingungen] = useState(emptyMultiText);
  const [mietbedingungenLoaded, setMietbedingungenLoaded] = useState(false);
  const { error, postMultiTextEntry } = usePostContent();
  const [richText, setRichText] = useState(true);
  const [newTitle, setNewTitle] = useState('');
  const [newSubtitle, setNewSubtitle] = useState('');
  const [newEntry, setNewEntry] = useState('');

  function submitNewMietbedingungenHandler() {
    postMultiTextEntry(
      mietbedingungenn._id,
      { title: newTitle, subtitle: newSubtitle, text: newEntry },
      (d) => {
        if (!error) {
          setPopupMessage(
            '"' + newTitle + '" wurde erfolgreich hinzugefügt!',
            'success'
          );
          setNewTitle('');
          setNewSubtitle('');
          setNewEntry('');
          setMietbedingungen(d);
        } else {
          setPopupMessage('Es gab einen Fehler beim Hochladen!', 'error');
        }
      }
    );
  }

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const tabKey = params[0].split('/');
  useEffect(() => {
    if (tabKey[0].indexOf('p=') !== -1) {
      if (value !== 1) {
        setValue(1);
      }
    } else {
      if (value !== 0) window.scrollTo(0, 0);
      setValue(0);
    }
  }, [value, tabKey]);

  const [iFrameHeight, setIFrameHeight] = useState(-1);
  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.type === 'message') {
        const height = e.data;
        if (typeof height === 'string' && height !== null) {
          if (height.split(':')[1] !== undefined)
            setIFrameHeight(Number(height.split(':')[1].replace(/\D/g, '')));
        }
      }
    });
    return () => {
      window.removeEventListener('message', () => {});
    };
  }, []);

  const [notebookHeadingRef, inView] = useInView({
    threshold: 0.25,
    triggerOnce: true,
    trackVisibility: true,
    initialInView: false,
    fallbackInView: true,
    delay: 101,
  });
  const [notebookInView, setNotebookInView] = useState(false);
  useEffect(() => {
    if (inView) {
      setNotebookInView(true);
    }
  }, [inView]);

  function getCorrespondingUrl(index) {
    switch (index) {
      case 0:
        return 'vermietung';

      case 1:
        return 'p=1';

      default:
        return 'vermietung';
    }
  }

  function handleTabChange(event, index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/` + url);
  }

  function handleChangeTabIndex(index) {
    setValue(index);
    const url = getCorrespondingUrl(index);
    history.push(`/${brand}/${globals.PAGES.WOHNMOBILE.url}/` + url);
  }

  const isVerticalOrientation = useMediaQuery('(max-width:560px)');
  const isHeaderSpaceNeeded = useMediaQuery('(max-width:599px)');

  return (
    <div className="container">
      <PageHeading title="Wohnmobile mieten und kaufen">
        Wohnmobile {value === 0 ? 'mieten' : 'kaufen'}
      </PageHeading>
      <Grid xs={12} container>
        <Grid xs={12} className={`${classes.tabBar}`}>
          <StyledTabs
            value={value}
            indicatorColor={brandObject.primeColor}
            onChange={handleTabChange}
            sx={{ marginBottom: '20px' }}
            variant="fullWidth"
          >
            <Tab label="Vermietung" />
            <Tab label="Verkauf" />
          </StyledTabs>
        </Grid>
        <SwipeableViews
          axis="x"
          index={value}
          onChangeIndex={handleChangeTabIndex}
          enableMouseEvents={editMietbedingungen ? false : true}
          style={{
            marginBottom: '40px',
            paddingLeft: 'calc(env(safe-area-inset-left))',
            paddingRight: 'calc( env(safe-area-inset-right))',
          }}
          className="noSelect"
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            style={{
              overflow: 'hidden',
            }}
          >
            <Grid
              xs={12}
              className={`container ${classes.section}`}
              style={{ marginTop: 0 }}
              id="termin-buchen"
            >
              <div
                style={{
                  position: 'relative',
                }}
              >
                {iFrameHeight <= 0 && (
                  <div
                    style={{
                      backgroundColor: 'white',
                      zIndex: 90,
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <PageLoaderIcon isLoading={iFrameHeight <= 0} />
                  </div>
                )}
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    aspectRatio: iFrameHeight <= 0 && '1205 / 826',
                    minHeight: '' + iFrameHeight + 'px',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      width: '100%',
                      top: 0,
                      left: 0,
                    }}
                  >
                    <iframe
                      src="https://wl.be.rentandtravel.de?locale=de-DE&amp;station=4588&amp;vehicleType=motorhome"
                      width="100%"
                      style={{
                        height: '' + iFrameHeight + 'px',
                        borderRadius: '4px',
                        border: '0px',
                      }}
                      data-ll-status="loading"
                      className="entered loading"
                      title="womo-iframe"
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              container
              className={`container ${classes.section}`}
              sx={{ padding: '10px' }}
              xs={12}
            >
              <Grid
                xs={12}
                lg={6}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Item>
                  <h2 className={classes.paperHeading}>
                    <SingleText id="63e40a7cfe838d336b0f468a" field="title" />
                  </h2>
                  <div className={` ${classes.infoText} ${classes.leftText}`}>
                    <SingleText id="63e40a7cfe838d336b0f468a" field="text" />
                  </div>
                  <h4 className={`${classes.leftText}`}>
                    Sie können wählen zwischen:
                  </h4>
                  <div className={classes.modelWrapper}>
                    <CaravanWidgets />
                  </div>
                  <h4 className={`${classes.leftText}`}>
                    Ihre Ansprechpartner:
                  </h4>
                  <div className={classes.contactWrapper}>
                    <StaffWidgets dep="caravans" noBackground />
                  </div>
                </Item>
              </Grid>
              <Grid
                xs={12}
                lg={6}
                sx={{
                  padding: '20px 0',
                }}
                onClick={() => {
                  if (authCtx.isAdmin) {
                    showModal(
                      'Bearbeiten',
                      null,
                      <div style={{ paddingBottom: '10px' }}>
                        <SingleText
                          id="63f4fc92acdc38c4822692ea"
                          field="title"
                        />
                        <br />
                        <SingleText
                          id="63f4fc92acdc38c4822692ea"
                          field="text"
                        />
                      </div>
                    );
                  }
                }}
              >
                <div className={classes.notebookWrapper}>
                  <div className={classes.notebookPaper}>
                    <div className={classes.notebookLines}>
                      <div
                        className={classes.notebookText}
                        ref={notebookHeadingRef}
                      >
                        <h2 className={classes.notebookHeading}>
                          <SingleText
                            id="63f4fc92acdc38c4822692ea"
                            field="title"
                            noEdit
                            typewriterOptions={{
                              autoStart: notebookInView,
                              loop: false,
                              delay: 1,
                              cursor: '',
                            }}
                          />
                        </h2>
                        <br />
                        <SingleText
                          id="63f4fc92acdc38c4822692ea"
                          field="text"
                          noEdit
                          typewriterOptions={{
                            autoStart: notebookInView,
                            loop: false,
                            delay: 0.1,
                            cursor: '',
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className={`${classes.holes} ${classes.holeTop}`}
                    ></div>
                    <div
                      className={`${classes.holes} ${classes.holeMiddle}`}
                    ></div>
                    <div
                      className={`${classes.holes} ${classes.holeBottom}`}
                    ></div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              xs={12}
              className={` ${classes.section} container`}
              id="mietbedingungen"
            >
              <MultiTextWrapper
                id="63ef598b2253202af1779c28"
                setEdit={setEditMietbedingungen}
                applyData={setMietbedingungen}
                setLoaded={setMietbedingungenLoaded}
              >
                {mietbedingungenLoaded && (
                  <>
                    <h1 className={classes.sectionHeading}>
                      <MultiTextHeader
                        value={mietbedingungenn}
                        setValue={setMietbedingungen}
                        edit={editMietbedingungen}
                        setEdit={setEditMietbedingungen}
                        richText={richText}
                        setRichText={setRichText}
                      />
                      <MultiTextAdminInfo value={mietbedingungenn} />
                    </h1>
                    <div className={classes.mietWrapper}>
                      <img
                        src={weinsberg_background}
                        alt="Wohnmobil auf einem Parkplatz vor einer Berglandschaft"
                        className={classes.mietBackground}
                        loading="lazy"
                      />
                      <div className={classes.mietOverlay}>
                        <div className={classes.mietContainer}>
                          {mietbedingungenn.texts.map((b, i) => {
                            return (
                              <Animation
                                key={i}
                                sx={{
                                  paddingLeft:
                                    'calc(env(safe-area-inset-left))',
                                  paddingRight:
                                    'calc( env(safe-area-inset-right))',
                                }}
                              >
                                <Accordion
                                  expanded={expanded === 'panel' + i}
                                  onChange={handleChangeAccordion('panel' + i)}
                                  className={classes.accordion}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMoreIcon
                                        className={classes.accordionExpandIcon}
                                      />
                                    }
                                  >
                                    <Grid
                                      container
                                      xs={12}
                                      sx={{
                                        textAlign: 'left',
                                      }}
                                      spacing={0.6}
                                    >
                                      <Grid xs={12} sm={12} md={5} lg={5}>
                                        <b>
                                          <MultiTextTitle
                                            id={mietbedingungenn._id}
                                            value={b}
                                            setValue={setMietbedingungen}
                                            edit={editMietbedingungen}
                                            setEdit={setEditMietbedingungen}
                                          />
                                        </b>
                                      </Grid>
                                      <Grid xs={12} sm={12} md={6.5}>
                                        <MultiTextTitle
                                          id={mietbedingungenn._id}
                                          value={b}
                                          setValue={setMietbedingungen}
                                          edit={editMietbedingungen}
                                          setEdit={setEditMietbedingungen}
                                          subtitle
                                          sx={{
                                            whiteSpace: {
                                              xs: 'nowrap',
                                              md: 'unset',
                                            },
                                          }}
                                        />
                                      </Grid>
                                      <Grid
                                        xs={0.5}
                                        sx={{
                                          textAlign: 'right',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-end',
                                        }}
                                      >
                                        <MultiTextDeleteIcon
                                          id={mietbedingungenn._id}
                                          value={b}
                                          setValue={setMietbedingungen}
                                          edit={editMietbedingungen}
                                        />
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    className={classes.accordionText}
                                  >
                                    <MultiTextEntry
                                      id={mietbedingungenn._id}
                                      value={b}
                                      setValue={setMietbedingungen}
                                      edit={editMietbedingungen}
                                      setEdit={setEditMietbedingungen}
                                      richText={richText}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </Animation>
                            );
                          })}
                          {editMietbedingungen && authCtx.isAdmin && (
                            <Animation>
                              <Accordion
                                expanded={expanded === 'panel0'}
                                onChange={handleChangeAccordion('panel0')}
                                className={classes.accordion}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      className={classes.accordionExpandIcon}
                                    />
                                  }
                                >
                                  <Grid
                                    container
                                    xs={12}
                                    sx={{
                                      textAlign: 'left',
                                    }}
                                    spacing={0.6}
                                  >
                                    <Grid xs={5}>
                                      <MultiTextTitle
                                        id={mietbedingungenn._id}
                                        value={newTitle}
                                        setValue={setNewTitle}
                                        edit={editMietbedingungen}
                                        setEdit={setEditMietbedingungen}
                                        new
                                      />
                                    </Grid>
                                    <Grid xs={6.5}>
                                      <MultiTextTitle
                                        id={mietbedingungenn._id}
                                        value={newSubtitle}
                                        setValue={setNewSubtitle}
                                        edit={editMietbedingungen}
                                        setEdit={setEditMietbedingungen}
                                        subtitle
                                        new
                                      />
                                    </Grid>
                                    <Grid
                                      xs={0.5}
                                      sx={{
                                        textAlign: 'right',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                      }}
                                    >
                                      <MultiTextSubmitIcon
                                        submit={submitNewMietbedingungenHandler}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                <AccordionDetails
                                  className={classes.accordionText}
                                >
                                  <MultiTextEntry
                                    id={mietbedingungenn._id}
                                    value={newEntry}
                                    setValue={setNewEntry}
                                    edit={editMietbedingungen}
                                    setEdit={setEditMietbedingungen}
                                    richText={richText}
                                    new
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </Animation>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </MultiTextWrapper>
            </Grid>
            <Grid
              xs={12}
              container
              className={`container ${classes.section}`}
              id="checklisten"
              sx={{ marginBottom: '40px' }}
            >
              <Grid container xs={12} spacing={2}>
                <Grid
                  xs={12}
                  sm={6}
                  md={8}
                  lg={8}
                  xl={8}
                  style={{ marginBottom: '40px' }}
                  container
                >
                  <Grid xs={12}>
                    <h1 className={classes.sectionHeading}>Checklisten</h1>
                  </Grid>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{ height: '100%', paddingBottom: '20px' }}
                  >
                    {checklisten.map((c, index) => (
                      <Grid xs={6} key={index}>
                        <div className={classes.checklistWrapper}>
                          <Animation dir="scaleIn">
                            <a
                              href={c.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={c.image}
                                alt={'Symbolbild: ' + c.title}
                                className={classes.checklistImage}
                                loading="lazy"
                              />
                              <br />
                              <span className={classes.checklistTitle}>
                                {c.title}
                              </span>
                            </a>
                          </Animation>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Grid xs={12} sx={{ margin: 0 }}>
                    <h1
                      className={classes.sectionHeading}
                      style={{
                        // marginTop: isHeaderSpaceNeeded ? '20px' : '0px',
                        margin: 0,
                      }}
                      orientation={
                        isVerticalOrientation ? 'vertical' : 'horizontal'
                      }
                    >
                      Mehr Infos
                    </h1>
                  </Grid>
                  <Grid
                    container
                    style={{
                      height: '100%',
                      paddingBottom: isHeaderSpaceNeeded ? '80px' : '60px',
                    }}
                  >
                    <div className={classes.infoItemContainer}>
                      <Grid xs={12} className={classes.infoItemWrapper}>
                        <a
                          href={
                            'https://autohaus-seyfarth.de/pdf/caravan/knaus-tabbert-packliste-campingurlaub.pdf'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className={classes.infoItem}>
                            <Animation dir="left">
                              Packliste Campingurlaub
                            </Animation>
                          </div>
                        </a>
                      </Grid>
                      <Grid xs={12} className={classes.infoItemWrapper}>
                        <a
                          href={
                            'https://autohaus-seyfarth.de/pdf/caravan/knaus-tabbert-tipps-fuer-reisen-in-europa.pdf'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className={classes.infoItem}>
                            <Animation dir="right">Tipps und Tricks</Animation>
                          </div>
                        </a>
                      </Grid>
                      <Grid xs={12} className={classes.infoItemWrapper}>
                        <a
                          href={
                            getMobileOperatingSystem() === 'ios'
                              ? 'https://apps.apple.com/de/app/california-on-tour/id1480369449'
                              : 'https://play.google.com/store/apps/details?id=de.volkswagen.CaliforniaOnTour&hl=gsw&gl=US'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className={classes.infoItem}>
                            <Animation dir="left">
                              California on Tour App
                            </Animation>
                          </div>
                        </a>
                      </Grid>
                      <Grid xs={12} className={classes.infoItemWrapper}>
                        <a
                          href="https://www.alpacacamping.de/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className={classes.infoItem}>
                            <Animation dir="right">
                              Campingplatz finden
                            </Animation>
                          </div>
                        </a>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} container className={` ${classes.section} container`}>
              <Grid xs={12}>
                <h1 className={classes.sectionHeading}>Einweisungsvideos</h1>
              </Grid>
              <Grid
                xs={12}
                sx={{
                  position: 'relative',
                }}
                className={classes.videoContainer}
              >
                <Animation dir="blurIn">
                  <img
                    src={knaus_background}
                    alt="Wohnmobil auf einer Brücke mit Blick in die Landschaft"
                    className={classes.backgroundImageIFrame}
                    loading="lazy"
                  />
                </Animation>
                <div className={classes.iFrameContainer}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      margin: '10px 0',
                      width: '100%',
                    }}
                  >
                    <Animation dir="right">
                      <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        style={{ backgroundColor: 'white' }}
                        orientation={
                          isVerticalOrientation ? 'vertical' : 'horizontal'
                        }
                        className={classes.buttonGroupVert}
                      >
                        <ColorButton
                          onClick={() => setVideoLink('11e4JdNvx2s')}
                          className={`${
                            videoLink === '11e4JdNvx2s' && 'videoChecked'
                          }`}
                        >
                          KNAUS BoxLife
                        </ColorButton>
                        <ColorButton
                          className={`${
                            videoLink === 'dql7YJk3_zs' && 'videoChecked'
                          }`}
                          onClick={() => setVideoLink('dql7YJk3_zs')}
                        >
                          WEINSBERG CaraTour
                        </ColorButton>
                        <ColorButton
                          onClick={() => setVideoLink('_R7JLQOZWfQ')}
                          className={`${
                            videoLink === '_R7JLQOZWfQ' && 'videoChecked'
                          }`}
                        >
                          WEINSBERG CaraSuite
                        </ColorButton>
                      </ButtonGroup>
                    </Animation>
                  </Box>
                  <Animation dir="left">
                    <iframe
                      className={classes.posIframe}
                      src={'https://www.youtube.com/embed/' + videoLink}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      style={{}}
                    />
                  </Animation>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            id="wohnmobilverkauf"
          >
            <Wohnmobilbestand />
          </TabPanel>
        </SwipeableViews>
      </Grid>
      <Grid xs={12} sx={{ paddingBottom: '40px' }}>
        <StaffWidgets dep="caravans" />
      </Grid>
    </div>
  );
}

export default Wohnmobile;

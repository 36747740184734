import { useEffect, useRef, useState } from 'react';

// IMPORT OWN COMPONENTS
import StaffWidgets from '../About/StaffWidgets';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import ShortFilter from '../Filter/ShortFilter';
import DisplayOptions from '../Filter/DisplayOptions';
import useFetchContent from '../../hooks/useFetchContent';
import { CarPreview, CarPreviewSkeleton } from '../Cars/Cars';
import { useHistory, useParams } from 'react-router-dom';
import Collapsible from '../UI/Collapsible';
import SingleText from '../UI/SingleText';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useBrandFind } from '../../hooks/useFinders';
import { globals } from '../../data/global-vars';

function Neuwagenbestand() {
  const standardLimit = 20;
  const params = useParams();
  const history = useHistory();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const { error, getCarsFiltered } = useFetchContent();
  const [data, setData] = useState({ docs: [], hasNextPage: true });
  const [carsLoaded, setCarsLoaded] = useState(false);
  const localFilter =
    localStorage.getItem('newCarFilter') &&
    !localStorage.getItem('newCarFilter') !== 'undefined' &&
    JSON.parse(localStorage.getItem('newCarFilter'));

  const [filter, setFilter] = useState(
    localFilter?.filter ||
      history.location.state?.filter || {
        isNew: true,
        brand: [brandObject.carzillaBrand],
        model: [],
        baseColor: [],
        category: [],
        fuelType: [],
        transmissionType: [],
        feature: [],
      }
  );
  const unfilteredFilter =
    localFilter?.unfilteredFilter ||
    history.location.state?.unfilteredFilter ||
    {};
  const [sort, setSort] = useState(
    localStorage.getItem('sort') &&
      !localStorage.getItem('sort') !== 'undefined'
      ? localStorage.getItem('sort').replace(/"/g, '')
      : ''
  );

  const [displayOptions, setDisplayOptions] = useState({
    transmissionType: [true, 'Kraftstoffart'],
    km: [true, 'Kilometer'],
    color: [true, 'Farbe'],
    enkvFuelType: [true, 'Kraftstoff'],
    kW: [true, 'Leistung'],
    firstRegistration: [false, 'Erstzulassung'],
    nextHU: [false, 'nächste HU'],
    yearOfConstruction: [false, 'Baujahr'],
  });
  const [priceOptions, setPriceOptions] = useState([]);
  const displayOptionsOrder = [
    'transmissionType',
    'km',
    'color',
    'enkvFuelType',
    'kW',
    'firstRegistration',
    'nextHU',
    'yearOfConstruction',
  ];

  // 1. check if query params in url
  useEffect(() => {
    if (
      params[0] === 'bestand' ||
      isNaN(params[0].split('/')[1]?.split('=')[1])
    ) {
      history.push(`/${brand}/${globals.PAGES.NEUWAGEN.url}/bestand/p=${1}`);
    }
  }, [params[0]]);

  // 2. first fetch
  useEffect(() => {
    const filteredFilter = Object.entries(filter).reduce(
      (acc, [key, value]) => {
        if (!Array.isArray(value) || value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
    const urlPage = Number(params[0].split('/')[1]?.split('=')[1]) || 1;
    if (data.docs.length === 0)
      getCarsFiltered(
        {
          page: 1,
          limit: urlPage * standardLimit,
          sort: sort === '' ? '_sortby=model&_sortdir=asc' : sort,
        },
        filteredFilter,
        (d) => {
          if (!error) {
            setData((dd) => {
              return { ...d, docs: [...dd.docs, ...d.docs] };
            });
            setCarsLoaded(true);
          }
        }
      );
  }, [filter, sort]);

  // 3. update url on scroll
  function fetchMoreData() {
    const urlPage = Number(params[0].split('/')[1]?.split('=')[1]) || 1;
    if (data.docs.length < data.totalDocs) localStorage.removeItem('detailId');
    history.push(
      `/${brand}/${globals.PAGES.NEUWAGEN.url}/bestand/p=${
        urlPage + (data.docs.length < data.totalDocs ? 1 : 0)
      }`
    );
  }

  // 4. fetch if url changed
  useEffect(() => {
    if (data.docs.length > 1) {
      const filteredFilter = Object.entries(filter).reduce(
        (acc, [key, value]) => {
          if (!Array.isArray(value) || value.length > 0) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      const urlPage = params[0].split('/')[1]?.split('=')[1];
      if (data.hasNextPage)
        getCarsFiltered(
          {
            page: urlPage,
            limit: standardLimit,
            sort: sort === '' ? '_sortby=model&_sortdir=asc' : sort,
          },
          filteredFilter,
          (d) => {
            if (!error) {
              setData((dd) => {
                return { ...d, docs: [...dd.docs, ...d.docs] };
              });
            }
          }
        );
    }
  }, [params]);

  // 5. change the filter
  function setFilterHandler(pFilter) {
    setCarsLoaded(false);
    pFilter && setFilter(pFilter);
    localStorage.removeItem('detailId');
    setData({ docs: [], hasNextPage: true });
    history.push(`/${brand}/${globals.PAGES.NEUWAGEN.url}/bestand/p=${1}`);
  }

  // 6. change sort
  function setSortHandler(sortKey) {
    setSort(sortKey);
    setCarsLoaded(false);
    localStorage.removeItem('detailId');
    setData({ docs: [], hasNextPage: true });
    history.push(`/${brand}/${globals.PAGES.NEUWAGEN.url}/bestand/p=${1}`);
  }

  // 7. scroll to detailId
  useEffect(() => {
    const targetDiv = document.getElementById(localStorage.getItem('detailId'));
    if (targetDiv) {
      targetDiv.scrollIntoView(true);
    }
  }, [carsLoaded]);

  const gridRef = useRef(null);
  const [gridWidth, setGridWidth] = useState(0);

  useEffect(() => {
    const gridElement = gridRef.current;

    if (!gridElement) return;

    const handleResize = () => {
      const newWidth = gridElement.offsetWidth;
      if (newWidth !== gridWidth) {
        setGridWidth(newWidth);
      }
    };

    const handleWindowResize = () => {
      handleResize();
    };

    const observer = new IntersectionObserver(handleResize, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    observer.observe(gridElement);

    window.addEventListener('resize', handleWindowResize);

    return () => {
      observer.unobserve(gridElement);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [gridWidth]);

  return (
    <Grid xs={12} container>
      <Grid
        xs={12}
        container
        className="container"
        sx={{
          paddingLeft: 'calc(env(safe-area-inset-left))',
          paddingRight: 'calc( env(safe-area-inset-right))',
        }}
      >
        <ShortFilter
          filter={filter}
          unfiltered={unfilteredFilter}
          setFilter={setFilterHandler}
          newCars
        />
        <Grid
          xs={12}
          sx={{
            margin: '40px 0',
          }}
        >
          <Collapsible
            label={<h1 className="sectionHeading">Anzeigeoptionen</h1>}
          >
            <div style={{ width: 'calc(100% - 10px)', paddingTop: '10px' }}>
              <DisplayOptions
                options={displayOptions}
                setOptions={setDisplayOptions}
                order={displayOptionsOrder}
                setPriceOptions={setPriceOptions}
                sort={sort}
                setSort={setSort}
                setSortHandler={setSortHandler}
                newCars
              />
            </div>
          </Collapsible>
        </Grid>
        <Grid xs={12} id="triggerTop"></Grid>
        {data.docs.length < 1 && carsLoaded && (
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ maxWidth: '800px', padding: '0 10px' }}>
              <SingleText id="646a230bee7476ec44dcd8fc" field="text" />
            </div>
          </Grid>
        )}
        <Grid xs={12} container ref={gridRef} id="liste">
          <InfiniteScroll
            dataLength={data.docs.length}
            next={fetchMoreData}
            hasMore={data.hasNextPage}
            loader={
              (data.docs.length > 0 || !carsLoaded) && (
                <Grid xs={12} container>
                  {Array.from({ length: 4 }).map((v, i) => {
                    return (
                      <Grid xs={12} md={6} key={i} sx={{ padding: '10px' }}>
                        <CarPreviewSkeleton />
                      </Grid>
                    );
                  })}
                </Grid>
              )
            }
            style={{
              width: gridWidth + 'px',
            }}
            // endMessage
          >
            <Grid xs={12} container>
              {data.docs.map((c, i) => {
                return (
                  <Grid
                    xs={12}
                    md={6}
                    key={i}
                    sx={{ padding: '10px' }}
                    container
                    onClick={() => {
                      localStorage.setItem(
                        'carsLoadedArray',
                        JSON.stringify({
                          ...data,
                          docs: data.docs.map((c) => {
                            return { carzillaId: c.carzillaId };
                          }),
                        })
                      );
                    }}
                  >
                    <Grid
                      xs={12}
                      container
                      style={{
                        borderRadius: '20px',
                        padding: '10px',
                        backgroundColor: 'var(--light-contrast)',
                      }}
                      id={c.carzillaId}
                    >
                      <CarPreview
                        data={c}
                        carsLoadedArray={data}
                        displayOptions={displayOptions}
                        displayOptionsOrder={displayOptionsOrder}
                        priceOptions={priceOptions}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </InfiniteScroll>
        </Grid>
      </Grid>

      <Grid xs={12} sx={{ marginTop: '40px' }}>
        {/* brand of page should be shown first */}
        {filter.brand.includes(brandObject.carzillaBrand) && (
          <StaffWidgets
            dep="newCarSales"
            brand={brandObject.url}
            showBrandTitle
          />
        )}
        {/* rest of brands */}
        {Object.values(globals.BRANDS).map((obj, i) => {
          if (
            filter.brand.includes(obj.carzillaBrand) &&
            obj.carzillaBrand !== brandObject.carzillaBrand
          )
            return (
              <StaffWidgets
                key={i}
                dep="newCarSales"
                brand={obj.url}
                showBrandTitle
              />
            );
        })}
      </Grid>
    </Grid>
  );
}

export default Neuwagenbestand;

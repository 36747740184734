import classes from './Backdrop.module.css';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';

function Backdrop(props) {
  function close() {
    props.onClick();
  }
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        close();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  });

  return ReactDOM.createPortal(
    <div
      className={`${props.show && classes.backdrop} ${
        !props.show && classes.default
      }`}
    >
      <div
        onClick={props.onClick}
        className={`${props.show && classes.backdropOverlay} ${
          !props.show && classes.default
        }`}
      ></div>
    </div>,
    document.getElementById('backdrop-root')
  );
}

export default Backdrop;

import { useEffect, useState, useContext, Fragment } from 'react';
import classes from './InternalNews.module.css';

// IMPORT OWN COMPONENTS
import PageLoaderIcon from '../UI/PageLoaderIcon';
import Button from '../UI/Button';

// IMPORT HOOKS, CONTEXTS AND FUNCTIONS
import AuthContext from '../../store/auth-context';
import usePostContent from '../../hooks/usePostContent';
import useFetchContent from '../../hooks/useFetchContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import PopupContext from '../../store/popup-context';
import ModalContext from '../../store/modal-context';
import dateformat from 'dateformat';

// IMPORT MUI AND FONTAWESOME COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@mui/material';
import styled from '@mui/material/styles/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';

// STYLE MUI COMPONENTS
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

export const InternalNewsForm = (props) => {
  const { setPopupMessage } = useContext(PopupContext);
  const { isLoading, error, postInternalNews } = usePostContent();

  // declare data vars
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [message, setMessage] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const { error: fetchError, getAllUsers } = useFetchContent();

  useEffect(() => {
    getAllUsers((d) => {
      if (!fetchError) setUsers(d);
    });
  }, []);

  // change selected group for which the internal news is displayed
  const handleToggle = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newsItem = {
      title,
      subtitle,
      text: message,
      level: selectedLevel,
      assignedUsers: selectedIds,
    };
    postInternalNews(newsItem, (d) => {
      props.patchInternalNews(d);
      setPopupMessage(
        'Interne Nachricht: "' + d.title + '" hochgeladen.',
        'success'
      );
    });
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{ width: '100%', overflow: 'hidden', paddingBottom: '20px' }}
    >
      <StyledBox>
        {isLoading ? (
          <PageLoaderIcon isLoading={isLoading} />
        ) : (
          <Grid xs={12} container rowSpacing={2}>
            <Grid xs={12}>{error && errorMessage}</Grid>
            <Grid xs={12}>
              <TextField
                label="Titel"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Subtitel"
                variant="outlined"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Nachricht"
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                required
                fullWidth
              />
            </Grid>
            <Grid xs={12}>
              <FormControl fullWidth>
                <InputLabel id="level-label">Level</InputLabel>
                <Select
                  labelId="level-label"
                  id="level-select"
                  value={selectedLevel}
                  label="Level"
                  onChange={(e) => setSelectedLevel(e.target.value)}
                  variant="outlined"
                >
                  <MenuItem value={'info'}>Blau</MenuItem>
                  <MenuItem value={'danger'}>Rot</MenuItem>
                  <MenuItem value={'warning'}>Orange</MenuItem>
                  <MenuItem value={'success'}>Grün</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl required>
                <FormGroup
                  style={{
                    width: '100%',
                  }}
                >
                  <Grid
                    xs={12}
                    container
                    spacing={0}
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    {users.map((u, i) => {
                      return (
                        <Grid xs={6} key={i}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedIds.includes(u._id)}
                                onChange={() => handleToggle(u._id)}
                              />
                            }
                            label={u.username}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <Button
                color="green"
                type="submit"
                disabled={title === '' || message === '' ? true : false}
              >
                Veröffentlichen
              </Button>
            </Grid>
          </Grid>
        )}
      </StyledBox>
    </form>
  );
};

function InternalNews(props) {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteInternalNews } = useDeleteContent();

  // declare data variables
  const n = props.data;

  // fetch user
  const { isLoading, error, getSingleUserById } = useFetchContent();
  const [user, setUser] = useState({ username: '', role: '' });
  useEffect(() => {
    if (authCtx.isAdmin) {
      getSingleUserById(n.editedBy, (d) => {
        setUser({ username: d.username, role: d.role });
      });
    }
  }, [getSingleUserById, n.editedBy, authCtx.isAdmin]);

  function deleteSuggestedCar() {
    showModal(
      n.title,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            closeModal();
            deleteInternalNews(n._id, (d) => {
              if (!deleteError) {
                setPopupMessage(
                  'Interne Nachricht: "' + n.title + '" gelöscht.',
                  'success'
                );
                props.deleteInternalNews(n._id);
              } else {
                setPopupMessage(
                  'Fehler beim Löschen der internen Nachricht!',
                  'error'
                );
              }
            });
          }}
          color="red"
        >
          WIRKLICH LÖSCHEN
        </Button>
      </div>
    );
  }

  let levelColor;
  switch (n.level) {
    case 'danger':
      levelColor = { borderLeft: '2px solid var(--danger-color)' };
      break;

    case 'warning':
      levelColor = { borderLeft: '2px solid var(--warning-color)' };
      break;

    case 'success':
      levelColor = { borderLeft: '2px solid var(--success-color)' };
      break;

    case 'info':
      levelColor = { borderLeft: '2px solid var(--info-color)' };
      break;

    default:
      levelColor = { borderLeft: '2px solid var(--info-color)' };
      break;
  }

  return (
    <Grid
      xs={12}
      className={classes.internalNewsContainer}
      style={{ padding: '10px', margin: '10px 0', ...levelColor }}
    >
      {authCtx.isAdmin && (
        <FontAwesomeIcon
          icon={faCircleMinus}
          className={classes.deleteIcon}
          onClick={deleteSuggestedCar}
        />
      )}
      <div>
        <span className={classes.intNewsTitle}>{n.title}</span>
        <br />
        <span className={classes.intNewsSubtitle}>{n.subtitle}</span>
      </div>
      <div className={classes.intNewsText}>{n.text}</div>
      {authCtx.isAdmin && !error && !isLoading && (
        <Fragment>
          <br />
          <span
            className="adminInfo"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {user.username +
              ' (' +
              user.role +
              ') ' +
              ', ' +
              dateformat(new Date(n.createdAt), 'dd.mm.yyyy') +
              ' um ' +
              dateformat(new Date(n.createdAt), 'H:MM:ss') +
              ' Uhr'}
          </span>
        </Fragment>
      )}
    </Grid>
  );
}

export default InternalNews;

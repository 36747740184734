// import classes from './imprint.module.css';
import { Fragment, useEffect } from 'react';

// IMPORT OWN COMPONENTS
import PageHeading from '../UI/PageHeading';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';

function Imprint() {
  const params = useParams();
  const brand = params.brand;
  const brandHouse = useBrandFind(brand, 'house');
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto';
  }, []);
  return (
    <Fragment>
      <PageHeading title="Impressum">Impressum</PageHeading>
      <Grid
        xs={12}
        container
        className="container"
        sx={{
          textAlign: 'left',
          color: 'var(--text-color)',
          fontWeight: 200,
          paddingTop: 0,
        }}
      >
        <Grid xs={12} md={6}>
          <p>
            <b>{brandHouse.NAME}</b>
            <br />
            {brandHouse.ADRESSE.KURZFORM} <br />
            {brandHouse.ADRESSE.PLZ + ' ' + brandHouse.ADRESSE.ORT}
          </p>
          <p>
            Telefon: {brandHouse.TELEFONNUMMER} <br />
            Telefax: {brandHouse.FAX}
            <br />
            E-Mail:{' '}
            <a href={`mailto:${brandHouse.EMAIL}`}>{brandHouse.EMAIL}</a>
          </p>
        </Grid>
        <Grid xs={12} md={6}>
          <p>
            <b>Vertreten durch:</b>
            <br />
            {brandHouse.VERWALTUNG}
            <br />
            Geschäftsführer: {brandHouse.GESCHAEFTSFUEHRER}
            <br />
            {brandHouse.REGISTER_GERICHT &&
              'Registergericht: ' + brandHouse.REGISTER_GERICHT}
            <br />
            {brandHouse.REGISTERNUMMER &&
              'Registernummer: ' + brandHouse.REGISTERNUMMER}
          </p>
        </Grid>
        <Grid xs={12} md={6}>
          <p>
            <b>Registereintrag:</b>
            <br />
            Eingetragen im Handelsregister.
            <br />
            Registergericht: {brandHouse.REGISTER_GERICHT_HANDEL}
            <br />
            Registernummer: {brandHouse.REGISTERNUMMER_HANDEL}
            <br />
            Sitz: {brandHouse.ADRESSE.PLZ + ' ' + brandHouse.ADRESSE.ORT}
          </p>
        </Grid>
        <Grid xs={12} md={6}>
          <p>
            <b>Umsatzsteuer-ID:</b> <br />
            Umsatzsteuer-Identifikationsnummer nach §27a Umsatzsteuergesetz:
            {' ' + brandHouse.UID}
          </p>
          <p>Quelle: Juraforum.de</p>
        </Grid>
        <Grid xs={12}>{brandHouse.IMPRINT_NODE}</Grid>
      </Grid>
    </Fragment>
  );
}

export default Imprint;

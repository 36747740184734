import { Fragment, useContext, useEffect, useState } from 'react';
import useFetchContent from '../../hooks/useFetchContent';
import classes from './Events.module.css';
import dateformat from 'dateformat';
import ModalContext from '../../store/modal-context';
import AuthContext from '../../store/auth-context';
import Button from '../UI/Button';
import {
  faCancel,
  faCirclePlus,
  faCopy,
  faList,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import { Box, Skeleton, TextField } from '@mui/material';
import usePatchContent from '../../hooks/usePatchContent';
import usePostContent from '../../hooks/usePostContent';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopupContext from '../../store/popup-context';
import useDeleteContent from '../../hooks/useDeleteContent';
// import { arrayIncludes } from '@mui/x-date-pickers/internals/utils/utils';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers/DateTimeField';
import { deDE } from '@mui/x-date-pickers/locales';
import 'dayjs/locale/de';
import Animation from '../UI/Animation';
import SingleText from '../UI/SingleText';
import useHttp from '../../hooks/use-http';
const parse = require('html-react-parser'); // must be last import

// STYLE MUI COMPONENT
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
});

// error message for adding a new shortcut
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

function EventsForm(props) {
  const authCtx = useContext(AuthContext);
  const {
    isLoading: isUploading,
    error: uploadError,
    postEvent,
  } = usePostContent();
  const {
    isLoading: isUpdating,
    error: updateError,
    patchEvent,
  } = usePatchContent();
  const { setPopupMessage } = useContext(PopupContext);
  const { updateHeight } = useContext(ModalContext);

  const [e, sE] = useState(props.selectedEvent);
  useEffect(() => {
    sE(props.selectedEvent);
  }, [props.selectedEvent]);

  const [add, setAdd] = useState(e.edit !== true);
  const [title, setTitle] = useState(e.title);
  const [location, setLocation] = useState(e.location);
  const [text, setText] = useState(e.text);
  const [date, setDate] = useState(dayjs(e.date));

  useEffect(() => {
    setTitle(e.title);
    setLocation(e.location);
    setText(e.text);
    setDate(dayjs(e.date));
    setAdd(e.edit !== true);
  }, [e]);

  function handleSubmit() {
    const event = {
      title: title,
      location: location,
      date: date,
      text: text,
    };
    if (add) {
      postEvent(event, (d) => {
        if (!uploadError) {
          props.patchEventsArray(d, 'add');
          setPopupMessage('Event erfolgreich hinzugefügt!', 'success');
        }
      });
    } else {
      patchEvent(e._id, event, (d) => {
        if (!updateError) {
          props.patchEventsArray(d, 'edit');
          setPopupMessage('Event erfolgreich bearbeitet!', 'success');
        }
      });
    }
  }

  const { sendRequest } = useHttp();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('upload', file);
            sendRequest(
              {
                url: '/api/v1/news/inlineimage', /// ### UNCOMPLETE #### recycle other inline image route, because no backend route exists
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + authCtx.token,
                },
                body: formData,
                noJSON: true,
              },
              (data) => {
                resolve({
                  default: `https://autohaus-seyfarth.de${data.url}`,
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  if (isUploading || isUpdating) {
    return <PageLoaderIcon isLoading={isUpdating || isUploading} />;
  } else
    return (
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <StyledBox>
          {updateError && errorMessage}
          {uploadError && errorMessage}
          <TextField
            label="Titel"
            variant="outlined"
            fullWidth
            className={classes.addInput}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <div style={{ width: '100%' }}>
            <CKEditor
              editor={Editor}
              data={text}
              onChange={(e, editor) => {
                const text = editor.getData();
                setText(text);
                updateHeight();
              }}
              config={{
                // plugins: [ImageResize],
                extraPlugins: [uploadPlugin],
              }}
            />
          </div>
          <TextField
            label="Veranstaltungsort"
            variant="outlined"
            fullWidth
            className={classes.addInput}
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
            adapterLocale="de"
          >
            <DateTimeField
              label="Datum"
              value={date}
              onChange={(newValue) => setDate(newValue)}
              fullWidth
            />
          </LocalizationProvider>
          <Button
            color="green"
            icon={add ? faCirclePlus : faPencil}
            type="submit"
            disabled={title === '' ? true : false}
          >
            {add ? 'Hinzufügen' : 'Bearbeiten'}
          </Button>
        </StyledBox>
      </form>
    );
}

function EventsListLine(props) {
  const e = props.event;
  const { deleteEvent } = useDeleteContent();
  const { error, setPopupMessage } = useContext(PopupContext);

  function deleteEventHandler() {
    deleteEvent(e._id, (d) => {
      if (!error) {
        props.patchEventsArray(d, 'delete');
      } else setPopupMessage('Fehler beim Löschen!', 'error');
    });
  }

  return (
    <li
      className={`${classes.editListItem} ${
        new Date(e.date) > new Date() && classes.future
      }`}
    >
      <span>{e.title + ', '}</span>
      <span style={{ color: 'gray', marginLeft: '4px' }}>
        {dateformat(new Date(e.date), 'dd.mm.yyyy')}, {e.location}
      </span>
      <div className={classes.editListIcons}>
        <FontAwesomeIcon
          icon={faPencil}
          className={classes.editListEditIcon}
          onClick={() => {
            props.setSelectedEvent({ ...e, edit: true });
          }}
        />
        <FontAwesomeIcon
          icon={faCopy}
          className={classes.editListCopyIcon}
          onClick={() => {
            props.setSelectedEvent({ ...e, edit: false });
          }}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className={classes.editListCancelIcon}
          onClick={deleteEventHandler}
        />
      </div>
    </li>
  );
}

function EventsList(props) {
  const prevEvents = props.events;
  return (
    <Fragment>
      <h2>Kommende Events</h2>
      <ul className={classes.editList}>
        {props.events.map((e, i) => {
          if (new Date(e.date) >= new Date())
            return (
              <EventsListLine
                event={e}
                key={i}
                setSelectedEvent={props.setSelectedEvent}
                patchEventsArray={props.patchEventsArray}
              />
            );
        })}
      </ul>
      <h2>Vergangene Events</h2>
      <ul className={classes.editList}>
        {prevEvents.map((e, i) => {
          if (new Date(e.date) < new Date() && i < 100)
            // only show limited amount of old events
            return (
              <EventsListLine
                event={e}
                key={i}
                setSelectedEvent={props.setSelectedEvent}
                patchEventsArray={props.patchEventsArray}
              />
            );
        })}
      </ul>
    </Fragment>
  );
}

function EventsManager(props) {
  const emptyEvent = {
    title: '',
    location: '',
    date: new Date(),
    text: '',
    edit: false,
  };
  const [selectedEvent, setSelectedEvent] = useState(emptyEvent);
  const [events, setEvents] = useState(props.events);
  const { setPopupMessage } = useContext(PopupContext);

  function patchEventsArray(e, action) {
    if (action === 'add') {
      setSelectedEvent(emptyEvent);
      props.setEvents((arr) => {
        const newItemDate = new Date(e.date);
        const index = arr.findIndex(
          (item) => new Date(item.date) > newItemDate
        );
        if (index === -1) {
          setEvents([...arr, e]);
          return [...arr, e];
        }
        setEvents([...arr.slice(0, index), e, ...arr.slice(index)]);
        return [...arr.slice(0, index), e, ...arr.slice(index)];
      });
    } else if (action === 'edit') {
      setSelectedEvent(emptyEvent);
      props.setEvents((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        setEvents(
          index === -1
            ? arr
            : [...arr.slice(0, index), e, ...arr.slice(index + 1)]
        );
        return index === -1
          ? arr
          : [...arr.slice(0, index), e, ...arr.slice(index + 1)];
      });
    } else {
      setSelectedEvent(emptyEvent);
      props.setEvents((arr) => {
        const index = arr.findIndex((item) => item._id === e._id);
        if (index !== -1) {
          arr.splice(index, 1);
        }
        setEvents(arr);
        return arr;
      });
      setPopupMessage('Event erfolgreich entfernt.', 'success');
    }
  }

  return (
    <Fragment>
      <EventsForm
        selectedEvent={selectedEvent}
        patchEventsArray={patchEventsArray}
      />
      <EventsList
        events={events}
        patchEventsArray={patchEventsArray}
        setSelectedEvent={setSelectedEvent}
      />
    </Fragment>
  );
}

function Event(props) {
  const { showModal } = useContext(ModalContext);
  const e = props.event;
  return (
    <li>
      {e.title}{' '}
      <span style={{ color: 'gray' }}>
        - {dateformat(new Date(e.date), 'dd.mm.yyyy')} - {e.location} -
      </span>
      <span
        style={{ color: 'blue', cursor: 'pointer' }}
        onClick={() => {
          showModal(
            e.title,
            null,
            <Animation dir="left">{parse(e.text)}</Animation>
          );
        }}
      >
        {' '}
        mehr Infos...
      </span>
    </li>
  );
}

function Events() {
  const { isLoading, error, getAllEvents } = useFetchContent();
  const [events, setEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const authCtx = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);

  // fetch events
  useEffect(() => {
    getAllEvents((d) => {
      if (!error) {
        setEvents(d);
        if (!eventsLoaded) setEventsLoaded(true);
      }
    });
  }, []);

  function manageEventsHandler() {
    showModal(
      'Events bearbeiten',
      null,
      <EventsManager events={events} setEvents={setEvents} />
    );
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      <h1 className="sectionHeading" id="veranstaltungen">
        Geplante Veranstaltungen
      </h1>
      {isLoading || !eventsLoaded || error ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {Array.from({ length: 3 }).map((v, i) => {
            return <Skeleton key={i} height="60px" width="90%" />;
          })}
        </div>
      ) : !events.find((obj) => {
          return new Date(obj.date) >= new Date();
        }) ? (
        <SingleText id="6481d94b12d62e7117a46cd0" field="text" />
      ) : (
        <ul className="standardText" style={{ lineHeight: '2' }}>
          {events.map((e, i) => {
            if (new Date(e.date) >= new Date())
              return <Event event={e} key={i} />;
            else return null;
          })}
        </ul>
      )}
      {authCtx.isAdmin && eventsLoaded && (
        <Button
          icon={faList}
          color="darkred"
          maxWidth="200px"
          onClick={manageEventsHandler}
        >
          VERWALTEN
        </Button>
      )}
    </div>
  );
}

export default Events;

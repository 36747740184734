import vcgth from '../assets/other_companies/vcgth.webp';
import rockets from '../assets/other_companies/rockets.webp';
import expert from '../assets/other_companies/expert.webp';
import frontrunner from '../assets/other_companies/frontrunner.webp';
import stadtwerke from '../assets/other_companies/stadtwerke.webp';

export const sponsorships = [
  {
    img: vcgth,
    title: 'Volleyballclub Gotha',
    link: 'www.vc-gotha.de',
    rows: 2,
    cols: 2,
  },
  {
    img: rockets,
    title: 'Rockets',
    link: 'www.big-gotha.de',
    rows: 2,
    cols: 2,
  },
];

export const partners = [
  {
    title: 'Stadtwerke Gotha',
    img: stadtwerke,
    link: 'https://www.stadtwerke-gotha.de/produkte-services/elektromobilitaet/autopaket.html',
  },
  {
    title: 'expert Media Mobil',
    img: expert,
  },
  {
    title: 'Front Runner',
    img: frontrunner,
  },
];

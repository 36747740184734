import { Tabs, Box } from '@mui/material/';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    sx={{
      borderRadius: '8px',
      paddingLeft: 'calc(10px + env(safe-area-inset-left))',
      paddingRight: 'calc(10px + env(safe-area-inset-right))',
    }}
    scrollButtons="auto"
    allowScrollButtonsMobile
    variant={props.variant === 'fullWidth' ? 'fullWidth' : 'scrollable'}
    textColor="inherit"
    TabIndicatorProps={{
      children: (
        <span
          style={{
            backgroundColor: props.indicatorColor,
            width: '100%',
          }}
        />
      ),
    }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
  },
});

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

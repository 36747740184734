import { createContext, useState } from 'react';

const PopupContext = createContext({
  message: '',
  type: '',
  setPopupMessage: (message, type) => {},
});

export const PopupProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [type, setType] = useState(null);

  const setPopupMessageHandler = (message, type) => {
    setMessage(message);
    setType(type);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const contextValue = {
    message,
    type,
    setPopupMessage: setPopupMessageHandler,
  };

  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
};

export default PopupContext;

import { useContext, useEffect, useState } from 'react';
import classes from './CaravanWidget.module.css';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import ModalContext from '../../store/modal-context';
import AuthContext from '../../store/auth-context';
import PopupContext from '../../store/popup-context';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import { TextField } from '@mui/material/';

// IMPORT OWN COMPONENTS
import Animation from '../UI/Animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleMinus,
  faPenSquare,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';
import useFetchContent from '../../hooks/useFetchContent';
import useDeleteContent from '../../hooks/useDeleteContent';
import usePatchContent from '../../hooks/usePatchContent';
import usePostContent from '../../hooks/usePostContent';
import DragAndDropFiles from '../UI/DragAndDropFiles';
import useHttp from '../../hooks/use-http';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

const parse = require('html-react-parser');

function CaravanWidgetForm({ patchClasses, data = {} }) {
  const { error: updateError, patchCaravanClass } = usePatchContent();
  const {
    error: uploadError,
    postCaravanClass,
    postCaravanClassThumbnail,
  } = usePostContent();
  const { updateHeight, closeModal } = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [formData, setFormData] = useState({
    // category: data.model || '',
    model: data.model || '',
    brand: data.brand || '',
  });
  const [description, setDescription] = useState(data.description || '');
  const [thumbnailImage, setThumbnailImage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const caravanClass = { ...formData, description, category: formData.model };
    if (!data.model) {
      postCaravanClass(caravanClass, (d) => {
        if (!uploadError) {
          if (thumbnailImage) {
            const formDataImg = new FormData();
            formDataImg.append('upload', thumbnailImage);
            postCaravanClassThumbnail(formDataImg, d._id, (i) => {
              patchClasses({ ...d, thumbnailImage: { src: i.url } }, 'add');
            });
          } else {
            patchClasses(d, 'add');
          }
        }
        closeModal();
      });
    } else {
      patchCaravanClass(data._id, caravanClass, (d) => {
        if (!updateError) {
          if (thumbnailImage) {
            const formDataFile = new FormData();
            formDataFile.append('upload', thumbnailImage);
            postCaravanClassThumbnail(formDataFile, d._id, (i) => {
              patchClasses({ ...d, thumbnailImage: { src: i.url } }, 'edit');
            });
          } else {
            patchClasses(d, 'edit');
            closeModal();
          }
        }
        closeModal();
      });
    }
  };

  const { sendRequest } = useHttp();
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file) => {
            formData.append('upload', file);
            sendRequest(
              {
                url: '/api/v1/caravan/inlineimage',
                method: 'POST',
                headers: {
                  Authorization: 'Bearer ' + authCtx.token,
                },
                body: formData,
                noJSON: true,
              },
              (data) => {
                resolve({
                  default: `https://autohaus-seyfarth.de${data.url}`,
                });
              }
            );
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} sx={{ marginTop: '10px' }}>
        {/* <Grid xs={12}>
          <TextField
            label="Category"
            name="category"
            value={formData.category || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid> */}
        <Grid xs={12}>
          <TextField
            label="Klasse"
            name="model"
            value={formData.model || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label="Marke"
            name="brand"
            value={formData.brand || ''}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <DragAndDropFiles
            types={['JPG', 'JPEG', 'PNG']}
            handleChange={setThumbnailImage}
            label={
              !thumbnailImage
                ? 'Kleines Bild hinzufügen'
                : 'Kleines Bild ausgewählt'
            }
          />
        </Grid>
        <Grid xs={12}>
          <div style={{ width: '100%' }}>
            <CKEditor
              editor={Editor}
              data={description}
              onChange={(e, editor) => {
                const text = editor.getData();
                setDescription(text);
                updateHeight();
              }}
              config={{
                // plugins: [ImageResize],
                extraPlugins: [uploadPlugin],
              }}
            />
          </div>
        </Grid>
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '20px',
          }}
        >
          <Button type="submit" color="green" maxWidth="200px">
            {data ? 'Aktualisieren' : 'Hochladen'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export function CaravanWidget(props) {
  const { showModal } = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const { error: delError, deleteCaravanClass } = useDeleteContent();

  const c = props.data;

  function showModalHandler() {
    showModal(
      c.model,
      null,
      <div className={classes.newsContent}>
        <Animation dir="left">
          {parse(
            c.description.replace(
              /<a\s+([^>]*)>/g,
              '<a $1 target="_blank" rel="noopener noreferrer">'
            )
          )}
        </Animation>
      </div>
    );
  }

  // UNFINISHED
  function deleteClass() {
    showModal(
      c.model,
      null,
      <Button
        onClick={() => {
          deleteCaravanClass(c._id, (d) => {
            if (!delError) {
              props.patchClasses(c, 'delete');
            }
          });
        }}
        color="red"
      >
        LÖSCHEN
      </Button>
    );
  }

  function editClass() {
    showModal(
      'Bearbeiten: ' + c.model,
      null,
      <CaravanWidgetForm data={c} patchClasses={props.patchClasses} />
    );
  }

  return (
    <Animation whileTap>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        {authCtx.isAdmin && (
          <div className={classes.iconWrapper}>
            <div className={classes.editIconCrop}>
              <FontAwesomeIcon
                icon={faPenSquare}
                className={classes.editIcon}
                onClick={editClass}
              />
            </div>
            <FontAwesomeIcon
              icon={faCircleMinus}
              className={classes.deleteIcon}
              onClick={deleteClass}
            />
          </div>
        )}
        <div
          className={`${classes.classPreviewWrapper} `}
          onClick={() => showModalHandler()}
        >
          <img
            src={
              c.thumbnailImage &&
              `https://autohaus-seyfarth.de${c.thumbnailImage.src}`
            }
            className={classes.classPreviewImage}
            alt={'Vorschau ' + c.model + ' der Firma ' + c.brand}
          />
          <span className={classes.classDescription}>{c.model}</span>
        </div>
      </div>
    </Animation>
  );
}

export default function CaravanWidgets() {
  const [items, setItems] = useState([]);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error, getAllCaravanClasses } = useFetchContent();
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);

  useEffect(() => {
    getAllCaravanClasses((d) => {
      if (!error) {
        setItems(d);
      }
    });
  }, []);

  function patchClasses(data, action) {
    if (action === 'add') {
      setItems((c) => [data, ...c]);
      setPopupMessage('Klasse: "' + data.model + '" hinzugefügt.', 'success');
    } else if (action === 'delete') {
      setItems((items) => items.filter((item) => item._id !== data._id));
      closeModal();
      setPopupMessage('Klasse: "' + data.model + '" gelöscht.', 'success');
    } else if (action === 'edit') {
      let found = false;
      setItems((arr) => {
        const updatedItems = arr.map((item) => {
          if (item._id === data._id) {
            found = true;
            return data;
          } else {
            return item;
          }
        });
        if (!found) {
          return [data, ...arr];
        }
        return updatedItems;
      });
      setPopupMessage('Klasse erfolgreich bearbeitet!', 'success');
    }
  }

  return (
    <Grid container xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
      {authCtx.isAdmin && (
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <FontAwesomeIcon
            icon={faPlusCircle}
            className={classes.addIcon}
            onClick={() => {
              showModal(
                'Caravan-Klasse hinzufügen',
                null,
                <CaravanWidgetForm patchClasses={patchClasses} />
              );
            }}
          />
        </Grid>
      )}
      {items.map((c, i) => {
        return (
          <Grid
            key={i}
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            sx={{
              margin: '20px 0',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CaravanWidget data={c} patchClasses={patchClasses} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export function CaravanClassWidget(props) {
  return (
    <CaravanWidget
      caravanClass={props.caravanClass}
      onClick={props.onClick}
      preview={props.data[0]}
      data={props.data}
    />
  );
}

import classes from './Startseite.module.css';
import { useContext, useEffect, useState } from 'react';
import image_movie from '../../assets/company_sources/imagefilm_kurz.mp4';
import amm_movie from '../../assets/company_sources/automobile_am_moenchhof.mp4';

// IMPORT OWN COMPONENTS
import NewsWidgets from '../About/NewsWidgets';
import StaffWidgets from '../About/StaffWidgets';
import SuggestedCars from '../Cars/SuggestedCars';
import ShortFilter from '../Filter/ShortFilter';
import Shortcuts from '../Home/Shortcuts';

// IMPORT MUI AND OTHER COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import ReactPlayer from 'react-player/lazy';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useGetTablet } from '../../hooks/useWindowDimensions';
import AuthContext from '../../store/auth-context';
import useFetchContent from '../../hooks/useFetchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import InternalNews, { InternalNewsForm } from '../About/InternalNews';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ModalContext from '../../store/modal-context';
import Button from '../UI/Button';
import { useParams } from 'react-router-dom';
import { useBrandFind } from '../../hooks/useFinders';
import { Helmet } from 'react-helmet';
import { globals } from '../../data/global-vars';
import Animation from '../UI/Animation';

function Startseite() {
  let tablet = useGetTablet();
  const authCtx = useContext(AuthContext);
  const { showModal, closeModal } = useContext(ModalContext);

  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);

  // declare data vars
  const [internalNews, setInternalNews] = useState([]);
  const [videoIsLoading, setVideoIsLoading] = useState(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState(true);
  const { getAllInternalNews, getSecureFile } = useFetchContent();

  function patchInternalNews(newNews) {
    let found = false;
    setInternalNews((n) => {
      const updatedNews = n.map((newsItem) => {
        if (newsItem._id === newNews._id) {
          found = true;
          return newNews;
        } else {
          return newsItem;
        }
      });
      if (!found) {
        return [newNews, ...n];
      }
      return updatedNews;
    });
    closeModal();
  }

  function deleteInternalNews(_id) {
    const filteredItems = internalNews.filter((item) => item._id !== _id);
    setInternalNews(filteredItems);
    closeModal();
  }

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      getAllInternalNews((n) => {
        setInternalNews(n);
      });
    }
  }, [authCtx.isLoggedIn, getAllInternalNews]);

  function openProtectedPDF() {
    // Öffne ein neues Fenster
    const newWindow = window.open();

    getSecureFile('pdf/employee/broschuere.pdf', (d) => {
      newWindow.location = URL.createObjectURL(d);
    });

    // Lade die geschützte PDF-Datei im neuen Fenster
  }

  const defaultTitle = 'Autohaus Seyfarth';
  const brandHouse = useBrandFind(brand, 'house');
  useEffect(() => {
    document.title = brandHouse.PAGE_TITLE;
  }, [brandHouse]);
  useEffect(
    () => () => {
      document.title = defaultTitle.current;
    },
    []
  );

  const [showWidget, setShowWidget] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWidget(true);
    }, 500); // 500 Millisekunden = 0,5 Sekunden

    // Aufräumarbeiten, um den Timer zu stoppen, wenn die Komponente unmountet wird
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid
      container
      xs={12}
      rowSpacing={2}
      sx={{ overflow: 'hidden', paddingTop: 0 }}
      className="container"
    >
      {authCtx.isAdmin && (
        <FontAwesomeIcon
          icon={faPlusCircle}
          className={classes.addIcon}
          onClick={() => {
            showModal(
              'Firmeninterne Nachricht hinzufügen',
              null,
              <InternalNewsForm patchInternalNews={patchInternalNews} />
            );
          }}
        />
      )}
      {authCtx.isLoggedIn && (
        <Grid xs={12} container>
          {internalNews.map((n, i) => {
            return (
              <Grid xs={12} lg={6} key={i} container>
                <InternalNews
                  deleteInternalNews={deleteInternalNews}
                  data={n}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {authCtx.isLoggedIn && (
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button maxWidth="300px" onClick={openProtectedPDF}>
            Mitarbeiterbroschüre
          </Button>
        </Grid>
      )}
      <Grid xs={12} sx={{ marginTop: '20px' }}>
        <div
          style={{
            position: 'relative',
          }}
        >
          {videoIsLoading && (
            <div
              style={{
                backgroundColor: 'white',
                zIndex: 90,
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            >
              <PageLoaderIcon isLoading={videoIsLoading} />
            </div>
          )}
          <div
            style={{
              position: 'relative',
              width: '100%',
              aspectRatio:
                brandObject.url === globals.BRANDS.SKODA.url
                  ? '1918 / 836'
                  : '1182 / 493',
              borderRadius: '8px',
              overflow: 'hidden',
            }}
          >
            <ReactPlayer
              url={
                brandObject.url === globals.BRANDS.SKODA.url
                  ? amm_movie
                  : image_movie
              }
              playing={videoIsPlaying}
              controls={!videoIsPlaying}
              loop={true}
              muted={true}
              playsinline={true}
              pip={false}
              light={false}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nofullscreen',
                  },
                },
              }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              width="100%"
              height="100%"
              onReady={() => {
                setVideoIsLoading(false);
              }}
              onPause={() => {
                setVideoIsPlaying(false);
              }}
              onPlay={() => {
                setVideoIsPlaying(true);
              }}
            />
          </div>
        </div>
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={4} xl={4}>
        <Shortcuts grid={tablet ? false : true} />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={8} xl={8} sx={{ paddingTop: '5px' }}>
        <ShortFilter short filter={{ brand: [brandObject.carzillaBrand] }} />
      </Grid>
      <Grid xs={12}>
        <SuggestedCars />
      </Grid>
      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <NewsWidgets header />
      </Grid>
      <Grid
        xs={12}
        lg={9}
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
          marginBottom: '40px',
        }}
      >
        <StaffWidgets dep="management" />
      </Grid>
      <Grid
        xs={12}
        lg={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        {showWidget ? (
          <Helmet>
            <script
              type="text/javascript"
              charset="UTF-8"
              src="https://apps.autohauskenner.de/Widget_Js/5322/27221/Widget.js"
            ></script>
          </Helmet>
        ) : null}
        <Animation>
          <div id="kenner-widget"></div>
        </Animation>
      </Grid>
    </Grid>
  );
}

export default Startseite;

import classes from './Departments.module.css';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import ModalContext from '../../store/modal-context';

// IMPORT MUI COMPONENTS
import Grid from '@mui/material/Unstable_Grid2';
import {
  faCancel,
  faCircleMinus,
  faCirclePlus,
  faEye,
  faFile,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../UI/Button';
import usePostContent from '../../hooks/usePostContent';
import usePatchContent from '../../hooks/usePatchContent';
import PageLoaderIcon from '../UI/PageLoaderIcon';
import styled from '@mui/material/styles/styled';
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import PopupContext from '../../store/popup-context';
import useDeleteContent from '../../hooks/useDeleteContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { globals } from '../../data/global-vars';

// STYLE MUI COMPONENT
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
});

// error message for adding a new employee
const errorMessage = (
  <div
    style={{
      width: '100%',
      textAlign: 'left',
      color: 'red',
      fontStyle: 'italic',
    }}
  >
    <span>Es gab einen Fehler mit der Eingabe. Bitte Eingabe überprüfen.</span>
  </div>
);

export function DepartmentForm(props) {
  const { isLoading, error, postDepartment } = usePostContent();
  const { error: updateError, patchDepartment } = usePatchContent();
  const s = props.selected;

  const [add, setAdd] = useState(s.edit ? false : true);
  const [key, setKey] = useState(s.key ? s.key : '');
  const [title, setTitle] = useState(s.title ? s.title : '');
  const [brand, setBrand] = useState(
    s.brandKey ? s.brandKey : globals.BRANDS.VW.make
  );

  useEffect(() => {
    setKey(s.key ? s.key : '');
    setTitle(s.title ? s.title : '');
    setBrand(s.brandKey ? s.brandKey : globals.BRANDS.VW.make);
    setAdd(s.edit ? false : true);
  }, [s]);

  function clear() {
    setAdd(true);
    setKey('');
    setTitle('');
    setBrand('');
  }

  function handleSubmit(e) {
    e.preventDefault();
    const department = {
      key,
      title,
      brand,
    };

    if (add) {
      postDepartment(department, (d) => {
        if (!error) {
          props.patchDepartments(d, 'add');
        }
      });
    } else {
      patchDepartment(s._id, department, (d) => {
        if (!updateError) {
          props.patchDepartments(d, 'edit');
        }
      });
    }
  }

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PageLoaderIcon isLoading={isLoading} />
      </div>
    );
  } else
    return (
      <form
        style={{
          width: '100%',
          border: '1px solid var(--text-color)',
          borderRadius: '20px',
          marginBottom: '40px',
        }}
        onSubmit={handleSubmit}
      >
        <StyledBox>
          {error || (updateError && errorMessage)}
          <Grid xs={12} container spacing={2}>
            <Grid xs={12} sm={6}>
              <TextField
                label="Abteilungsbezeichnung"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid xs={12} sm={6}>
              <TextField
                label="einmaliger Key"
                variant="outlined"
                fullWidth
                className={classes.addInput}
                value={key}
                onChange={(e) => setKey(e.target.value)}
                required
                disabled={!add}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.concInput}>
              <FormControl fullWidth>
                <InputLabel>Marke</InputLabel>
                <Select
                  name="selectedBrand"
                  label="Marke"
                  value={brand}
                  sx={{ textAlign: 'left' }}
                  onChange={(e) => setBrand(e.target.value)}
                >
                  {Object.entries(globals.BRANDS).map(([key, value]) => {
                    return <MenuItem value={value.make}>{value.name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
              }}
              container
            >
              <Grid xs="auto">
                <Button
                  color="red"
                  icon={faCircleMinus}
                  disabled={key === '' && title === '' ? true : false}
                  maxWidth="200px"
                  onClick={clear}
                >
                  Auswahl leeren
                </Button>
              </Grid>
              <Grid xs="auto">
                <Button
                  color="green"
                  icon={add ? faCirclePlus : faPencil}
                  type="submit"
                  disabled={key === '' && title === '' ? true : false}
                  maxWidth="200px"
                >
                  {add ? 'Hinzufügen' : 'Aktualisieren'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </StyledBox>
      </form>
    );
}

function DepartmentLine(props) {
  const e = props.data;
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const { error: deleteError, deleteDepartment } = useDeleteContent();
  const { patchDepartment } = usePatchContent();
  const params = useParams();
  const brand = params.brand;
  const brandObject = useBrandFind(brand);
  const primeColor = brandObject.primeColor;

  function deleteDepartmentHandler() {
    showModal(
      e.title,
      null,
      <div style={{ paddingBottom: '10px' }}>
        <Button
          onClick={() => {
            deleteDepartment(e._id, (d) => {
              if (!deleteError) {
                props.patchDepartments(d, 'delete');
              } else {
                setPopupMessage('Fehler beim Löschen der Abteilung!', 'error');
              }
              closeModal();
            });
          }}
          color="red"
        >
          Abteilung ENTFERNEN
        </Button>
      </div>
    );
  }

  function toggleHideOverviewHandler() {
    patchDepartment(e._id, { hideOverview: !e.hideOverview }, (d) => {
      props.patchDepartments(d, 'edit');
    });
  }

  return (
    <Grid
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        textAlign: 'left',
        height: '100%',
        padding: '10px',
        position: 'relative',
        backgroundColor: 'var(--light-contrast)',
        borderRadius: '20px',
      }}
    >
      <span
        className={classes.lineName}
        style={{
          color: e.hideOverview ? 'var(--text-color)' : primeColor,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={e.hideOverview ? faFile : faEye}
          onClick={toggleHideOverviewHandler}
          style={{
            marginRight: '5px',
            cursor: 'pointer',
            color: e.hideOverview ? 'var(--text-color)' : 'var(--info-color)',
          }}
        />
        {e.title}
      </span>
      <div className={classes.editListIcons}>
        <FontAwesomeIcon
          icon={faPencil}
          className={classes.editListEditIcon}
          onClick={() => {
            props.setSelected({
              ...e,
              edit: true,
            });
            window.scrollTo(0, 0);
            setPopupMessage('Zur Bearbeitung ausgewählt!', 'info');
          }}
        />
        <FontAwesomeIcon
          icon={faCancel}
          className={classes.editListCancelIcon}
          onClick={deleteDepartmentHandler}
        />
      </div>
    </Grid>
  );
}

export function DepartmentsList(props) {
  return (
    <Grid xs={12} container>
      {props.departments.map((e, i) => {
        return (
          <Grid xs={12} container sx={{ margin: '20px 0' }} key={i}>
            <Grid xs={12}>
              <h1 className="sectionHeading">{e.brandTitle}</h1>
            </Grid>
            {e.departments.map((d, ii) => {
              return (
                <Grid
                  xs={12}
                  sm={4}
                  key={ii}
                  sx={{
                    margin: '10px 0',
                    padding: '0 10px',
                  }}
                  container
                >
                  <DepartmentLine
                    data={d}
                    setSelected={props.setSelected}
                    selected={props.selected}
                    patchDepartments={props.patchDepartments}
                  />
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default DepartmentsList;

import { compData, globals } from '../data/global-vars';
export function usePageFind(page) {
  let pages = [];
  let position = null;
  Object.values(globals.PAGES).map((item) => {
    pages = [...pages, item.url];
    if (item.url === page) {
      return (position = item);
    }
    return position;
  });
  return position;
}

export function useBrandFind(brand, house) {
  let brands = [];
  let position = null;
  let foundBrand = null;
  if (!brand) {
    return globals.BRANDS.VW;
  } else {
    Object.values(globals.BRANDS).map((item) => {
      brands = [...brands, item.url];
      if (item.url === brand) {
        foundBrand = position = item;
      }
      foundBrand = position;
    });
    foundBrand = position;
  }

  if (house === 'house') {
    if (foundBrand === globals.BRANDS.SKODA) {
      return compData.AAM;
    } else {
      return compData.SEYFARTH;
    }
  } else {
    return foundBrand !== null ? foundBrand : globals.BRANDS.VW;
  }
}

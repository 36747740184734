import classes from './Service.module.css';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

// IMPORT OWN COMPONENTS
import StaffWidgets from '../About/StaffWidgets';
import PageHeading from '../UI/PageHeading';
import Animation from '../UI/Animation';
import SingleText from '../UI/SingleText';
import Button from '../UI/Button';
import Products from '../About/Product';
import ServiceFeatures from '../About/ServiceFeatures';
import dateformat from 'dateformat';

// Import images
import captcha from '../../assets/icons/get_captcha.jpeg';

// IMPORT MUI AND OTHER COMPONENTS
import {
  FormControl,
  InputLabel,
  Paper,
  Tab,
  Pagination,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material/';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import { DateTimeField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// IMPORT DATA
import { requests } from '../../data/service.js';

// IMPORT HOOKS, CONTEXTS AND OTHER FUNCTIONS
import { useBrandFind } from '../../hooks/useFinders';
import { faArrowRight, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import {
  MultiTextHeader,
  MultiTextTitle,
  MultiTextEntry,
  MultiTextAdminInfo,
  MultiTextWrapper,
  MultiTextDeleteIcon,
  MultiTextUrl,
  MultiTextStaff,
} from '../UI/MultiText';
import AuthContext from '../../store/auth-context';
import ModalContext from '../../store/modal-context';
import usePostContent from '../../hooks/usePostContent';
import PopupContext from '../../store/popup-context';
import { StyledTabs, TabPanel } from '../UI/TabController';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: '8px',
  color: theme.palette.text.secondary,
}));

function Service() {
  const authCtx = useContext(AuthContext);
  const { setPopupMessage } = useContext(PopupContext);
  const { showModal } = useContext(ModalContext);
  const { error, postMultiTextEntry, postRequest } = usePostContent();
  const [editServiceleistungen, setEditServiceleistungen] = useState(false);
  const [activeAppointmentPanel, setActiveAppointmentPanel] = useState(1);
  const [request, setRequest] = useState(0);
  const [appointmentSteps, setAppointmentSteps] = useState(requests[0].steps);
  const [maxAppointmentPanel, setMaxAppointmentPanel] = useState(
    requests[0].steps.length + 2
  );
  const appointmentPanel = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const params = useParams();
  const brand = params.brand;
  const page = params.page;
  const brandObject = useBrandFind(brand);

  const history = useHistory();
  const theme = useTheme();

  const emptyMultiText = {
    title: '',
    texts: [],
    editedBy: '',
    editedAt: '',
    createdBy: '',
    createdAt: '',
  };

  const [editMoreInfos, setEditMoreInfos] = useState(false);
  const [moreInfos, setMoreInfos] = useState(emptyMultiText);
  const [moreInfosLoaded, setMoreInfosLoaded] = useState(false);
  const [richText, setRichText] = useState(true);
  const [newMoreInfosTitle, setNewMoreInfosTitle] = useState('');
  const [newMoreInfosDepKey, setNewMoreInfosDepKey] = useState('');
  const [newMoreInfosText, setNewMoreInfosText] = useState('');
  const [newMoreInfosUrl, setNewMoreInfosUrl] = useState('');

  function submitNewMoreInfosHandler() {
    postMultiTextEntry(
      moreInfos._id,
      {
        title: newMoreInfosTitle,
        text: newMoreInfosText,
        url: newMoreInfosUrl,
        departmentKey: newMoreInfosDepKey,
      },
      (d) => {
        if (!error) {
          setPopupMessage(
            '"' + newMoreInfosTitle + '" wurde erfolgreich hinzugefügt!',
            'success'
          );
          setNewMoreInfosTitle('');
          setNewMoreInfosText('');
          setNewMoreInfosUrl('');
          setNewMoreInfosDepKey('');
          setMoreInfos(d);
        } else {
          setPopupMessage('Es gab einen Fehler beim Hochladen!', 'error');
        }
      }
    );
  }

  const [tabPanel, setTabPanel] = useState(
    authCtx.isAdmin || moreInfos.texts.length < 1 ? 0 : 1
  );
  const tabKey = params[0].split('/');
  useEffect(() => {
    if (moreInfos.texts.length > 0) {
      moreInfos.texts.map((i, _i) => {
        if (tabKey[0] === i.url) {
          return setTabPanel(_i + 1);
        }
        return null;
      });
      if (tabKey[0] === 'hinzufuegen') {
        setTabPanel(0);
        setEditMoreInfos(true);
      } else if (!moreInfos.texts.find((obj) => obj.url === tabKey[0])) {
        return setTabPanel(1);
      }
    }
  }, [tabPanel, tabKey, moreInfos]);

  function handleTabChange(event, index) {
    setTabPanel(index);
    const url = moreInfos.texts[index - 1]
      ? moreInfos.texts[index - 1].url
      : 'hinzufuegen';
    history.push(`/${brand}/${page}/` + url);
  }

  function handleChangeTabIndex(index) {
    setTabPanel(index);
    const url = moreInfos.texts[index - 1]
      ? moreInfos.texts[index - 1].url
      : 'hinzufuegen';
    history.push(`/${brand}/${page}/` + url);
  }

  const [formValues, setFormValues] = useState({}); // State für Formularwerte

  function pageIsValid(page) {
    const step = page - 1;
    let feedback = true;
    if (page > 0 && page < maxAppointmentPanel - 1)
      requests[request].steps[step].map((ip, id) => {
        if (ip.obligatory) {
          if (
            ip.type === 'reCaptcha' &&
            formValues[`${request}-${step}-${id}`] !== '57A1'
          ) {
            feedback = false;
          } else if (
            !formValues[`${request}-${step}-${id}`] ||
            formValues[`${request}-${step}-${id}`] === ''
          ) {
            feedback = false;
          }
        }
      });
    return feedback;
  }
  function formIsValid() {
    let feedback = true;
    requests[request].steps.map((s, i) => {
      feedback = pageIsValid(i + 1);
    });
    return feedback;
  }

  // Funktion zum Aktualisieren der Formularwerte
  const handleFormChange = (stepIndex, requestIndex, inputIndex, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [`${requestIndex}-${stepIndex}-${inputIndex}`]: value,
    }));
  };

  function submitHandler(e) {
    e.preventDefault();
    let formData = [];
    let genInfo = {};

    requests[request].steps.map((s, ii) => {
      s.map((ip, iii) => {
        const val = formValues[`${request}-${ii}-${iii}`] || '';

        if (ip.type === 'date_future' || ip.type === 'date_past')
          formData = [
            ...formData,
            {
              mailLabel: ip.mailLabel,
              value: dateformat(new Date(val), 'dd.mm.yyyy, H:MM') + ' Uhr',
            },
          ];
        else formData = [...formData, { mailLabel: ip.mailLabel, value: val }];

        if (
          ip.key === 'firstName' ||
          ip.key === 'lastName' ||
          ip.key === 'fromMail' ||
          ip.key === 'phone' ||
          ip.key === 'reCaptcha'
        ) {
          genInfo = { ...genInfo, [ip.key]: val };
        }
        if (ip.key === 'type')
          genInfo = {
            ...genInfo,
            type: requests[request].steps[ii].find((obj) => obj.key === 'type')
              .value,
          };
      });
    });
    const formRequest = {
      ...genInfo,
      data: formData,
      reCaptcha: '57A1',
    };

    const reqFormData = new FormData();
    reqFormData.append('data', JSON.stringify(formRequest));

    postRequest(reqFormData, (d) => {
      if (!error) {
        setPopupMessage('Anfrage gesendet', 'success');
        setFormValues({});
      } else {
        setPopupMessage('Fehler beim Senden der Anfrage', 'error');
      }
    });
  }

  function toggleStringInArray(array, string) {
    const index = array.indexOf(string);
    if (index !== -1) {
      // Zeichenkette im Array gefunden, entfernen
      array.splice(index, 1);
    } else {
      // Zeichenkette nicht im Array gefunden, hinzufügen
      array.push(string);
    }
    return array;
  }

  function renderInput(ip, stepIndex, inputIndex, requestIndex) {
    const inputKey = `${requestIndex}-${stepIndex}-${inputIndex}`;
    const value = formValues[inputKey] || ''; // Aktueller Wert des Inputs

    switch (ip.type) {
      case 'select':
      case 'dependant_select':
        return (
          <FormControl fullWidth>
            <InputLabel id="select-label-test">{ip.title}</InputLabel>
            <Select
              labelId="select-label-test"
              label={ip.title}
              className={classes.appointmentSelect}
              value={value}
              onChange={(e) => {
                handleFormChange(
                  stepIndex,
                  requestIndex,
                  inputIndex,
                  e.target.value
                );
              }}
            >
              {ip.type !== 'dependant_select' &&
                ip.options.map((o, oi) => {
                  return (
                    <MenuItem value={o} key={oi}>
                      {o}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        );

      case 'text':
        return (
          <TextField
            label={ip.title}
            variant="outlined"
            className={classes.appointmentTextInput}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'email':
        return (
          <TextField
            label={ip.title}
            required={ip.obligatory}
            type="email"
            variant="outlined"
            className={classes.appointmentTextInput}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'telephone':
        return (
          <TextField
            label={ip.title}
            required={ip.obligatory}
            type="text"
            variant="outlined"
            className={classes.appointmentTextInput}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'date_future':
      case 'date_past':
        var tomorrow = new Date();
        if ('date_future') tomorrow.setDate(tomorrow.getDate() + 1);
        else if ('date_past') tomorrow.setDate(tomorrow.getDate() - 1);
        return (
          <DateTimeField
            disableFuture={ip.type === 'date_future' ? false : true}
            disablePast={ip.type === 'date_past' ? false : true}
            label={ip.title}
            // value={dayjs(value || tomorrow)}
            value={dayjs(value || tomorrow)}
            onChange={(e) =>
              handleFormChange(stepIndex, requestIndex, inputIndex, new Date(e))
            }
            className={classes.appointmentDatepicker}
          />
        );

      case 'textarea':
        return (
          <TextField
            label={ip.title}
            multiline
            rows={4}
            className={classes.appointmentTextarea}
            value={value}
            onChange={(e) =>
              handleFormChange(
                stepIndex,
                requestIndex,
                inputIndex,
                e.target.value
              )
            }
          />
        );

      case 'checkbox':
        return (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!value}
                onChange={(e) => {
                  handleFormChange(
                    stepIndex,
                    requestIndex,
                    inputIndex,
                    e.target.checked
                  );
                }}
              />
            }
            label={ip.label}
            className={classes.appointmentCheckbox}
            required={ip.obligatory === true ? true : false}
          />
        );

      case 'checkboxes':
        if (!value) handleFormChange(stepIndex, requestIndex, inputIndex, []);
        return ip.options.map((o, oi) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value.includes(o) || false}
                  onChange={() => {
                    handleFormChange(
                      stepIndex,
                      requestIndex,
                      inputIndex,
                      toggleStringInArray(value, o)
                    );
                  }}
                />
              }
              label={o}
              key={oi}
              className={classes.appointmentCheckbox}
            />
          );
        });

      case 'reCaptcha':
        return (
          <Grid
            xs={12}
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Grid xs="auto">
              <img
                src={captcha}
                alt="captcha"
                loading="lazy"
                style={{
                  maxWidth: '140px',
                  marginRight: '10px',
                  borderRadius: '8px',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                fullWidth
                label="Sicherheitscode"
                variant="outlined"
                sx={{ maxWidth: '150px' }}
                value={value}
                onChange={(e) => {
                  handleFormChange(
                    stepIndex,
                    requestIndex,
                    inputIndex,
                    e.target.value
                  );
                }}
                required
              />
            </Grid>
          </Grid>
        );

      default:
        break;
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={
        deDE.components.MuiLocalizationProvider.defaultProps.localeText
      }
      adapterLocale="de"
    >
      <PageHeading title="Service">Service</PageHeading>
      <Grid container className="container" sx={{ padding: '0 20px' }}>
        <Grid
          xs={12}
          md={editServiceleistungen ? 12 : 5}
          id="serviceleistungen"
        >
          <ServiceFeatures
            setEditServiceleistungen={setEditServiceleistungen}
          />
        </Grid>
        {!editServiceleistungen && (
          <Grid
            xs={12}
            md={7}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '20px 0',
            }}
          >
            <form
              onSubmit={submitHandler}
              style={{
                overflow: 'hidden',
                boxShadow: 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
              }}
            >
              <Item
                style={{
                  overflow: 'hidden',
                  maxWidth: '95%',
                  maxHeight: '95%',
                }}
              >
                <h1 className="subheading" id="termin-buchen">
                  Termin buchen
                </h1>
                <div
                  className={classes.paginationWrapper}
                  style={{
                    left: '-' + (activeAppointmentPanel - 1) + '00%',
                    width: 100 * maxAppointmentPanel + '%',
                    height: !appointmentPanel[activeAppointmentPanel - 1]
                      .current
                      ? '300px'
                      : appointmentPanel[activeAppointmentPanel - 1].current
                          .clientHeight + 60,
                    cursor: 'pointer',
                    // padding: '40px 0',
                  }}
                >
                  <div
                    className={`${classes.paginationPanel}`}
                    ref={appointmentPanel[0]}
                  >
                    {requests.map((a, i) => {
                      if (i < 3) {
                        return (
                          <div
                            className={`${classes.selectItem} ${classes.selectAppointmentCase}`}
                            onClick={() => {
                              setActiveAppointmentPanel(2);
                              setAppointmentSteps(a.steps);
                              setMaxAppointmentPanel(a.steps.length + 2);
                              setRequest(i);
                            }}
                            key={i}
                          >
                            {a.title}
                          </div>
                        );
                      } else return null;
                    })}
                    <div style={{ width: '80%' }}>
                      <Button
                        icon={faArrowRight}
                        iconPos="post"
                        onClick={() => {
                          history.push(`/${brandObject.url}/anfrage/`, {
                            scroll: true,
                          });
                        }}
                      >
                        alle Anfragen
                      </Button>
                    </div>
                  </div>
                  {appointmentSteps &&
                    appointmentSteps.map((s, ii) => {
                      return (
                        <div
                          className={classes.paginationPanel}
                          ref={appointmentPanel[ii + 1]}
                          key={ii}
                        >
                          {s.map((ip, id) => {
                            return (
                              <div className={classes.selectItem} key={id}>
                                {renderInput(ip, ii, id, request)}
                              </div>
                            );
                          })}
                          <div style={{ width: '80%', marginBottom: '10px' }}>
                            <Button
                              onClick={() =>
                                setActiveAppointmentPanel((p) => p + 1)
                              }
                              maxWidth="200px"
                              disabled={
                                !pageIsValid(activeAppointmentPanel - 1)
                              }
                            >
                              Weiter
                              {/* {activeAppointmentPanel + 1} */}
                            </Button>
                          </div>
                          <Pagination
                            count={maxAppointmentPanel}
                            page={activeAppointmentPanel}
                            onChange={(e, v) => {
                              setActiveAppointmentPanel(v);
                            }}
                          />
                        </div>
                      );
                    })}

                  {maxAppointmentPanel > 1 && (
                    <div
                      className={`${classes.paginationPanel}`}
                      ref={appointmentPanel[maxAppointmentPanel]}
                    >
                      <Button
                        className={`${classes.selectItem} ${classes.selectAppointmentCase}`}
                        type="submit"
                        maxWidth="200px"
                        color="green"
                        disabled={!formIsValid()}
                        onClick={(e) => {
                          setActiveAppointmentPanel(1);
                          setAppointmentSteps(requests[0].steps);
                          setMaxAppointmentPanel(requests[0].steps.length + 2);
                          submitHandler(e);
                        }}
                      >
                        Absenden
                      </Button>
                      <Button
                        className={`${classes.selectItem} ${classes.selectAppointmentCase}`}
                        type="submit"
                        maxWidth="200px"
                        onClick={(e) => {
                          setActiveAppointmentPanel((p) => p - 1);
                        }}
                      >
                        Zurück
                      </Button>
                      <Button
                        className={`${classes.selectItem} ${classes.selectAppointmentCase}`}
                        maxWidth="200px"
                        color="red"
                        onClick={() => {
                          setActiveAppointmentPanel(1);
                          setAppointmentSteps(requests[0].steps);
                          setMaxAppointmentPanel(requests[0].steps.length + 2);
                        }}
                      >
                        Abbrechen
                      </Button>
                    </div>
                  )}
                </div>
              </Item>
            </form>
          </Grid>
        )}
      </Grid>
      <Grid
        xs={12}
        container
        className="container-contrast"
        sx={{
          paddingLeft: 'calc(20px + env(safe-area-inset-left))',
          paddingRight: 'calc(20px +  env(safe-area-inset-right))',
          margin: '20px 0',
        }}
      >
        <Grid
          xs={12}
          container
          className="container-contrast-inner"
          sx={{ padding: '20px', paddingBottom: '30px' }}
        >
          <h1 className="subheading" id="vermietung-und-nachruestung">
            Vermietung und Nachrüstung
          </h1>
          <Products />
        </Grid>
      </Grid>
      <Grid
        xs={12}
        className="container"
        id="waschanlage"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          paddingTop: '20px',
        }}
      >
        <div
          style={{
            width: 'calc(100% - 20px)',
            maxWidth: '600px',
          }}
        >
          <Animation>
            <Item className={classes.moreInfosTextPanel}>
              <h1 className="subheading">
                <SingleText id="63e4134ffe838d336b0f4692" field="title" />
              </h1>
              <div
                className={`${classes.moreInfosTextPanelText}`}
                style={{ width: 'calc(100% - 20px)' }}
              >
                <SingleText id="63e4134ffe838d336b0f4692" field="text" />
              </div>

              <Button
                className={classes.btnSpace}
                maxWidth="200px"
                onClick={() => {
                  showModal(
                    <SingleText id="64379af365171cc7c96fcf97" field="title" />,
                    null,
                    <Animation dir="left">
                      <SingleText id="64379af365171cc7c96fcf97" field="text" />
                    </Animation>
                  );
                }}
              >
                mehr Infos
              </Button>
            </Item>
          </Animation>
        </div>
      </Grid>
      <Grid xs={12} className="container" sx={{ padding: '20px' }}>
        <MultiTextWrapper
          id="63ef5be3e7c01eb0c6772884"
          applyData={setMoreInfos}
          setEdit={setEditMoreInfos}
          value={moreInfos}
          setLoaded={setMoreInfosLoaded}
        >
          {moreInfosLoaded && (
            <>
              <Grid xs={12} container>
                <Grid xs={12}>
                  <h1 className="sectionHeading" id="mehr-informationen">
                    <MultiTextHeader
                      value={moreInfos}
                      setValue={setMoreInfos}
                      edit={editMoreInfos}
                      setEdit={setEditMoreInfos}
                      richText={richText}
                      setRichText={setRichText}
                    />
                  </h1>
                  <MultiTextAdminInfo value={moreInfos} />
                  <br />
                </Grid>
                <Grid xs={12}>
                  <StyledTabs
                    value={tabPanel}
                    onChange={handleTabChange}
                    indicatorColor={brandObject.primeColor}
                    className={classes.tabWrapper}
                  >
                    {authCtx.isAdmin && (
                      <Tab
                        label={<Button color="green">Hinzufügen</Button>}
                        onClick={() => {
                          history.push(`/${brand}/${page}/hinzufuegen`, {
                            scroll: false,
                          });
                          setTabPanel(0);
                        }}
                        value={0}
                        className={classes.tab}
                        style={{
                          padding: 0,
                          marginLeft: '10px',
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {moreInfos.texts.map((i, _i) => {
                      return (
                        <Tab
                          label={i.title}
                          key={_i}
                          value={_i + 1}
                          className={classes.tab}
                          style={{
                            padding: 0,
                            marginLeft: '10px',
                            marginRight: '10px',
                          }}
                        />
                      );
                    })}
                  </StyledTabs>
                  <SwipeableViews
                    axis="x"
                    index={tabPanel}
                    onChangeIndex={handleChangeTabIndex}
                    enableMouseEvents={editMoreInfos ? false : true}
                  >
                    {authCtx.isAdmin ? (
                      <TabPanel value={0} index={0} dir={theme.direction}>
                        <div
                          className={`${classes.moreInfosTabPanelText} standardText`}
                          style={{ padding: '40px 0' }}
                        >
                          <MultiTextTitle
                            id={moreInfos._id}
                            value={newMoreInfosTitle}
                            setValue={setNewMoreInfosTitle}
                            setEdit={setEditMoreInfos}
                            edit={true}
                            new
                          />
                          <br />
                          <br />
                          <MultiTextUrl
                            id={moreInfos._id}
                            value={newMoreInfosUrl}
                            setValue={setNewMoreInfosUrl}
                            setEdit={setEditMoreInfos}
                            edit={true}
                            new
                          />
                          <br />
                          <br />
                          <MultiTextStaff
                            id={moreInfos._id}
                            value={newMoreInfosDepKey}
                            setValue={setNewMoreInfosDepKey}
                            setEdit={setEditMoreInfos}
                            edit={true}
                            new
                          />
                          <br />
                          <br />
                          <MultiTextEntry
                            id={moreInfos._id}
                            value={newMoreInfosText}
                            setValue={setNewMoreInfosText}
                            edit={true}
                            setEdit={setEditMoreInfos}
                            richText={richText}
                            new
                          />
                          <Button
                            maxWidth="200px"
                            color="green"
                            onClick={submitNewMoreInfosHandler}
                            icon={faCirclePlus}
                          >
                            Hochladen
                          </Button>
                        </div>
                      </TabPanel>
                    ) : (
                      <Fragment />
                    )}
                    {moreInfos.texts.map((p, i) => {
                      return (
                        <TabPanel
                          value={tabPanel}
                          index={i + 1}
                          dir={theme.direction}
                          key={i}
                        >
                          <div
                            className={`${classes.moreInfosTabPanelText} standardText`}
                            style={{
                              paddingBottom: '40px',
                              paddingTop: editMoreInfos && '40px',
                            }}
                          >
                            {authCtx.isAdmin && editMoreInfos && (
                              <Grid
                                container
                                xs={12}
                                sx={{
                                  textAlign: 'left',
                                }}
                                spacing={2}
                              >
                                <Grid xs={11}>
                                  <MultiTextTitle
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                    setEdit={setEditMoreInfos}
                                  />
                                </Grid>
                                <Grid
                                  xs={1}
                                  sx={{
                                    textAlign: 'right',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  <MultiTextDeleteIcon
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                  />
                                </Grid>
                                <Grid xs={12}>
                                  <MultiTextUrl
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                    setEdit={setEditMoreInfos}
                                  />
                                </Grid>
                                <Grid xs={12}>
                                  <MultiTextStaff
                                    id={moreInfos._id}
                                    value={p}
                                    setValue={setMoreInfos}
                                    edit={editMoreInfos}
                                    setEdit={setEditMoreInfos}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            <br />
                            <MultiTextEntry
                              id={moreInfos._id}
                              value={p}
                              setValue={setMoreInfos}
                              edit={editMoreInfos}
                              setEdit={setEditMoreInfos}
                              richText={richText}
                            />
                            {p.departmentKey && (
                              <div style={{ paddingTop: '20px' }}>
                                <StaffWidgets
                                  dep={p.departmentKey}
                                  noBackground
                                />
                              </div>
                            )}
                          </div>
                        </TabPanel>
                      );
                    })}
                  </SwipeableViews>
                </Grid>
              </Grid>
            </>
          )}
        </MultiTextWrapper>
      </Grid>
      <div className="container" style={{ marginBottom: '30px' }}>
        <StaffWidgets dep="service" />
      </div>
    </LocalizationProvider>
  );
}

export default Service;

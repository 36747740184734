import React, { useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import PopupContext from '../../store/popup-context';

const Popup = () => {
  const { message, type } = useContext(PopupContext);

  return (
    <Snackbar
      open={!!message}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

export default Popup;
